// core
import React from 'react';

// types
import { EButtonTypes, IProps } from './types';

// styles
import { Wrapper, Outline, Fill } from './styles';

const ButtonLink = ({ children, theme, to, ...restProps }: IProps) => {
  return (
    <Wrapper {...restProps}>
      {theme === EButtonTypes.Outline && <Outline to={to}>{children}</Outline>}
      {theme === EButtonTypes.Fill && <Fill to={to}>{children}</Fill>}
    </Wrapper>
  );
};

export default ButtonLink;
