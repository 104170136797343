import styled from 'styled-components/macro';
import ButtonLink from '../../components/Atoms/ButtonLink';
import { mediaSizes } from '../../styles/media';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0 80px 0;
`;

const Title = styled.div`
  font-family: ${p => p.theme.fontFamily.Libre};
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 48px;
  text-align: center;
  color: ${p => p.theme.colors.lightBlue};

  ${mediaSizes.max480} {
    font-size: 24px;
    line-height: 40px;
  }
`;

const Desc = styled.div`
  font-family: ${p => p.theme.fontFamily.Ubuntu};
  color: ${p => p.theme.colors.lightBlue};
  font-weight: 400;
  margin: 40px 0;
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  max-width: 1045px;

  ${mediaSizes.max1024} {
    padding: 0 48px;
    //margin: 40px 0 64px 0;
  }
  ${mediaSizes.max480} {
    margin: 24px 0;
    padding: 0 24px;
    font-size: 14px;
    line-height: 24px;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(472px + 8px);
  margin-bottom: 80px;
  border-top: 8px solid ${p => p.theme.colors.blue};
  border-bottom: 8px solid ${p => p.theme.colors.blue};
  &:before {
    content: '';
    position: absolute;
    z-index: 2;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: #000000;
    opacity: .3;
  }

  ${mediaSizes.max1024} {
    //height: calc(268px + 8px);
    margin-bottom: 64px;
  }
  ${mediaSizes.max480} {
    //height: calc(176px + 8px);
    border-top: 4px solid ${p => p.theme.colors.blue};
    border-bottom: 4px solid ${p => p.theme.colors.blue};
  }
`;


const ImageContent = styled.div`
  position: relative;
  z-index: 3;
`;

const Image = styled.img`
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: rotateY(180deg);
`;

const SubTitle = styled.div`
  font-family: ${p => p.theme.fontFamily.Libre};
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 48px;
  text-align: center;
  color: ${p => p.theme.colors.black};
  margin-bottom: 40px;

  ${mediaSizes.max1024} {
    padding: 0 80px;
  }
  ${mediaSizes.max480} {
    padding: 0 48px;
    font-size: 24px;
    line-height: 40px;
  }
`;

const Button = styled(ButtonLink)`
  max-width: 376px;

  ${mediaSizes.max480} {
    padding: 0 48px;
  }
`;

export { Wrapper, Button, Title, SubTitle, Desc, Image, ImageContent, ImageWrapper };
