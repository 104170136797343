import styled from 'styled-components/macro';
import Icon from '../Icon';

const Svg = styled(Icon)`
  width: 100%;
  height: 100%;
  object-fit: contain;
  color: #8a8d94;
`;

export { Svg };
