export const theme = {
  colors: {
    primary: '#122662',
    black: '#323537',
    gray: '#606467',
    lightGray: '#F2F2F2',
    lighterGray: '#8A8D94',
    red: '#A71D1D',
    blue: '#1F42AC',
    lightBlue: '#F6F8FF',
    green: '#19A763',
    pink: '#EE5252',
  },
  fontFamily: {
    Libre:
      '"Libre Caslon Text", apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
    Ubuntu:
      '"Ubuntu", apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
  },

  transitions: {
    regular: 'all ease 0.3s;',
    regular2x: 'all ease 0.6s;',
    custom: ' ease 0.3s;',
  },
};
