import styled from 'styled-components/macro';
import { mediaSizes } from '../../styles/media';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0 229px 0;

  ${mediaSizes.max1024} {
    padding: 16px 64px 309px 64px;
  }

  ${mediaSizes.max480} {
    padding: 24px 24px 64px 24px;
  }
`;

const Content = styled.div`
  max-width: 928px;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-family: ${p => p.theme.fontFamily.Libre};
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 48px;
  text-align: center;
  color: ${p => p.theme.colors.primary};

  ${mediaSizes.max480} {
    font-size: 24px;
    line-height: 40px;
  }
`;

const Separator = styled.div`
  width: 100%;
  height: 2px;
  background-color: ${p => p.theme.colors.primary};
  margin: 40px 0 24px 0;
`;

const LastUpdated = styled.div`
  color: ${p => p.theme.colors.lighterGray};
  font-family: ${p => p.theme.fontFamily.Ubuntu};
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;

  margin-bottom: 40px;
`;

const Desc = styled.div`
  font-family: ${p => p.theme.fontFamily.Ubuntu};
  color: ${p => p.theme.colors.black};
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
`;

export { Wrapper, Content, Title, Desc, Separator, LastUpdated };
