import React from 'react';

const PhoneIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.40039 3.5999C2.40039 2.93716 2.93765 2.3999 3.60039 2.3999H6.18384C6.77045 2.3999 7.27107 2.824 7.36751 3.40262L8.25469 8.72567C8.34129 9.24531 8.07886 9.76067 7.60767 9.99626L5.7499 10.9251C7.08946 14.2539 9.74641 16.9108 13.0751 18.2504L14.004 16.3926C14.2396 15.9214 14.755 15.659 15.2746 15.7456L20.5977 16.6328C21.1763 16.7292 21.6004 17.2298 21.6004 17.8165V20.3999C21.6004 21.0626 21.0631 21.5999 20.4004 21.5999H18.0004C9.38475 21.5999 2.40039 14.6155 2.40039 5.9999V3.5999Z"
      fill="#819FF9"
    />
  </svg>
);

export default PhoneIcon;
