// core
import React, { useEffect, useState } from 'react';
import { ErrorMessage, Formik, FormikErrors } from 'formik';
import { toast } from 'react-toastify';

// utils
import { $apiClient } from '../../utils/request';

// types
import { FormValues, IProjected } from './types';
import { EButtonTypes } from '../../components/Atoms/Button/types';

// styles
import {
  Wrapper,
  Title,
  Subtitle,
  FormField,
  FormFieldSolo,
  FormItem,
  FormWrap,
  FormWrapTitle,
  FormSides,
  FormSidesLeft,
  FormSidesRight,
  FormFieldWrap,
  FormFieldRow,
  FormFieldRowCouple,
  FormFieldLabel,
  FormFieldSubtitle,
  FormCheckBoxRow,
  FormCheckBoxWrap,
  FormCheckbox,
  FormCheckBoxLabel,
  FormCheckBoxIcon,
  FormRadioRow,
  FormRadioItem,
  FormRadioItemTitle,
  FormRadioItemButtons,
  FormRadioItemButton,
  FormRadioItemButtonLabel,
  FormRadio,
  FormSubmitButton,
  Error,
  BelowLine,
  Projected,
  ProjectedText,
  ProjectedItems,
  ProjectedItem,
  ProjectedCard,
  ProjectedCardTitle,
  ProjectedCardTitleSpan,
  ProjectedCardTitleCount,
  ProjectedItemYear,
  SignUpButton,
} from './styles';

// Loader
import LoadingIndicator from '../../components/Features/Loaders/LoadingIndicator';
import LoadingWrapper from '../../components/Features/Loaders/LoadingWrapper';
import {
  ColorType,
  TypesType,
} from '../../components/Features/Loaders/LoadingIndicator/types';

// icons
import InputUncheckIcon from '../../components/Icons/InputUncheck';
import InputCheckIcon from '../../components/Icons/InputCheck';
import PhoneIcon from '../../components/Icons/Phone';
import EmailIcon from '../../components/Icons/Email';
import SearchIcon from '../../components/Icons/Search';
import PercentIcon from '../../components/Icons/Percent';
import DollarIcon from '../../components/Icons/Dollar';

// data
import { NonCookCountyValidation, initialData } from './data';

const NonCookCountyForm = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState({
    lng: null,
    lat: null,
    addr: null,
    place: null,
  });
  const [pinData, setPinData] = useState<FormValues | null>(null);
  const [projectedData, setProjectedData] = useState<IProjected | null>(null);

  const initialValues: FormValues = pinData || initialData;

  const Validation = NonCookCountyValidation;

  const getPinData = () => {
    if (searchQuery && searchQuery.lng !== null) {
      setLoading(true);
      setPinData(null);
      $apiClient
        .post('property/find', {
          searchString: searchQuery,
          type: 'non-cook',
        })
        .then(res => setPinData(res.data.data))
        .catch(res => toast.error(JSON.parse(res.request.response).message))
        .finally(() => setLoading(false));
    } else {
      setPinData(initialData);
    }
  };

  const handleSubmit = (values: FormValues) => {
    const data = { ...values, type: 'non-cook' };
    setLoading(true);
    $apiClient
      .post('appeal', data)
      .then(res => {
        setProjectedData(res.data.data);
        setTimeout(() => {
          const element = document.getElementById('bellow');
          window.scrollTo({
            left: 0,
            top: element!.offsetTop,
            behavior: 'smooth',
          });
        }, 300);
      })
      .catch(res => {
        setTimeout(() => {
          const element = document.getElementById('form');
          window.scrollTo({
            left: 0,
            top: element!.offsetTop + 90,
            behavior: 'smooth',
          });
        }, 300);

        toast.error(JSON.parse(res.request.response).message);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getPinData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  const handleErrorScroll = () => {
    const element = document.getElementById('form');
    window.scrollTo({
      left: 0,
      top: element!.offsetTop + 90,
      behavior: 'smooth',
    });
  };

  return (
    <Wrapper>
      {loading && (
        <LoadingWrapper className={'fixed'}>
          <LoadingIndicator
            type={TypesType.Local}
            color={ColorType.Dark}
            size={7}
          />
        </LoadingWrapper>
      )}
      <Title>
        Non-Cook County Residental Property Tax <br /> 3 Year Appeal Savings
        Analysis
      </Title>
      <Subtitle>
        Let us help you analyze your property taxes. To locate your property,
        and pin details below. Please tell us information as well, so that we
        can contact you if we have any enter your property address your <br />
        contact questions.
      </Subtitle>

      <FormFieldWrap id="form" className={'solo'}>
        <FormFieldRow>
          <FormFieldSolo
            apiKey={process.env.REACT_APP_GOOGLE_MAPS}
            onPlaceSelected={place => {
              setSearchQuery({
                lng: place.geometry.location.lng(),
                lat: place.geometry.location.lat(),
                addr: place.formatted_address,
                place: place,
              });
            }}
            options={{
              types: ['address'],
              fields: ['all'],
              componentRestrictions: { country: 'usa' },
            }}
          />
          <FormFieldLabel>
            <SearchIcon />
            Find my property by address or PIN
          </FormFieldLabel>
        </FormFieldRow>
      </FormFieldWrap>

      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={Validation}
      >
        {({ errors, touched, values }) => (
          <FormItem>
            <FormWrap>
              <FormWrapTitle>Property Information</FormWrapTitle>
              <FormSides>
                <FormSidesLeft>
                  <FormFieldWrap>
                    <FormFieldRow>
                      <FormField
                        type="text"
                        autoComplete="chrome-off"
                        name="property_county"
                        className={
                          errors.property_county &&
                          touched.property_county &&
                          'error'
                        }
                      />
                      <FormFieldLabel>County</FormFieldLabel>
                    </FormFieldRow>
                    <ErrorMessage name="property_county">
                      {msg => <Error>{msg}</Error>}
                    </ErrorMessage>
                  </FormFieldWrap>
                  <FormFieldWrap>
                    <FormFieldRow>
                      <FormField
                        type="text"
                        autoComplete="chrome-off"
                        name="property_address"
                        className={
                          errors.property_address &&
                          touched.property_address &&
                          'error'
                        }
                      />
                      <FormFieldLabel>Property Address</FormFieldLabel>
                    </FormFieldRow>
                    <ErrorMessage name="property_address">
                      {msg => <Error>{msg}</Error>}
                    </ErrorMessage>
                  </FormFieldWrap>
                  <FormFieldWrap>
                    <FormFieldRow>
                      <FormField
                        type="text"
                        autoComplete="chrome-off"
                        name="property_pin"
                        className={
                          errors.property_pin && touched.property_pin && 'error'
                        }
                      />
                      <FormFieldLabel>PIN #</FormFieldLabel>
                    </FormFieldRow>
                    <ErrorMessage name="property_pin">
                      {msg => <Error>{msg}</Error>}
                    </ErrorMessage>
                  </FormFieldWrap>
                  <FormFieldRowCouple>
                    <FormFieldWrap>
                      <FormFieldRow>
                        <FormField
                          type="text"
                          autoComplete="chrome-off"
                          name="property_city"
                          className={
                            errors.property_city &&
                            touched.property_city &&
                            'error'
                          }
                        />
                        <FormFieldLabel>City</FormFieldLabel>
                      </FormFieldRow>
                      <ErrorMessage name="property_city">
                        {msg => <Error>{msg}</Error>}
                      </ErrorMessage>
                    </FormFieldWrap>
                    <FormFieldWrap>
                      <FormFieldRow>
                        <FormField
                          type="text"
                          autoComplete="chrome-off"
                          name="property_state"
                          className={
                            errors.property_state &&
                            touched.property_state &&
                            'error'
                          }
                        />
                        <FormFieldLabel>State</FormFieldLabel>
                      </FormFieldRow>
                      <ErrorMessage name="property_state">
                        {msg => <Error>{msg}</Error>}
                      </ErrorMessage>
                    </FormFieldWrap>
                  </FormFieldRowCouple>
                  <FormFieldRowCouple>
                    <FormFieldWrap className={'short'}>
                      <FormFieldRow>
                        <FormField
                          type="number"
                          autoComplete="chrome-off"
                          name="property_zip_code"
                          className={
                            errors.property_zip_code &&
                            touched.property_zip_code &&
                            'error'
                          }
                        />
                        <FormFieldLabel>Zip Code</FormFieldLabel>
                      </FormFieldRow>
                      <ErrorMessage name="property_zip_code">
                        {msg => <Error>{msg}</Error>}
                      </ErrorMessage>
                    </FormFieldWrap>
                    <FormFieldWrap>
                      <FormFieldRow>
                        <FormField
                          type="text"
                          autoComplete="chrome-off"
                          name="property_township"
                          className={
                            errors.property_township &&
                            touched.property_township &&
                            'error'
                          }
                        />
                        <FormFieldLabel>Township</FormFieldLabel>
                      </FormFieldRow>
                      <ErrorMessage name="property_township">
                        {msg => <Error>{msg}</Error>}
                      </ErrorMessage>
                    </FormFieldWrap>
                  </FormFieldRowCouple>
                </FormSidesLeft>
                <FormSidesRight>
                  <FormFieldRowCouple className={'first'}>
                    <FormFieldWrap>
                      <FormFieldRow>
                        <FormField
                          type="text"
                          autoComplete="chrome-off"
                          name="property_style"
                          className={
                            errors.property_style &&
                            touched.property_style &&
                            'error'
                          }
                        />
                        <FormFieldLabel>Style of Home</FormFieldLabel>
                      </FormFieldRow>
                      <ErrorMessage name="property_style">
                        {msg => <Error>{msg}</Error>}
                      </ErrorMessage>
                    </FormFieldWrap>
                    <FormFieldWrap className={'longer'}>
                      <FormFieldRow>
                        <FormField
                          type="number"
                          autoComplete="chrome-off"
                          name="property_area"
                          className={
                            errors.property_area &&
                            touched.property_area &&
                            'error'
                          }
                        />
                        <FormFieldLabel>Approx. Sq. Ft.</FormFieldLabel>
                      </FormFieldRow>
                      <ErrorMessage name="property_area">
                        {msg => <Error>{msg}</Error>}
                      </ErrorMessage>
                    </FormFieldWrap>
                  </FormFieldRowCouple>
                  <FormCheckBoxRow>
                    <FormCheckBoxWrap>
                      <FormCheckbox
                        id={'property_purchased_recently'}
                        type="checkbox"
                        name="property_purchased_recently"
                      />
                      <FormCheckBoxIcon>
                        {values.property_purchased_recently === true ? (
                          <InputCheckIcon />
                        ) : (
                          <InputUncheckIcon />
                        )}
                      </FormCheckBoxIcon>
                      <FormCheckBoxLabel
                        htmlFor={'property_purchased_recently'}
                      >
                        Did you purchase your home in the last 3 years?
                      </FormCheckBoxLabel>
                    </FormCheckBoxWrap>
                  </FormCheckBoxRow>
                  <FormFieldWrap className={'long'}>
                    <FormCheckBoxLabel
                      htmlFor={'property_is_primary'}
                      className={'checkbox'}
                    >
                      How much was the sale price?
                    </FormCheckBoxLabel>
                    <FormFieldRow>
                      <FormField
                        type="number"
                        autoComplete="chrome-off"
                        name="property_sale_price"
                        disabled={values.property_purchased_recently === false}
                        className={
                          errors.property_sale_price &&
                          touched.property_sale_price &&
                          'error'
                        }
                      />
                      <FormFieldLabel>Amount in $</FormFieldLabel>
                    </FormFieldRow>
                    <ErrorMessage name="property_sale_price">
                      {msg => <Error>{msg}</Error>}
                    </ErrorMessage>
                  </FormFieldWrap>
                  <FormRadioRow>
                    <FormRadioItem>
                      <FormRadioItemTitle>
                        Is this your Primary Residence?
                      </FormRadioItemTitle>
                      <FormRadioItemButtons>
                        <FormRadioItemButton>
                          <FormRadio
                            type="radio"
                            name="property_is_primary"
                            id="isRecidenceYes"
                            value="1"
                          />
                          <FormRadioItemButtonLabel htmlFor={'isRecidenceYes'}>
                            yes
                          </FormRadioItemButtonLabel>
                        </FormRadioItemButton>
                        <FormRadioItemButton>
                          <FormRadio
                            type="radio"
                            name="property_is_primary"
                            id="isRecidenceNo"
                            value="0"
                          />
                          <FormRadioItemButtonLabel htmlFor={'isRecidenceNo'}>
                            No
                          </FormRadioItemButtonLabel>
                        </FormRadioItemButton>
                      </FormRadioItemButtons>
                      <ErrorMessage name="property_is_primary">
                        {msg => <Error>{msg}</Error>}
                      </ErrorMessage>
                    </FormRadioItem>
                  </FormRadioRow>
                </FormSidesRight>
              </FormSides>
            </FormWrap>
            <FormWrap>
              <FormWrapTitle>Homeowners Information</FormWrapTitle>
              <FormSides>
                <FormSidesLeft>
                  <FormFieldWrap>
                    <FormFieldRow>
                      <FormField
                        type="text"
                        autoComplete="chrome-off"
                        name="owner_name"
                        className={
                          errors.owner_name && touched.owner_name && 'error'
                        }
                      />
                      <FormFieldLabel>Homeowners Name</FormFieldLabel>
                    </FormFieldRow>
                    <ErrorMessage name="owner_name">
                      {msg => <Error>{msg}</Error>}
                    </ErrorMessage>
                  </FormFieldWrap>
                  <FormRadioRow>
                    <FormRadioItem>
                      <FormRadioItemTitle>
                        Is homeowner 65 or over?
                      </FormRadioItemTitle>
                      <FormRadioItemButtons>
                        <FormRadioItemButton>
                          <FormRadio
                            type="radio"
                            name="owner_is_old"
                            id="isOverYes"
                            value="1"
                          />
                          <FormRadioItemButtonLabel htmlFor={'isOverYes'}>
                            yes
                          </FormRadioItemButtonLabel>
                        </FormRadioItemButton>
                        <FormRadioItemButton>
                          <FormRadio
                            type="radio"
                            name="owner_is_old"
                            id="isOverNo"
                            value="0"
                          />
                          <FormRadioItemButtonLabel htmlFor={'isOverNo'}>
                            No
                          </FormRadioItemButtonLabel>
                        </FormRadioItemButton>
                      </FormRadioItemButtons>
                      <ErrorMessage name="owner_is_old">
                        {msg => <Error>{msg}</Error>}
                      </ErrorMessage>
                    </FormRadioItem>
                  </FormRadioRow>
                </FormSidesLeft>
                <FormSidesRight>
                  <FormFieldWrap>
                    <FormFieldRow>
                      <FormField
                        type="text"
                        autoComplete="chrome-off"
                        name="owner_phone"
                        className={
                          errors.owner_phone && touched.owner_phone && 'error'
                        }
                      />
                      <FormFieldLabel>
                        <PhoneIcon />
                        Phone #
                      </FormFieldLabel>
                    </FormFieldRow>
                    <ErrorMessage name="owner_phone">
                      {msg => <Error>{msg}</Error>}
                    </ErrorMessage>
                  </FormFieldWrap>
                  <FormFieldWrap>
                    <FormFieldRow>
                      <FormField
                        type="text"
                        autoComplete="chrome-off"
                        name="owner_email"
                        className={
                          errors.owner_email && touched.owner_email && 'error'
                        }
                      />
                      <FormFieldLabel>
                        <EmailIcon />
                        Email Address
                      </FormFieldLabel>
                    </FormFieldRow>
                    <ErrorMessage name="owner_email">
                      {msg => <Error>{msg}</Error>}
                    </ErrorMessage>
                    <FormFieldSubtitle>
                      Where you want your savings analysis sent
                    </FormFieldSubtitle>
                  </FormFieldWrap>
                </FormSidesRight>
              </FormSides>
            </FormWrap>
            <div
              onClick={() =>
                Object.keys(errors).length > 0 && handleErrorScroll()
              }
            >
              <FormSubmitButton theme={EButtonTypes.Outline} type={'submit'}>
                Check My Taxes
              </FormSubmitButton>
            </div>
          </FormItem>
        )}
      </Formik>
      {projectedData && (
        <>
          <BelowLine id={'bellow'}>
            Below is your 3 years Projected Computer Generated Savings Analysis
          </BelowLine>

          <Projected>
            <ProjectedText>Projected</ProjectedText>
            <ProjectedItems>
              <ProjectedText>Projected</ProjectedText>
              {projectedData.projected.map((el, index) => (
                <ProjectedItem key={index}>
                  <ProjectedCard>
                    <ProjectedCardTitle>
                      <ProjectedCardTitleSpan>
                        Year {index + 1}
                      </ProjectedCardTitleSpan>{' '}
                      Tax Savings
                    </ProjectedCardTitle>
                    <ProjectedCardTitleCount>
                      <PercentIcon />${el.value}
                    </ProjectedCardTitleCount>
                  </ProjectedCard>
                  <ProjectedItemYear>Year: {el.year}</ProjectedItemYear>
                </ProjectedItem>
              ))}
            </ProjectedItems>
            <ProjectedText>For</ProjectedText>
            <ProjectedItem>
              <ProjectedText>For</ProjectedText>
              <ProjectedCard>
                <ProjectedCardTitle>
                  <ProjectedCardTitleSpan>One Time</ProjectedCardTitleSpan> Fee
                </ProjectedCardTitle>
                <ProjectedCardTitleCount>
                  <DollarIcon />${projectedData.fee}
                </ProjectedCardTitleCount>
              </ProjectedCard>
            </ProjectedItem>
          </Projected>
          <SignUpButton
            to={`/payment-form/${projectedData.id}`}
            theme={EButtonTypes.Fill}
          >
            I want to sign up now!
          </SignUpButton>
        </>
      )}
    </Wrapper>
  );
};

export default NonCookCountyForm;
