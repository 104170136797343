import React from 'react';
import { Svg } from './styles';

const BurgerIcon = ({ props }: any) => (
  <Svg viewBox="0 0 32 32" fill="none" {...props}>
    <path
      d="M4 9.33301H28"
      stroke="#EE5252"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M4 16H28"
      stroke="#EE5252"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M4 22.667H28"
      stroke="#EE5252"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </Svg>
);

export default BurgerIcon;
