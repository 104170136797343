import React from 'react';
import { Svg } from './styles';

const SearchIcon = ({ props }: any) => (
  <Svg viewBox="0 0 24 24" {...props}>
    <path
      d="M21.2168 21L15.2168 15M17.2168 10C17.2168 13.866 14.0828 17 10.2168 17C6.3508 17 3.2168 13.866 3.2168 10C3.2168 6.13401 6.3508 3 10.2168 3C14.0828 3 17.2168 6.13401 17.2168 10Z"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default SearchIcon;
