import styled from 'styled-components/macro';
import { mediaSizes } from '../../styles/media';
import { Field, Form } from 'formik';
import MaskedInput from 'react-text-mask';
import Button from '../../components/Atoms/Button';
import { NavLink } from 'react-router-dom';

const Wrapper = styled.div`
  max-width: 987px;
  padding: 24px 24px 64px 24px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  ${mediaSizes.min768} {
    align-items: center;
    padding: 16px 32px 80px 32px;
  }
  ${mediaSizes.min1140} {
    padding: 24px 96px 80px 96px;
  }
`;
const Title = styled.div`
  font-family: ${p => p.theme.fontFamily.Libre};
  font-weight: 400;
  font-size: 24px;
  line-height: 40px;
  color: ${p => p.theme.colors.primary};
  text-align: center;
  margin-bottom: 40px;
  width: 100%;

  ${mediaSizes.min1140} {
    font-size: 32px;
    line-height: 48px;
    margin-bottom: 80px;
  }
`;
const Subtitle = styled.div`
  font-family: ${p => p.theme.fontFamily.Ubuntu};
  font-weight: 400;
  color: ${p => p.theme.colors.black};
  margin-bottom: 40px;
  font-size: 18px;
  line-height: 32px;

  ${mediaSizes.min1140} {
    font-family: ${p => p.theme.fontFamily.Libre};
    font-size: 24px;
    line-height: 40px;
    text-align: center;
  }
`;
const Addresses = styled.div`
  ${mediaSizes.min1140} {
    margin-bottom: 40px;
  }
`;
const AddressesItem = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: ${p => p.theme.colors.black};
  ${mediaSizes.min1140} {
    font-size: 18px;
    line-height: 32px;
  }
`;
const AddressesItemSpan = styled.span`
  display: inline-block;
  margin-right: 30px;
  min-width: 115px;
  text-align: right;
  ${mediaSizes.min1140} {
    min-width: 150px;
  }
`;
const BlockText = styled.div`
  margin: 40px 0;
`;
const BlockTextTitle = styled.div`
  font-weight: 700;

  color: ${p => p.theme.colors.black};
  margin-bottom: 16px;
  text-align: left;
  font-size: 14px;
  line-height: 24px;
  ${mediaSizes.min1140} {
    font-size: 18px;
    line-height: 32px;
  }
`;
const BlockTextDesc = styled.div`
  font-weight: 400;
  color: ${p => p.theme.colors.black};
  text-align: left;
  font-size: 14px;
  line-height: 24px;
  ${mediaSizes.min1140} {
    font-size: 18px;
    line-height: 32px;
  }
`;
const FormTitle = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  font-family: ${p => p.theme.fontFamily.Libre};
  color: ${p => p.theme.colors.primary};
  margin-bottom: 40px;
  text-align: center;
  ${mediaSizes.min1140} {
    font-size: 24px;
    line-height: 40px;
  }
`;
const Cards = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  ${mediaSizes.min1140} {
    margin-bottom: 40px;
  }
`;
const CardsItem = styled.img`
  display: block;
  & + & {
    margin-left: 24px;
  }
`;
const FormItem = styled(Form)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const SignButton = styled(Button)``;
const FormWrap = styled.div`
  width: calc(100% + 48px);
  margin-left: -24px;
  margin-right: -24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f0f1f2;
  padding: 32px 24px;
  border-radius: 4px;

  & + & {
    margin-top: 32px;
  }
  ${mediaSizes.min768} {
    margin: 0;
    max-width: 472px;
  }
  ${mediaSizes.min1140} {
    max-width: 795px;
    padding: 64px 64px 48px 64px;
  }
`;

const FormFieldWrap = styled.div`
  width: 100%;
  & + & {
    margin-top: 32px;
  }
  ${mediaSizes.min1140} {
    & + & {
      margin-top: 0;
      margin-left: 32px;
    }
  }
`;
const FormFieldLine = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  &.three {
    flex-direction: row;
    ${FormFieldWrap} {
      margin-top: 0;
      &:not(:last-child) {
        margin-right: 16px;
      }
    }
  }
  ${mediaSizes.min1140} {
    flex-direction: row;
    margin-bottom: 40px;
  }
`;
const FormFieldRow = styled.div`
  position: relative;
  input:focus ~ span,
  input:not([value='']) ~ span {
    top: 6px;
    left: 16px;
    font-size: 11px;
    line-height: 14px;
    opacity: 1;

    svg {
      margin-right: 5px;
      width: 12px;
      height: 12px;
    }
  }

  ${mediaSizes.min1140} {
    input:focus ~ span,
    input:not([value='']) ~ span {
      top: 10px;
      left: 16px;
      font-size: 11px;
      line-height: 14px;
      opacity: 1;

      svg {
        margin-right: 5px;
        width: 12px;
        height: 12px;
      }
    }
  }
`;
const FormFieldLabel = styled.span`
  display: flex;
  align-items: center;
  font-family: ${p => p.theme.fontFamily.Ubuntu};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #8a8d94;
  position: absolute;
  pointer-events: none;
  top: 12px;
  left: 16px;
  transition: ${p => p.theme.transitions.regular};
  svg {
    margin-right: 11px;
  }
  ${mediaSizes.min1140} {
    font-size: 18px;
    line-height: 32px;
    top: 16px;
  }
`;
const FormField = styled(Field)`
  width: 100%;
  background: #ffffff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.04), 0 2px 8px rgba(0, 0, 0, 0.03),
    0 1.12694px 2.75474px rgba(0, 0, 0, 0.01);
  border-radius: 4px;
  outline: none;
  border: 2px solid transparent;
  height: 48px;
  padding: 20px 16px 4px 16px;
  font-family: ${p => p.theme.fontFamily.Ubuntu};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  transition: ${p => p.theme.transitions.regular};

  &.error {
    border: 2px solid #ff554a;
    background: #fff2f0;
  }
  &:disabled {
    background: #e3e5e8;
  }
  ${mediaSizes.min1140} {
    height: 64px;
    padding: 24px 16px 8px 16px;
    font-size: 18px;
    line-height: 32px;
    border-radius: 8px;
  }
`;
const FormFieldMasked = styled(MaskedInput)`
  width: 100%;
  background: #ffffff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.04), 0 2px 8px rgba(0, 0, 0, 0.03),
    0 1.12694px 2.75474px rgba(0, 0, 0, 0.01);
  border-radius: 4px;
  outline: none;
  border: 2px solid transparent;
  height: 48px;
  padding: 20px 16px 4px 16px;
  font-family: ${p => p.theme.fontFamily.Ubuntu};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  transition: ${p => p.theme.transitions.regular};

  &.error {
    border: 2px solid #ff554a;
    background: #fff2f0;
  }
  &:disabled {
    background: #e3e5e8;
  }
  ${mediaSizes.min1140} {
    height: 64px;
    padding: 24px 16px 8px 16px;
    font-size: 18px;
    line-height: 32px;
    border-radius: 8px;
  }
`;
const FormSubmitButton = styled(Button)`
  ${mediaSizes.min1140} {
    max-width: 265px;
  }
`;
const Error = styled.div`
  font-family: ${p => p.theme.fontFamily.Ubuntu};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #e42208;
  margin-top: 4px;
  ${mediaSizes.min1140} {
    font-size: 14px;
    line-height: 24px;
  }
`;
const FormCheckBoxRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  ${mediaSizes.min1140} {
    margin-bottom: 64px;
  }
`;
const FormCheckBoxWrap = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;
const FormCheckbox = styled(Field)`
  width: 24px;
  height: 24px;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 2;
`;

const FormCheckBoxIcon = styled.div`
  width: 24px;
  height: 24px;
`;

const FormCheckBoxLabel = styled.label`
  font-family: ${p => p.theme.fontFamily.Ubuntu};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #323537;
  margin-left: 10px;
  cursor: pointer;

  &.checkbox {
    display: block;
    margin-bottom: 8px;
  }

  ${mediaSizes.min1140} {
    font-size: 18px;
    line-height: 32px;
  }
`;
const FormCheckBoxLink = styled(NavLink)`
  color: ${p => p.theme.colors.blue};
  border-bottom: 1px solid ${p => p.theme.colors.blue};
  transition: ${p => p.theme.transitions.regular};

  &:hover {
    color: ${p => p.theme.colors.pink};
    border-bottom: 1px solid ${p => p.theme.colors.pink};
  }
`;
const Failed = styled.div`
  margin-top: 48px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
const FailedBox = styled.div`
  width: 100%;
  background: #ffdad5;
  border: 1px solid #ff554a;
  border-radius: 4px;
  padding: 16px;
  margin-bottom: 24px;
`;
const FailedBoxTitle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #cb1800;
  text-align: center;
  margin-bottom: 4px;
`;
const FailedBoxSubtitle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #cb1800;
  text-align: center;
`;
const FailedTitle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: ${p => p.theme.colors.black};
  margin-bottom: 4px;
`;
const FailedItems = styled.ul`
  margin: 0;
  margin-left: -15px;
`;
const FailedItem = styled.li`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #606467;
`;

const ButtonSignWrap = styled.div`
  width: 100%;
  ${mediaSizes.min1140} {
    max-width: 265px;
  }
`;

export {
  Wrapper,
  Title,
  Subtitle,
  Addresses,
  AddressesItem,
  AddressesItemSpan,
  BlockText,
  BlockTextTitle,
  BlockTextDesc,
  SignButton,
  FormTitle,
  FormField,
  FormItem,
  FormWrap,
  FormFieldLine,
  FormFieldWrap,
  FormFieldRow,
  FormFieldLabel,
  FormSubmitButton,
  Error,
  Cards,
  CardsItem,
  FormCheckbox,
  FormCheckBoxIcon,
  FormCheckBoxRow,
  FormCheckBoxWrap,
  FormCheckBoxLabel,
  FormCheckBoxLink,
  Failed,
  FailedBox,
  FailedBoxTitle,
  FailedBoxSubtitle,
  FailedTitle,
  FailedItems,
  FailedItem,
  FormFieldMasked,
  ButtonSignWrap,
};
