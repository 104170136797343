// core
import React from 'react';

// components
import Header from '../Partials/Header';
import Footer from '../Partials/Footer';
import { useWindowSize } from 'react-use';
import HeaderMob from '../Partials/HeaderMob';
import { ChildrenWrap } from './styles';

const Layout = ({ children }: any) => {
  const { width } = useWindowSize();
  return (
    <>
      {width <= 1024 ? <HeaderMob /> : <Header />}
      <ChildrenWrap>{children}</ChildrenWrap>
      <Footer />
    </>
  );
};

export default Layout;
