/*
 * Media queries utility
 */

import {
  css,
  DefaultTheme,
  CSSObject,
  InterpolationFunction,
  ThemedStyledProps,
  Interpolation,
  FlattenInterpolation,
} from 'styled-components';

export const sizes = {
  xxxsmall: 375,
  xxsmall: 480,
  xsmall: 660,
  small: 768,
  medium: 1024,
  xsmedium: 1140,
  smedium: 1280,
  lmedium: 1366,
  large: 1440,
  slarge: 1680,
  xlarge: 1920,
};

export const mediaSizes = {
  min375: `@media (min-width: ${sizes.xxxsmall}px)`,
  min480: `@media (min-width: ${sizes.xxsmall}px)`,
  min660: `@media (min-width: ${sizes.xsmall}px)`,
  min768: `@media (min-width: ${sizes.small}px)`,
  min1024: `@media (min-width: ${sizes.medium}px)`,
  min1140: `@media (min-width: ${sizes.xsmedium}px)`,
  min1280: `@media (min-width: ${sizes.smedium}px)`,
  min1366: `@media (min-width: ${sizes.lmedium}px)`,
  min1440: `@media (min-width: ${sizes.large}px)`,
  min1680: `@media (min-width: ${sizes.slarge}px)`,
  min1920: `@media (min-width: ${sizes.xlarge}px)`,

  max375: `@media (max-width: ${sizes.xxxsmall}px)`,
  max480: `@media (max-width: ${sizes.xxsmall}px)`,
  max660: `@media (max-width: ${sizes.xsmall}px)`,
  max768: `@media (max-width: ${sizes.small}px)`,
  max1024: `@media (max-width: ${sizes.medium}px)`,
  max1140: `@media (max-width: ${sizes.xsmedium}px)`,
  max1280: `@media (max-width: ${sizes.smedium}px)`,
  max1366: `@media (max-width: ${sizes.lmedium}px)`,
  max1440: `@media (max-width: ${sizes.large}px)`,
  max1680: `@media (max-width: ${sizes.slarge}px)`,
  max1920: `@media (max-width: ${sizes.xlarge}px)`,
};

// Iterate through the sizes and create a media template
export const media = (Object.keys(sizes) as Array<keyof typeof sizes>).reduce(
  (acc, label) => {
    acc[label] = (first: any, ...interpolations: any[]) => css`
      @media (min-width: ${sizes[label]}px) {
        ${css(first, ...interpolations)}
      }
    `;

    return acc;
  },
  {} as { [key in keyof typeof sizes]: MediaFunction },
);

/*
 * @types/styled-component is not working properly as explained in the github issue referenced above.
 * We must overcome this with custom typings, however, this might not work in time as the styled-components update.
 * Be carefull and keep an eye on the issue and the possible improvements
 */
type MediaFunction = <P extends object>(
  first:
    | TemplateStringsArray
    | CSSObject
    | InterpolationFunction<ThemedStyledProps<P, DefaultTheme>>,
  ...interpolations: Array<Interpolation<ThemedStyledProps<P, DefaultTheme>>>
) => FlattenInterpolation<ThemedStyledProps<P, DefaultTheme>>;
