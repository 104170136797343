import React from 'react';
import { useWindowSize } from 'react-use';
import { listData } from './data';
import {
  Button,
  ListCont,
  ListItem,
  ListMarker,
  ListText,
  Title,
  Wrapper,
} from './styles';

const ServicesComp = () => {
  const { width } = useWindowSize();

  return (
    <Wrapper>
      <Title>
        Complimentary Property <br /> Tax Appeal Analysis Includes:
      </Title>
      <ListCont>
        {listData.map((el, i) => (
          <ListItem key={i}>
            <ListMarker>{el.marker}</ListMarker>
            <ListText dangerouslySetInnerHTML={{ __html: el.text }} />
          </ListItem>
        ))}
      </ListCont>
      <Title>
        Hire us today to start a successful appeal.
        <br />
        We know the winning strike zone...
        {width <= 480 ? null : <br />}
        or do nothing and watch your bill creep up!
      </Title>
      <Button to="/#analysis" theme={'fill'}>
        I want to sign up now!
      </Button>
    </Wrapper>
  );
};

export default ServicesComp;
