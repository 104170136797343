// core
import React from 'react';

// styles

import {
  Wrapper,
  Container,
  LogoWrap,
  Logo,
  Contacts,
  ContactsItem,
  ContactsItemIcon,
  ContactsItemLink,
  Socials,
  SocialsText,
  SocialsIcon,
  Rights,
  RightsSpan,
  Docs,
  DocsLink,
  Separator,
  Content,
  RightsMob,
  MobLogoSocial,
} from './styles';

// icons

import MapIcon from './icons/Map';
import PhoneIcon from './icons/Phone';
import EmailIcon from './icons/Email';
import FBIcon from './icons/FB';
import InstagramIcon from './icons/Instagram';
import { useWindowSize } from 'react-use';

const Footer = () => {
  const { width } = useWindowSize();

  return (
    <>
      {width <= 480 ? (
        <Wrapper>
          <Content>
            <Container>
              <Contacts>
                <ContactsItem>
                  <ContactsItemIcon>
                    <MapIcon />
                  </ContactsItemIcon>
                  <ContactsItemLink
                    href={'https://goo.gl/maps/ntTyaC5RZbYCEMjn7'}
                    target={'_blank'}
                  >
                    15000 S. Cicero Avenue Suite 101 <br /> Oak Forest, IL 60452
                  </ContactsItemLink>
                </ContactsItem>
                <ContactsItem>
                  <ContactsItemIcon>
                    <EmailIcon />
                  </ContactsItemIcon>
                  <ContactsItemLink href={'mailto:info@illtaxappeals.com'}>
                    info@illtaxappeals.com
                  </ContactsItemLink>
                </ContactsItem>
                <ContactsItem>
                  <ContactsItemIcon>
                    <PhoneIcon />
                  </ContactsItemIcon>
                  <ContactsItemLink href={'tel:7083120087'}>
                    (708) 312-0087
                  </ContactsItemLink>
                </ContactsItem>
              </Contacts>
            </Container>
            <Separator />
            <Container>
              <MobLogoSocial>
                <LogoWrap to={'/'}>
                  <Logo src={'/img/logo.svg'} alt="" />
                </LogoWrap>
                <Socials>
                  <SocialsText>Let’s get social:</SocialsText>
                  <SocialsIcon href={'/'} target={'_blank'}>
                    <FBIcon />
                  </SocialsIcon>
                  <SocialsIcon href={'/'} target={'_blank'}>
                    <InstagramIcon />
                  </SocialsIcon>
                </Socials>
              </MobLogoSocial>
              <Docs>
                <DocsLink to={'/disclaimer'}>Disclaimer</DocsLink>
                <DocsLink to={'/terms-of-service'}>Terms of Service</DocsLink>
                <DocsLink to={'/privacy-policy'}>Privacy Policy</DocsLink>
              </Docs>
            </Container>
          </Content>
          <RightsMob>
            ©2022 <RightsSpan>ILPTA.</RightsSpan> All Rights Reserved.
          </RightsMob>
        </Wrapper>
      ) : (
        <Wrapper>
          <Content>
            <Container>
              <LogoWrap to={'/'}>
                <Logo src={'/img/logo.svg'} alt="" />
              </LogoWrap>
              <Contacts>
                <ContactsItem>
                  <ContactsItemIcon>
                    <MapIcon />
                  </ContactsItemIcon>
                  <ContactsItemLink
                    href={'https://goo.gl/maps/ntTyaC5RZbYCEMjn7'}
                    target={'_blank'}
                  >
                    15000 S. Cicero Avenue Suite 101 <br /> Oak Forest, IL 60452
                  </ContactsItemLink>
                </ContactsItem>
                <ContactsItem>
                  <ContactsItemIcon>
                    <EmailIcon />
                  </ContactsItemIcon>
                  <ContactsItemLink href={'mailto:info@illtaxappeals.com'}>
                    info@illtaxappeals.com
                  </ContactsItemLink>
                </ContactsItem>
                <ContactsItem>
                  <ContactsItemIcon>
                    <PhoneIcon />
                  </ContactsItemIcon>
                  <ContactsItemLink href={'tel:7083120087'}>
                    (708) 312-0087
                  </ContactsItemLink>
                </ContactsItem>
              </Contacts>
            </Container>
            <Container>
              <Socials>
                <SocialsText>Let’s get social:</SocialsText>
                <SocialsIcon href={'/'} target={'_blank'}>
                  <FBIcon />
                </SocialsIcon>
                <SocialsIcon href={'/'} target={'_blank'}>
                  <InstagramIcon />
                </SocialsIcon>
              </Socials>
              <Rights>
                ©2022 <RightsSpan>ILPTA.</RightsSpan> All Rights Reserved.
              </Rights>
              <Docs>
                <DocsLink to={'/disclaimer'}>Disclaimer</DocsLink>
                <DocsLink to={'/terms-of-service'}>Terms of Service</DocsLink>
                <DocsLink to={'/privacy-policy'}>Privacy Policy</DocsLink>
              </Docs>
            </Container>
          </Content>
          {width <= 1024 && (
            <>
              <Separator />
              <RightsMob>
                ©2022 <RightsSpan>ILPTA.</RightsSpan> All Rights Reserved.
              </RightsMob>
            </>
          )}
        </Wrapper>
      )}
    </>
  );
};

export default Footer;
