import React from 'react';
import { Svg } from './styles';

const PhoneIcon = ({ props }: any) => (
  <Svg viewBox="0 0 24 24" {...props}>
    <path
      d="M3.2168 5C3.2168 3.89543 4.11223 3 5.2168 3H8.49604C8.92647 3 9.30861 3.27543 9.44472 3.68377L10.9425 8.17721C11.0999 8.64932 10.8862 9.16531 10.4411 9.38787L8.1838 10.5165C9.28605 12.9612 11.2556 14.9308 13.7003 16.033L14.8289 13.7757C15.0515 13.3306 15.5675 13.1169 16.0396 13.2743L20.533 14.7721C20.9414 14.9082 21.2168 15.2903 21.2168 15.7208V19C21.2168 20.1046 20.3214 21 19.2168 21H18.2168C9.93253 21 3.2168 14.2843 3.2168 6V5Z"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default PhoneIcon;
