// core
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import BurgerIcon from '../../Icons/Burger';
import CrossIcon from '../../Icons/Cross';
import FBIcon from '../Footer/icons/FB';
import InstagramIcon from '../Footer/icons/Instagram';

// styles

import {
  Wrapper,
  LogoWrap,
  Logo,
  Navigation,
  NavigationLink,
  HomePageIcon,
  NavMenuWrapper,
  NavMenu,
  MenuBurger,
  MenuBurgerWrap,
  NavWrapper,
  Socials,
  SocialsText,
  SocialsIcon,
  Docs,
  DocsLink,
  NavFooterWrap,
} from './styles';

const HeaderMob = () => {
  const location = useLocation();
  const [opened, toggleMenu] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        toggleMenu(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  useEffect(() => {
    toggleMenu(false);
  }, [location]);

  // {opened ? {"Close"} : {"Menu"}}
  return (
    <Wrapper ref={ref} className={opened ? 'opened' : ''}>
      <NavWrapper>
        <LogoWrap to={'/'}>
          <Logo src={'/img/logo.svg'} alt="" />
        </LogoWrap>
        <MenuBurgerWrap onClick={() => toggleMenu(!opened)}>
          {opened ? 'Close' : 'Menu'}
          <MenuBurger>{opened ? <CrossIcon /> : <BurgerIcon />}</MenuBurger>
        </MenuBurgerWrap>
      </NavWrapper>
      <NavMenuWrapper className={opened ? 'opened' : ''}>
        <NavMenu>
          <NavigationLink
            to={'/'}
            className={navData => (navData.isActive ? 'active' : '')}
          >
            Home
          </NavigationLink>
          <NavigationLink
            to={'/about'}
            className={navData => (navData.isActive ? 'active' : '')}
          >
            About Us
          </NavigationLink>
          <NavigationLink
            to={'/qa'}
            className={navData => (navData.isActive ? 'active' : '')}
          >
            Q & A
          </NavigationLink>
          <NavigationLink
            to={'/tax-explained'}
            className={navData => (navData.isActive ? 'active' : '')}
          >
            Your tax bill explained
          </NavigationLink>
          <NavigationLink
            to={'/services'}
            className={navData => (navData.isActive ? 'active' : '')}
          >
            Services Provided
          </NavigationLink>
          <NavigationLink
            to={'/taxpayers'}
            className={navData => (navData.isActive ? 'active' : '')}
          >
            Satisfied taxpayers
          </NavigationLink>
        </NavMenu>
        <NavFooterWrap>
          <Socials>
            <SocialsText>Let’s get social:</SocialsText>
            <SocialsIcon href={'/'} target={'_blank'}>
              <FBIcon />
            </SocialsIcon>
            <SocialsIcon href={'/'} target={'_blank'}>
              <InstagramIcon />
            </SocialsIcon>
          </Socials>
          <Docs>
            <DocsLink to={'/disclaimer'}>Disclaimer</DocsLink>
            <DocsLink to={'/terms-of-service'}>Terms of Service</DocsLink>
            <DocsLink to={'/privacy-policy'}>Privacy Policy</DocsLink>
          </Docs>
        </NavFooterWrap>
      </NavMenuWrapper>
    </Wrapper>
  );
};

export default HeaderMob;
