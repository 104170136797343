import styled from 'styled-components/macro';
import { mediaSizes } from '../../../styles/media';
import { NavLink } from 'react-router-dom';
import HomeIcon from '../../Icons/Home';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 96px;
  max-width: 1440px;
  margin: 0 auto;
  position: relative;
  z-index: 15;
  ${mediaSizes.max1280} {
    padding: 16px;
  }
  ${mediaSizes.max1024} {
    display: none;
  }
`;
const LogoWrap = styled(NavLink)``;
const Logo = styled.img`
  width: 100px;
  height: 100px;
  object-fit: contain;
`;
const Navigation = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const HomePageIcon = styled(HomeIcon)``;

const NavigationLink = styled(NavLink)`
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  font-family: ${p => p.theme.fontFamily.Ubuntu};
  color: ${p => p.theme.colors.black};
  transition: ${p => p.theme.transitions.regular};
  border-bottom: 1px solid transparent;
  text-transform: capitalize;

  :not(:first-child):hover {
    color: ${p => p.theme.colors.pink};
    border-bottom: 1px solid ${p => p.theme.colors.pink};
  }

  svg {
    margin-bottom: 3px;
  }

  &.active {
    color: ${p => p.theme.colors.red};

    svg {
      color: ${p => p.theme.colors.red};
    }
  }

  & + & {
    margin-left: 48px;
  }

  svg {
    transition: ${p => p.theme.transitions.regular};
  }

  &:hover {
    svg {
      color: ${p => p.theme.colors.red};
    }
  }
`;

export { Wrapper, LogoWrap, Logo, Navigation, NavigationLink, HomePageIcon };
