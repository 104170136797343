import styled from 'styled-components/macro';
import { mediaSizes } from '../../styles/media';
import ButtonLink from '../../components/Atoms/ButtonLink';

const Wrapper = styled.div`
  padding: 24px 24px 64px 24px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  ${mediaSizes.min768} {
    align-items: center;
    padding: 16px 32px 80px 32px;
  }
  ${mediaSizes.min1140} {
    padding: 24px 96px 80px 96px;
  }
`;
const Title = styled.div`
  font-family: ${p => p.theme.fontFamily.Libre};
  font-weight: 400;
  font-size: 24px;
  line-height: 40px;
  color: ${p => p.theme.colors.primary};
  text-align: center;
  margin-bottom: 40px;
  width: 100%;

  ${mediaSizes.min1140} {
    font-size: 32px;
    line-height: 48px;
    margin-bottom: 80px;
  }
`;
const Subtitle = styled.div`
  font-family: ${p => p.theme.fontFamily.Ubuntu};
  font-weight: 400;
  color: ${p => p.theme.colors.primary};
  margin-bottom: 40px;
  font-size: 18px;
  line-height: 32px;
  text-align: center;

  max-width: 1248px;

  ${mediaSizes.min1140} {
    font-family: ${p => p.theme.fontFamily.Libre};
    font-size: 24px;
    line-height: 40px;
  }
`;
const Addresses = styled.div`
  margin-bottom: 40px;
  ${mediaSizes.min1140} {
    margin-bottom: 80px;
  }
`;
const AddressesItem = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: ${p => p.theme.colors.black};
  ${mediaSizes.min1140} {
    font-size: 18px;
    line-height: 32px;
  }
`;
const AddressesItemSpan = styled.span`
  display: inline-block;
  margin-right: 30px;
  min-width: 115px;
  text-align: right;
  ${mediaSizes.min1140} {
    min-width: 150px;
  }
`;
const Button = styled(ButtonLink)`
  max-width: 415px;
`;

export {
  Wrapper,
  Title,
  Subtitle,
  Addresses,
  AddressesItem,
  AddressesItemSpan,
  Button,
};
