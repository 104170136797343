// core
import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// components
import Layout from './components/Layout';
import Home from './containers/Home';
import CookCountyForm from './containers/CookCountyForm';
import AboutUs from './containers/AboutUs';
import PaymentForm from './containers/PaymentForm';
import NonCookCountyForm from './containers/NonCookCountyForm';
import IndustrialForm from './containers/IndustrialForm';
import IndustrialRequest from './containers/IndustrialRequest';
import QaComp from './containers/QA';
import ServicesComp from './containers/Services';
import DisclaimerComp from './containers/Disclaimer';
import TermsComp from './containers/Terms';
import PrivacyComp from './containers/Privacy';
import TaxComp from './containers/Tax';
import TaxpayersComp from './containers/Taxpayers';
import PaymentSuccess from './containers/PaymentSuccess';

// toast modal configuration
toast.configure({
  position: 'bottom-right',
  autoClose: 5000,
  hideProgressBar: true,
  newestOnTop: false,
  closeOnClick: true,
  rtl: false,
  draggable: true,
  pauseOnHover: true,
});

const useScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      window.scrollTo({ left: 0, top: element!.offsetTop, behavior: 'smooth' });
    } else {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 10);
    }
  }, [location]);
};

function App() {
  useScrollToTop();
  return (
    <>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/cook-county" element={<CookCountyForm />} />
          <Route path="/non-cook-county" element={<NonCookCountyForm />} />
          <Route path="/industrial" element={<IndustrialForm />} />
          <Route path="/payment-form/:id" element={<PaymentForm />} />
          <Route path="/payment-success/:id" element={<PaymentSuccess />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/qa" element={<QaComp />} />
          <Route path="/tax-explained" element={<TaxComp />} />
          <Route path="/services" element={<ServicesComp />} />
          <Route path="/disclaimer" element={<DisclaimerComp />} />
          <Route path="/taxpayers" element={<TaxpayersComp />} />
          <Route path="/terms-of-service" element={<TermsComp />} />
          <Route path="/privacy-policy" element={<PrivacyComp />} />
          <Route
            path="/industrial-request/:id"
            element={<IndustrialRequest />}
          />
          <Route path="*" element={<Home />} />
        </Routes>
      </Layout>
    </>
  );
}

export default App;
