import React from 'react';

const FBIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.981 0H6C2.70476 0 0 2.70476 0 6.01905V18C0 21.2952 2.70476 24 6 24H17.981C21.2952 24 24 21.2952 24 17.981V6.01905C24 2.70476 21.2952 0 17.981 0ZM15.219 12H12.9524V19.4286H10.0952V12H8.57143V8.95238H9.90476V7.65714C9.90476 6.4381 10.5143 4.51429 13.0857 4.51429H15.4286V7.04762H13.7714C13.5048 7.04762 13.1429 7.21905 13.1429 7.80952V8.95238H15.4857L15.219 12Z"
      fill="#F17575"
    />
  </svg>
);

export default FBIcon;
