import styled from 'styled-components/macro';

const Icon = styled.svg.attrs({
  version: '1.1',
  fill: 'none',
  xmlns: 'http://www.w3.org/2000/svg',
  xmlnsXlink: 'http://www.w3.org/1999/xlink',
})``;

export default Icon;
