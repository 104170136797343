// core
import React from 'react';

// styles
import {
  Content,
  Desc,
  LastUpdated,
  Separator,
  Title,
  Wrapper,
} from './styles';

const DisclaimerComp = () => {
  return (
    <Wrapper>
      <Content>
        <Title>Disclaimer</Title>
        <Separator />
        <LastUpdated>Last updated April 2022</LastUpdated>
        <Desc>
          The Illinois supreme court does not require certifications of
          specialities in the practice of law and therefore no specific
          certificate or recognition is required to practice property tax appeal
          practices in Illinois.
          <br />
          <br />
          The information on this website is for general purposes only and no
          opinion should be considered, construed or interpreted that a certain
          result will occur. The information on this website is not legal advice
          and does not create an attorney client relationship.
        </Desc>
      </Content>
    </Wrapper>
  );
};

export default DisclaimerComp;
