import styled from 'styled-components/macro';

const Wrapper = styled.div`
  width: 100%;
  height: 48px;
  cursor: pointer;
`;

const Outline = styled.button`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  padding: 0 20px;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  transition: ${p => p.theme.transitions.regular};
  cursor: pointer;
  color: #ee5252;
  background: transparent;
  border: 1px solid #ee5252;
  border-radius: 48px;

  &:hover {
    background: #ee5252;
    color: #ffffff;
  }

  &:disabled {
    color: #ffffff;
    background: #d2d3d6;
    border: 1px solid #d2d3d6;
    cursor: not-allowed;
  }
`;

const Fill = styled.button`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  padding: 0 20px;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  transition: ${p => p.theme.transitions.regular};
  cursor: pointer;
  background: #ee5252;
  color: #ffffff;
  border: 1px solid transparent;
  border-radius: 48px;
  &:hover {
    border: 1px solid #ee5252;
    color: #ee5252;
    background: transparent;
  }

  &:disabled {
    color: #ffffff;
    background: #d2d3d6;
    border: 1px solid #d2d3d6;
    cursor: not-allowed;
  }
`;

export { Wrapper, Outline, Fill };
