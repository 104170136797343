export const tableData = [
  {
    name: 'Land',
    operator: ' ',
    amount: '5.718',
    indent: false,
    border: false,
  },
  {
    name: 'Building',
    operator: '+',
    amount: '28.071',
    indent: false,
    border: false,
  },
  {
    name: 'Added Assessment',
    operator: '+',
    amount: '0',
    indent: false,
    border: false,
  },
  {
    name: 'Improvement Exemption',
    operator: '-',
    amount: '0',
    indent: false,
    border: true,
  },
  {
    name: 'Total Assessed Value',
    operator: '=',
    amount: '33.789',
    indent: true,
    border: false,
  },
  {
    name: 'Twp. Multiplier',
    operator: 'x',
    amount: '1.0000',
    indent: false,
    border: false,
  },
  {
    name: 'County Multiplier',
    operator: 'x',
    amount: '1.0000',
    indent: true,
    border: true,
  },
  {
    name: 'Equalized Value',
    operator: '',
    amount: '33.789',
    indent: false,
    border: false,
  },
  {
    name: 'Farm Land',
    operator: '+',
    amount: '29.731',
    indent: false,
    border: false,
  },
  {
    name: 'Farm Building',
    operator: '+',
    amount: '14.753',
    indent: false,
    border: false,
  },
  {
    name: 'Homestead Exemption',
    operator: '-',
    amount: '6.000',
    indent: false,
    border: false,
  },
  {
    name: 'Sr. Citizen Exemption',
    operator: '-',
    amount: '0',
    indent: false,
    border: false,
  },
  {
    name: 'Sr. Citizen Freeze Exemption',
    operator: '-',
    amount: '0',
    indent: false,
    border: false,
  },
  {
    name: 'Enterprise Zone Exemption',
    operator: '-',
    amount: '0',
    indent: false,
    border: false,
  },
  {
    name: ' ',
    operator: ' ',
    amount: '0',
    indent: false,
    border: false,
  },
  {
    name: 'Open Space Exemption',
    operator: '-',
    amount: '0',
    indent: false,
    border: true,
  },
  {
    name: 'Net Equalized Value',
    operator: '=',
    amount: '72.273',
    indent: false,
    border: false,
  },
  {
    name: 'Tax Rate',
    operator: 'x',
    amount: '5.8367',
    indent: false,
    border: true,
  },
  {
    name: 'Tax Before Exemptions',
    operator: '=',
    amount: '4,568.56',
    indent: false,
    border: false,
  },
  {
    name: 'Reduction due to Exemption',
    operator: '-',
    amount: '350.20',
    indent: false,
    border: true,
  },
  {
    name: 'Tax After Exemptions',
    operator: '=',
    amount: '4,218.36',
    indent: false,
    border: false,
  },
  {
    name: 'Other Tax',
    operator: '+',
    amount: '270.00',
    indent: false,
    border: true,
  },
  {
    name: 'Total Tax Due',
    operator: '=',
    amount: '4,488.36',
    indent: false,
    border: false,
  },
];
