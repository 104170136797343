export const questionData = [
  {
    question: 'Why should I appeal?',
    answerText:
      'All property owners have a right to appeal every year at three levels: the assessor, county board of review and the state level known as PTAB.',
    number: 1,
  },
  {
    question: 'What are the reasons to appeal?',
    answerText:
      `<ol>
        <li>
          <p><strong>A recent purchase</strong><p>
          <p>Your property has been purchased in the last three years.</p>
        </li>
        <li>
          <p><strong>Lack of uniformity</strong><p>
          <p>This is the most common reason and most successful reason to appeal. The Illinois constitution requires that your property be assessed per sq. ft as similar properties.</p>
        </li>
        <li>
          <p><strong>Factual error</strong><p>
          <p>Using the grounds that the assessor has physical characteristics on your property which is incorrect and therefore your assessment requires a reduction. </p>
        </li>
        <li>
          <p><strong>Vacancy</strong><p>
          <p>If your property is uninhabitable for a period of time due to rehab, fire, loss of tenant, flood or government closings ie. COVID.</p>
        </li>
      </ol>`,
    number: 2,
  },
  {
    question: 'How do I appeal?',
    answerText:
      'Submitting evidence to support why your assessment is too high, either by yourself if residential or by hiring a professional tax appeal firm or tax law firm. Corporate owned property in Illinois requires law firm representation. Filing evidence requires filing during the published time frames posted in each county/township and at each level of appeal timely each appeal year.',
    number: 3,
  },
  {
    question: 'When do I appeal?',
    answerText:
      'You have the right to appeal every year at the 3 levels. Assessor, County Board of review and ILLINOIS Property Tax Appeals Board. The township assessor appeal schedule changes every year. Contact your township assessor to obtain your township filing deadline.',
    number: 4,
  },
  {
    question:
      'How will I know how much my bill will go down the following year after I appeal?',
    answerText:
      'Once you receive your decision, any assessment reduction times your tax rate will project how much lower your next year bill is reduced.',
    number: 5,
  },
  {
    question: 'How do I know I’m over-assessed?',
    answerText:
      "7 out of 10 houses on a block typically are over paying VS. their neighbors. To find out how much you are over assessed, provide your property information to our search engine and receive your complimentary savings analysis.    ",
    number: 6,
  },
  {
    question: 'How often does my property get re-assessed?',
    answerText:
      'In Cook County, every 3 years the assessor proposes a typical "increased assessment letter" to you. It is up to you to fight it according to his township schedule. In the other 101 counties of Illinois, you get re-assessed every 1- 4 years. It varies from township to township.',
    number: 7,
  },
  {
    question:
      'Will the assessor raise my assessment or my neighbor’s assessment if I appeal?',
    answerText:
      "Typically, \"No\", they usually will only issue a (no change decision) or a lower assessment decision on a appeal. This process is a poor man’s court and you have a right to appeal every year. If you don’t appeal, your tax will continue to creep up!",
    number: 8,
  },
  {
    question: 'How does the assessor assess?',
    answerText:
      'Typically, the use of a mass appraisal computer modeling method using various data sets. <strong>ILLINOIS Property Tax Appeals</strong> uses the full township data sets of info on each property and uses exclusive proprietary computer algorithms to derive good assessment evidence to lower your assessment. The assessor computer generates many errors because they generate assessment on 30,000 to 2,000,000 properties in a short window each year. Appeals help generate fair and equitable assessment by cleansing out mistakes. This is why you should always appeal.',
    number: 9,
  },
  {
    question: 'How is my tax bill calculated?',
    answerText:
      'Your bill utilizes the assessment derived based on the market value the assessor places on your property. The assessment X the local tax rate less any exemptions (you are entitled to) equals your bill.',
    number: 10,
  },
];
