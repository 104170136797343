import styled from 'styled-components/macro';
import ButtonLink from '../../components/Atoms/ButtonLink';
import { mediaSizes } from '../../styles/media';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0 80px 0;
`;

const Title = styled.div`
  font-family: ${p => p.theme.fontFamily.Libre};
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 48px;
  text-align: center;
  color: ${p => p.theme.colors.primary};

  ${mediaSizes.max1024} {
    padding: 0 44px;
  }
  ${mediaSizes.max480} {
    padding: 0 24px;
    font-size: 24px;
    line-height: 40px;

    &:first-child {
      padding: 0 18px;
    }
  }
`;

const SubTitle = styled(Title)`
  max-width: 1011px;
`;

const Button = styled(ButtonLink)`
  max-width: 376px;
  margin-top: 40px;

  ${mediaSizes.max480} {
    padding: 0 24px;
`;

const ListCont = styled.div`
  display: flex;
  flex-direction: column;
  margin: 80px 0;
  /* max-width: 960px; */
  max-width: 1040px;

  ${mediaSizes.max1024} {
    padding: 0 64px;
    margin: 40px 0 64px 0;
  }
  ${mediaSizes.max480} {
    padding: 0 24px;
    margin: 40px 0;
  }
`;

const ListItem = styled.div`
  display: flex;
  align-items: flex-start;

  & + & {
    margin-top: 16px;
  }
`;

const ListMarker = styled.div`
  min-width: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${p => p.theme.colors.primary};
  font-family: ${p => p.theme.fontFamily.Libre};
  font-weight: 400;
  font-size: 24px;
  line-height: 40px;
  margin-right: 16px;

  ${mediaSizes.max1024} {
    margin-right: 8px;
  }
  ${mediaSizes.max480} {
    margin-right: 0;
    min-width: 32px;
    font-family: ${p => p.theme.fontFamily.Ubuntu};
    justify-content: flex-start;
    font-size: 18px;
    line-height: 32px;
  }
`;

const ListText = styled.div`
  font-family: ${p => p.theme.fontFamily.Libre};
  color: ${p => p.theme.colors.primary};
  font-weight: 400;
  font-size: 24px;
  line-height: 40px;

  ${mediaSizes.max480} {
    font-family: ${p => p.theme.fontFamily.Ubuntu};
    font-size: 18px;
    line-height: 32px;
  }
`;

export {
  Wrapper,
  Title,
  SubTitle,
  Button,
  ListCont,
  ListItem,
  ListMarker,
  ListText,
};
