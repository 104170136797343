import React, { useEffect, useRef, useState } from 'react';
import {
  AnswerDesc,
  AnswerTitle,
  ButtonClose,
  Question,
  QuestionAnswer,
  QuestionNumber,
  QuestionText,
} from './styles';

interface PropsType {
  number: number;
  question: string;
  answer: string;
}

const QuestionComp = ({ number, question, answer }: PropsType) => {
  const [opened, togglePopup] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (opened) {
      ref.current?.scrollIntoView({ block: 'center' });
    }
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        togglePopup(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, opened]);

  return (
    <>
      <Question>
        <QuestionAnswer
          ref={ref}
          style={opened ? { opacity: 1, visibility: 'visible' } : {}}
        >
          <AnswerTitle>{question}</AnswerTitle>
          <AnswerDesc dangerouslySetInnerHTML={{ __html: answer }} />
          <div onClick={() => togglePopup(false)}>
            <ButtonClose type="button" theme={'outline'}>
              Close
            </ButtonClose>
          </div>
        </QuestionAnswer>
        <QuestionNumber>{number}.</QuestionNumber>
        <QuestionText onClick={() => togglePopup(true)}>
          {question}
        </QuestionText>
      </Question>
    </>
  );
};

export default QuestionComp;
