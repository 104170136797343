// core
import React from 'react';

// data
import { termsListData } from './data';

// styles
import {
  Content,
  Desc,
  LastUpdated,
  Separator,
  SubTitle,
  Title,
  Wrapper,
} from './styles';

const TermsComp = () => {
  return (
    <Wrapper>
      <Content>
        <Title>Terms of Service</Title>
        <Separator />
        <LastUpdated>Last updated April 2022</LastUpdated>
        <SubTitle>1. Acceptance of Service</SubTitle>
        <Desc>
          This website is owned by Illinois Property Tax Inc.. By using or
          visiting the Illinois Property Tax Inc. website(s) or any component
          and/or services provided to you, from or through the Illinois Property
          Tax Inc. ‘s (known as ILPTINC in this written terms of service),
          website (collectively our service), you signify your agreement to
          these items of terms of services and the company’s privacy notice.
          Privacy notice is found at www.illtaxappeals.com and incorporated
          herein by reference. If you don’t agree to any of these terms of
          services or privacy notice, please don’t use the services and lease
          visiting this site. We may attempt to notify when major changes are
          made to the terms of service, it is your responsibility to review from
          time to time and rely on the most up to date version on
          www.iltaxappeals.com ILPTINC., at its sole discretion may revise or
          modify the Terms of Service and/or policies. The reader and/or user
          agrees to be bound by such changes or reasons. None of these Terms of
          Services shall be deemed to grant any third party benefits or rights.
        </Desc>
        <SubTitle>2. Services</SubTitle>
        <Desc>
          A. These terms of services apply to all users of the services,
          including any users who also are contributors of Contents of Services.
          Content includes software, graphic features and all texts which may be
          viewed or accessed through this website. This website exists to
          provide users with a complete catalog of services offered by the
          company as well as educational information relative to assessment,
          appeals of assessment, exemptions and any collection of property
          taxes; collection of service fees via credit card and it includes tax
          refunds processed as well in Illinois. The objective of this website
          is to educate property owners about their right to tax relief, whether
          they engage ILPTINC. or not to file an appeal. The Services includes
          any and all products and services offered by ILPTINC. via this
          website.
          <br />
          <br />
          B. The Services contains links to third parties websites that are not
          owned and/or controlled by ILPTINC.. ILPTINC. can’t edit nor censor
          the content of these third party websites. Use of any services,
          expressly relieves iLPTINC. from any and all liability from any users
          who use these party websites.
          <br />
          <br />
          C. Please read the Terms of Services and our Private Policy terms, as
          well as the Terms/Policies of the third party service providers
          involved.
          <br />
          <br />
          D. Users are granted permission to access and use services, however
          restrictions/permissions can’t be dismissed in any medium for any part
          of our Services. This applies to content provided without written
          permission from ILPTINC. Further, users agree to be restricted from
          altering or changing any parts of the Services provided. Users agree
          to not access Content through the use of technology or any other
          modifying means other than viewing the pages of Service..
          <br />
          <br />
          E. Users agree to not use the service of any commercial uses unless
          you obtain the written approval from ILPTINC.
          <br />
          <br />
          F. Users agree not to use, copy or launch any automated systems i.e.
          spiders, robots and any offline readers that access our Services in a
          manner that sends more request messages to ILPTINC. than a typical
          company and it’s employees can reasonably handle from a typical web
          browser. IPTINC. grants the operators of public search engines
          permission to use spiders to copy materials from the site for the sole
          purpose of and solely for the extent needed to create public
          searchable material available to inform the public, but not to cache
          these materials. The ILPTINC. reserves the right to revoke these
          rights as a general purpose or for specific cases. Users/agents agree
          to not collect or use harvesting practices to obtain personal
          information, such as account names, from the service, nor to use
          communication type systems provided by ILPTINC.’s Service for any
          commercial solicitation services. You agree not to solicit, for
          commercial use, any users of the Service with respect to our content.
          <br />
          <br />
          G. Use of ILPTINC. Service requires you to comply with all applicable
          laws.
          <br />
          <br />
          H. ILPTINC. reserves the right to discontinue any and all aspects of
          the Services at any time.
        </Desc>
        <SubTitle>3. Use of Content</SubTitle>
        <Desc>
          Additionally, the following overall restrictions and conditions apply
          to the use of ILPTINC.
          <br />
          <br />
          A. The Content of the Service, trademarks, service marks, and logos on
          the Service is owned or licensed by ILPTINC. ILPTINC. enjoys and users
          are subject to copyright and other intellectual property rights under
          law.
          <br />
          <br />
          B. Content is provided as stated. Access rights are granted for your
          information and personal use solely for the intended functions
          provided on the website domain. These are permitted under the Terms of
          Service. You shall not copy, reproduce, transmit, broadcast, license,
          distribute nor exploit any Content for any purposes without prior
          written consent of the ILPTINC or the respective licensors of the said
          Content. ILPTINC. and its licensors reserve all rights whether
          expressly granted or not to the Service and Content. From time to
          time, ILPTINC. may choose to explicitly make certain material
          available to be downloaded for personal, but not commercial use.
          <br />
          <br />
          C. User, agrees to not disable, circumvent or interfere with any
          security related features of Service which prevents or restricts use
          of copying of any Content. User agrees to not enforce limitations on
          any use of Service or the Content therein this website.
          <br />
          <br />
          D. Any user of the Service and/or Content provided agrees to waive, or
          hereby waive any legal or equitable rights or remedies the user may
          have against ILPTINC. with respect thereto and to the extent permitted
          by applicable law. User also agrees to indemnify and hold harmless
          ILPTINC, its owners, operators, affiliates, agents, licensors, and
          licensees to the fullest extent allowed by law regarding all matters
          related to your use of Service.
          <br />
          <br />
          E. User is required to sign and adhere to the terms of ILPTINC.’s
          Property Tax engagement letter for property tax appeal services.
          Specifically in said engagement letter the following: User/Property
          owner agrees for ILPTINC. To be authorized or its agents authorized,
          or some combination is authorized to represent the user to file appeal
          evidence to any governmental jurisdictions which oversee the tax
          assessment appeal process each year in Illinois. Further, the
          engagement letter clearly states: NO REDUCTION IN ASSESSMENT IS
          GUARANTEED TO ANY USER, CLIENT, OR PROPERTY TAX OWNER WHOSE SIGNATURE
          IS CONTAINED ON LETTER NOR IS AN ASSESSMENT REDUCTION PROMISED IN ANY
          WAY. ILPTINC. AND IT’S THIRD PARTIES, LICENSORS, AGENTS SHALL HAVE NO
          LIABILITY WITH REGARD TO DECISIONS RENDERED BY ANY GOVERNMENT AGENCY..
          ADDITIONALLY ANY CANCELLATION OF THE ENGAGEMENT LETTER NEEDS TO BE IN
          WRITING. THE SALE OF PROPERTY DOES NOT RELEASE CLIENT, USER, PROPERTY
          OWNER FROM THE ENGAGEMENT AND WARRANT A REFUND. AFTER MULTIYEAR FILING
          ATTEMPTS RENDER UNSUCCESSFUL ASSESSMENT REDUCTIONS, A CASE BY CASE
          BASIS WILL BE USED FOR REFUND CONSIDERATION, BUT IS NOT A PROMISED
          EXCLUSIVE OUTCOME. CLIENT, USER, PROPERTY OWNER ACKNOWLEDGES THERE IS
          NO EXPLICIT POLICY OF FEE REFUNDS AND IT IS TOTALLY UP TO ILPTINC
          WHETHER ANY REFUND IS GRANTED.
          <br />
          <br />
          F. ILPTINC. reserves the right to terminate a user’s access to the
          Serviceif, under appropriate circumstance, the user or client is
          determined to be a repeat infringer of the rules and standards
          contained in there terms of Service.
        </Desc>
        <SubTitle>4. Digital Millennium Copyright Act</SubTitle>
        <Desc>
          If you are a copyright owner or an agent thereof and believe that any
          Content fringes upon your copyrights, you may submit a notification
          pursuant to the Digital millennium Copyright Act by providing our
          copyright agent with the following information in writing (see
          17U.S.C.512(c)(3) for further details)
          <br />
          <br />
          A. A physical or electronic signature of a person authorized to act on
          behalf of the owner of an exclusive right that is allegedly infringed:
          <br />
          <br />
          B. Identification of the copyrighted work claimed to have been
          infringed, or, if multiple copyrighted works at a single online site
          are covered by a single notification, a representative list of said
          works at that site;
          <br />
          <br />
          C. Identification of the material that is claimed to be infringing or
          to be the subject of infringing activity and that is to be removed or
          access to which is to be disabled and information reasonably
          sufficient to permit the service provider to locate the service
          provider to locate the material;
          <br />
          <br />
          D. Information reasonably sufficient to permit the service provider to
          contact you, such as an address, telephone number, and if available ,
          an electronic mail;
          <br />
          <br />
          E. A statement that you have a good faith belief that use of the
          material in the manner complained of is not authorized by the
          copyright owner, its agent or the law;
          <br />
          <br />
          F. A statement of the information in the notification is accurate ,
          and under penalty of perjury, that you are authorized to act on behalf
          of the owner of an exclusive right that is allegedly infringed. The
          company’s designated Copyright agent to receive notifications of
          claimed infringement is Robert Hattar 15000 S Cicero Ave Suite 101 Oak
          Forest, Il 60452. Further clarification; only DMCA notices should go
          to the copyright Agent; any other feedback, comments, requests for
          technical support and other communications should be directed to
          ILPTINC. through the contact of the ILPTINC. website. You are herby
          informed and acknowledge that if you fail to comply with all the
          requirements of this section, your DMCA notice may not be valid.
        </Desc>
        <SubTitle>5. Warranty Disclaimer</SubTitle>
        <Desc>
          YOU AGREE THAT YOUR USE OF THE SERVICES SHALL BE AT YOUR SOLE RISK, TO
          THE FULLEST EXTENT PERMITTED BY LAW, ILPTINC. ITS OFFICERS, DIRECTORS,
          AGENTS AND EMPLOYEES DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN
          CONNECTION WITH THE SERVICES AND YOUR USE OF THEREOF. THE FIRM MAKES
          NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF
          THIS SITE’S CONTENT OR THE CONTENT OF ANY SITES LINKED TO THIS SITE
          AND ASSUMES NO LIABILITY OR RESPONSIBILITY FOR ANY (!) ERRORS,
          MISTAKES, OR INACCURACIES OF CONTENT, (ll) PERSONAL INJURY OR PROPERTY
          DAMAGE, OF ANY This website is owned by Illinois Property Tax Inc.. By
          using or visiting the Illinois Property Tax Inc. website(s) or any
          component and/or services provided to you, from or through the
          Illinois Property Tax Inc. ‘s (known as ILPTINC in this written terms
          of service), website (collectively our service), you signify your
          agreement to these items of terms of services and the company’s
          privacy notice. Privacy notice is found at www.illtaxappeals.com and
          incorporated herein by reference. If you don’t agree to any of these
          terms of services or privacy notice, please don’t use the services and
          lease visiting this site. We may attempt to notify when major changes
          are made to the terms of service, it is your responsibility to review
          from time to time and rely on the most up to date version on
          www.iltaxappeals.com ILPTINC., at its sole discretion may revise or
          modify the Terms of Service and/or policies. The reader and/or user
          agrees to be bound by such changes or reasons. None of these Terms of
          Services shall be deemed to grant any third party benefits or
          rights.NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF OUR
          SERVICES, (lll) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE
          SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL
          INFORMATION STORED THEREIN, (lV) ANY BUGS, VIRUSES, TROJAN HORSES, OR
          SIMILAR MALWARE WHICH MAY BE TRANSMITTED TO OR THROUGH OUR SERVICES BY
          ANY THIRD PARTY, AND/OR (V) ANY ERROR OR OMISSIONS IN ANY CONTENT
          POSTED, EMAILED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE
          SERVICES. ILPTINC. DOES NOT ENDORSE, GUARANTEE, OR ASSUME
          RESPONSIBILITY FOR ANY PRODUCT OR SERVICES OFFERED BY A THIRD PARTY
          THROUGH THE SERVICES OF ANY HYPERLINKED SERVICES AND THE FIRM WILL NOT
          BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY
          TRANSACTION BETWEEN YOU AND THIRD PARTY PROVIDERS OF PRODUCTS OR
          SERVICES. YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE
          APPROPRIATE WHEN YOU PURCHASE ANY PRODUCT OR SERVICE.
        </Desc>
        <SubTitle>6. Limitation of Liability</SubTitle>
        <Desc>
          IN NO WAY OR EVENT SHALL IPTINC., ITS OFFICERS, AGENTS, EMPLOYEES, BE
          LIABLE TO YOU FOR ANY DIRECT, INDIRECT, INCIDENTALUNIQUE, SPECIAL
          PUNITIVE OR CONSEQUENTIAL DAMAGES WHATSOEVER RESULTING FROM (l) ANY
          ERRORS, MISTAKES OR INACCURACIES OF CONTENT (ll) PERSONAL INJURY OR
          PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM OUR ACCESS
          TO AND USE OF OUR SERVICES, (lll) ANY UNAUTHORIZED ACCESS TO OR USE OF
          OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR
          FINANCIAL INFORMATION STORED THEREIN, (lV) ANY INTERRUPTION OR
          RESTRICTION OF TRANSMISSION TO OR FROM OUR SERVICES (lV) ANY BUGS,
          TROJAN HORSES, VIRUSES AND MALWARE WHICH MAY BE TRANSMITTED TO OR
          THROUGH OUR SERVICES BY ANY THIRD PARTY, (V) THE FACT A USER OF THE
          SERVICES OBTAINED TAX RELIEF, OR DID NOT OBTAIN RELIEF, AND/OR (Vl)
          ANY ERRORS OR OMISSIONS IN ANY CONTENT OR FOR ANY LOSS OR DAMAGE OF
          ANY KIND INCURRED AS A RESULT OF YOUR USE OF ANY CONTENT POSTED,
          EMAILED, TRANSMITTED OR OTHERWISE MADE AVAILABLE VIA THE SERVICES,
          WHETHER BASED ON WARRANTY,CONTRACT, TORT, OR ANY OTHER LEGAL THEORY,
          AND WHETHER OR NOT THE FIRM IS ADVISED OF THE POSSIBILITY OF SUCH
          DAMAGES. THE FOREGOING LIMITATION OF LIABILITY SHALL APPLY TO THE
          FULLEST EXTENT PERMITTED BY LAW IN THE APPLICABLE JURISDICTION. YOU
          SPECIFICALLY ACKNOWLEDGE THAT ILPTINC. SHALL NOT BE LIABLE FOR CONTENT
          OR THE DEFAMATORY, OFFENSIVE, OR ILLEGAL CONDUCT OF ANY THIRD PARTY
          AND THAT THE RISK OF HARM OR DAMAGE FROM THE THE FOREGOING RESTS
          ENTIRELY WITH YOU. The services is controlled and offered in the state
          of Illinois. ILPTINC. Makes no representations that the service is
          appropriate or available for use in other locations. Anyone who
          accesses or uses the Service from other jurisdictions do so at their
          own peril and are responsible for compliance with local law.
        </Desc>
        <SubTitle>7. INDEMNITY</SubTitle>
        <Desc>
          As permitted by applicable law, you agree to defend, indemnify and
          hold harmless ILPTINC. , its subsidiary companies or any affiliates,
          officers, directors, employees, agents, from any and against any and
          all claims, damages, obligations, losses, liabilities, costs of debt,
          and expenses, ( including but not limited to attorneys fees) arising
          from (l) your use of and access to the service; (ii) your violation of
          any term of these Terms Of Service; (iii) your violation of any third
          party right, including without limitation any copyright, property, or
          privacy right; or (iv) any claim that your Content caused damage to a
          third party. This defense and indemnification obligation will survive
          these Terms of Service and your use of Service.
        </Desc>
        <SubTitle>8. ABILITY TO ACCEPT TERMS OF SERVICE</SubTitle>
        <Desc>
          You affirm that you are either 18 years of age or possess legal
          parental or guardian consent, and are fully able and competent to
          enter into terms, conditions, obligations, affirmations,
          representations and warranties set forth in these Terms of Service,
          and to abide by and comply with these Terms of Service.
        </Desc>
        <SubTitle>9. ASSIGNMENT</SubTitle>
        <Desc>
          These Terms of Service and any rights and license granted hereunder,
          may not be transferred or assigned by you, but may be assigned by
          ILPTINC. without restriction.
        </Desc>
        <SubTitle>10. You agree that:</SubTitle>
        <Desc>
          You agree that: (i) the Service shall be deemed solely based in
          Illinois; and(ii) the Service shall be deemed a passive website that
          does not give rise to personal jurisdiction over ILPTINC. , either
          specific or general, in jurisdictions other than Illinois. These Terms
          of Service shall be governed by the internal substantive laws of the
          State of Illinois, without respect to its conflict of laws principles.
          Any and all claims or disputes between you and ILPTINC which arises in
          part or in whole from the Service shall be decided exclusively by a
          court of competent jurisdiction located in Cook County, Illinois.
          These Terms of service, together with the Privacy Notice found on this
          website and any other legal notices published by ILPTINC. shall
          constitute the entire agreement between ILPTINC. and you. If any
          provision of these Terms of Service is deemed invalid by a court of
          competent jurisdiction, the invalidity of such provision shall not
          affect the validity of the remaining provisions of these Terms of
          service., which shall remain in full force. No waiver of any term of
          these Terms of Service shall be deemed further, or continuing waiver
          of such term or any other term and ILPTINC. ‘S failure to assert any
          right or provision under these Terms of Service shall not constitute a
          waiver of such right. IPTINC. Reserves the right to amend these Terms
          of Service at any time and without notice. It is your responsibility
          to review these Terms of Service for any provision changes. Your use
          of the Service following any amendments of these Terms of Service will
          signify your assent to and acceptance of these revised terms. YOU AND
          IPTINC AGREE THAT ANY CAUSE OF ACTION ARISING OUT OF OR RELATED TO THE
          SERVICES MUST COMMENCE WITHIN ONE YEAR AFTER THE CAUSE OF ACTION
          ACCRUES, OTHERWISE, SUCH CAUSE OF ACTION IS PERMANENTLY BARRED.
        </Desc>
      </Content>
    </Wrapper>
  );
};

export default TermsComp;
