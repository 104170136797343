import styled from 'styled-components/macro';
import { mediaSizes } from '../../styles/media';
import { Field, Form } from 'formik';
import Button from '../../components/Atoms/Button';
import ButtonLink from '../../components/Atoms/ButtonLink';

const Wrapper = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 0 64px 0;
  position: relative;

  ${mediaSizes.min768} {
    padding: 16px 32px 80px 32px;
  }

  ${mediaSizes.min1140} {
    padding: 24px 96px 80px 96px;
  }
`;
const Title = styled.div`
  font-family: ${p => p.theme.fontFamily.Libre};
  font-weight: 400;
  color: ${p => p.theme.colors.primary};
  text-align: center;

  font-size: 24px;
  line-height: 40px;
  margin-bottom: 24px;
  padding: 0 24px;

  ${mediaSizes.min768} {
    padding: 0;
    font-size: 32px;
    line-height: 48px;
    margin-bottom: 40px;
  }
  ${mediaSizes.min1140} {
    font-size: 40px;
    line-height: 56px;
    margin-bottom: 64px;
  }
`;
const Subtitle = styled.div`
  font-family: ${p => p.theme.fontFamily.Libre};
  font-weight: 400;
  padding: 0 24px;

  color: ${p => p.theme.colors.primary};
  text-align: center;

  font-size: 14px;
  line-height: 24px;
  margin-bottom: 40px;

  ${mediaSizes.min768} {
    padding: 0;
    font-weight: 400;
    font-size: 18px;
  }
  ${mediaSizes.min1140} {
    font-size: 24px;
    line-height: 40px;
    margin-bottom: 80px;
  }
`;
const FormWrapTitle = styled.div`
  font-family: ${p => p.theme.fontFamily.Libre};
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  color: #606467;
  margin-bottom: 32px;
  ${mediaSizes.min1140} {
    font-size: 24px;
    line-height: 40px;
    margin-bottom: 24px;
  }
`;

const FormItem = styled(Form)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const FormWrap = styled.div`
  width: 100%;
  background: #f0f1f2;
  margin: 0 auto;
  padding: 16px 24px 32px 24px;
  border-radius: 4px;

  & + & {
    margin-top: 32px;
  }

  ${mediaSizes.min1140} {
    padding: 32px 64px 48px 64px;
  }
`;
const FormSides = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${mediaSizes.min768} {
    flex-direction: row;
    align-items: flex-start;
  }
`;
const FormSidesLeft = styled.div`
  width: 100%;
  margin-bottom: 32px;
  ${mediaSizes.min768} {
    width: 50%;
    margin-right: 48px;
    margin-bottom: 0;
  }
  ${mediaSizes.min1140} {
    margin-right: 96px;
  }
`;
const FormSidesRight = styled.div`
  width: 100%;
  ${mediaSizes.min768} {
    width: 50%;
  }
`;
const FormFieldWrap = styled.div`
  & + & {
    margin-top: 32px;
  }
  &.solo {
    width: 100%;
    max-width: 688px;
    margin-bottom: 40px;
    padding: 0 24px;
  }
  &.long {
    input {
      max-width: none;
    }
    margin-top: 32px;
  }
  ${mediaSizes.min1140} {
    & + & {
      margin-top: 40px;
    }
    &.short {
      max-width: 175px;
    }
    &.longer {
      max-width: 192px;
    }
    &.long {
      input {
        max-width: 250px;
      }
      margin-top: 32px;
    }
  }
`;
const FormFieldRow = styled.div`
  position: relative;
  input:focus ~ span,
  input:not([value='']) ~ span {
    top: 6px;
    left: 16px;
    font-size: 11px;
    line-height: 14px;
    opacity: 1;

    svg {
      margin-right: 5px;
      width: 12px;
      height: 12px;
    }
  }

  ${mediaSizes.min1140} {
    input:focus ~ span,
    input:not([value='']) ~ span {
      top: 10px;
      left: 16px;
      font-size: 11px;
      line-height: 14px;
      opacity: 1;

      svg {
        margin-right: 5px;
        width: 12px;
        height: 12px;
      }
    }
  }
`;
const FormFieldLabel = styled.span`
  display: flex;
  align-items: center;
  font-family: ${p => p.theme.fontFamily.Ubuntu};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #8a8d94;
  position: absolute;
  pointer-events: none;
  top: 12px;
  left: 16px;
  transition: ${p => p.theme.transitions.regular};
  svg {
    margin-right: 11px;
  }
  ${mediaSizes.min1140} {
    font-size: 18px;
    line-height: 32px;
    top: 16px;
  }
`;

const FormFieldSubtitle = styled.div`
  font-family: ${p => p.theme.fontFamily.Ubuntu};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #8a8d94;
  margin-top: 4px;
`;
const FormField = styled(Field)`
  width: 100%;
  background: #ffffff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.04), 0 2px 8px rgba(0, 0, 0, 0.03),
    0 1.12694px 2.75474px rgba(0, 0, 0, 0.01);
  border-radius: 4px;
  outline: none;
  border: 2px solid transparent;
  height: 48px;
  padding: 20px 16px 4px 16px;
  font-family: ${p => p.theme.fontFamily.Ubuntu};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  transition: ${p => p.theme.transitions.regular};

  &.error {
    border: 2px solid #ff554a;
    background: #fff2f0;
  }
  &:disabled {
    background: #e3e5e8;
  }
  ${mediaSizes.min1140} {
    height: 64px;
    padding: 24px 16px 8px 16px;
    font-size: 18px;
    line-height: 32px;
    border-radius: 8px;
  }
`;

const FormFieldRowCouple = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 32px;
  width: 100%;

  &.first {
    margin-top: 0;
    margin-bottom: 32px;
  }
  ${FormFieldWrap} {
    width: 100%;
    margin-top: 0;
    &:nth-child(2) {
      margin-left: 16px;
    }
  }
  ${mediaSizes.min1140} {
    display: flex;
    align-items: flex-start;
    margin-top: 40px;

    &.first {
      margin-top: 0;
      margin-bottom: 40px;
    }
    ${FormFieldWrap} {
      margin-top: 0;
      &:nth-child(2) {
        margin-left: 32px;
      }
    }
  }
`;
const FormCheckBoxRow = styled.div`
  display: flex;
  align-items: center;
  margin-top: 32px;
`;
const FormCheckBoxWrap = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;
const FormCheckbox = styled(Field)`
  width: 24px;
  height: 24px;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 2;
`;

const FormCheckBoxIcon = styled.div`
  width: 24px;
  height: 24px;
`;

const FormCheckBoxLabel = styled.label`
  font-family: ${p => p.theme.fontFamily.Ubuntu};
  font-style: normal;
  font-weight: 400;
  color: #323537;
  cursor: pointer;
  font-size: 14px;
  line-height: 24px;
  margin-left: 16px;

  &.checkbox {
    display: block;
    margin-bottom: 8px;
  }
  ${mediaSizes.min1140} {
    font-size: 18px;
    line-height: 32px;
    margin-left: 10px;
  }
`;

const FormRadioRow = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 32px;
  ${mediaSizes.min1140} {
    margin-top: 40px;
  }
`;

const FormRadioItem = styled.div`
  & + & {
    margin-left: 32px;
  }
`;

const FormRadioItemTitle = styled.div`
  font-family: ${p => p.theme.fontFamily.Ubuntu};
  font-style: normal;
  font-weight: 400;
  color: #323537;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 16px;

  ${mediaSizes.min1140} {
    font-size: 18px;
    line-height: 32px;
    margin-bottom: 24px;
  }
`;

const FormRadioItemButtons = styled.div`
  display: flex;
  align-items: center;
`;

const FormRadioItemButton = styled.div`
  display: flex;
  align-items: center;

  & + & {
    margin-left: 20px;
  }
  ${mediaSizes.min1140} {
    & + & {
      margin-left: 66px;
    }
  }
`;
const FormRadioItemButtonLabel = styled.label`
  font-family: ${p => p.theme.fontFamily.Ubuntu};
  font-style: normal;
  font-weight: 400;
  color: #323537;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 14px;
  line-height: 24px;

  ${mediaSizes.min1140} {
    font-size: 18px;
    line-height: 32px;
  }
`;

const FormRadio = styled(Field)`
  appearance: none;
  -webkit-appearance: none;
  border-radius: 50%;
  outline: none;
  width: 20px;
  height: 20px;
  border: 2px solid #323537;
  margin-right: 16px;
  position: relative;
  cursor: pointer;
  z-index: 2;

  &:checked {
    border: 2px solid ${p => p.theme.colors.pink};
  }

  &:after {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    background: ${p => p.theme.colors.pink};
    opacity: 0;
    transition: ${p => p.theme.transitions.regular};
  }

  &:checked {
    &:after {
      opacity: 1;
    }
  }

  ${mediaSizes.min1140} {
    width: 24px;
    height: 24px;
    border: 2px solid #323537;
    &:checked {
      border: 2px solid ${p => p.theme.colors.pink};
    }
    &:after {
      content: '';
      position: absolute;
      width: 14px;
      height: 14px;
    }
  }
`;
const FormSubmitButton = styled(Button)`
  margin: 40px;
  max-width: 320px;

  ${mediaSizes.min768} {
    margin-bottom: 68px;
    max-width: 375px;
  }
  ${mediaSizes.min1140} {
    margin-top: 80px;
    max-width: 480px;
  }
`;
const Error = styled.div`
  font-family: ${p => p.theme.fontFamily.Ubuntu};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #e42208;
  margin-top: 4px;
  ${mediaSizes.min1140} {
    font-size: 14px;
    line-height: 24px;
  }
`;

const BelowLine = styled.div`
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: ${p => p.theme.colors.primary};
  background: ${p => p.theme.colors.lightBlue};
  padding: 16px;
  text-align: center;
  margin-bottom: 90px;

  ${mediaSizes.min768} {
    font-size: 18px;
    line-height: 32px;
  }
  ${mediaSizes.min1140} {
    margin-bottom: 56px;
  }
`;
const Projected = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${mediaSizes.min768} {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
  }
`;
const ProjectedText = styled.div`
  font-family: ${p => p.theme.fontFamily.Libre};
  font-weight: 400;
  font-size: 24px;
  line-height: 40px;
  color: ${p => p.theme.colors.primary};
  margin: 16px 32px;
  display: none;
  ${mediaSizes.min1140} {
    display: block;
  }
`;
const ProjectedItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-bottom: 56px;
  ${ProjectedText} {
    display: block;
    ${mediaSizes.min1140} {
      display: none;
    }
    position: absolute;
    top: -56px;
    left: 50%;
    transform: translateX(-50%);
    margin: 0;
  }
  ${mediaSizes.min768} {
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 64px;
  }
  ${mediaSizes.min1140} {
    margin-bottom: 0;
  }
`;
const ProjectedItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  ${ProjectedText} {
    display: block;
    ${mediaSizes.min1140} {
      display: none;
    }
    position: absolute;
    top: -56px;
    left: 50%;
    transform: translateX(-50%);
    margin: 0;
  }
  & + & {
    margin-bottom: 8px;
  }
  ${mediaSizes.min768} {
    & + & {
      margin-left: 16px;
    }
    &.one-time {
      margin-left: 40px;
    }
  }
  ${mediaSizes.min1140} {
    & + & {
      margin-left: 32px;
      margin-bottom: 0;
    }
    &.one-time {
      margin-left: 0;
    }
  }
`;
const ProjectedCard = styled.div`
  min-width: 185px;
  padding: 10px 16px 8px 16px;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15), 0px 1px 6px rgba(0, 0, 0, 0.11),
    0px 0.500862px 1.50259px rgba(0, 0, 0, 0.11),
    0px 0.181152px 0.543456px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  margin-bottom: 8px;

  ${mediaSizes.min768} {
    min-width: 130px;
    margin-bottom: 16px;
  }
  ${mediaSizes.min1140} {
    min-width: 168px;
  }
`;
const ProjectedCardTitle = styled.div`
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
  color: #8a8d94;
`;
const ProjectedCardTitleSpan = styled.span`
  font-weight: 700;
`;
const ProjectedCardTitleCount = styled.div`
  display: flex;
  align-items: center;
  padding: 0 5px;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;

  svg {
    margin-right: 4px;
  }
`;
const ProjectedItemYear = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #323537;
  ${mediaSizes.min1140} {
    font-size: 18px;
    line-height: 32px;
  }
`;
const SignUpButton = styled(ButtonLink)`
  margin-top: 40px;
  max-width: 325px;
  ${mediaSizes.min768} {
    margin-top: 0;
    max-width: 375px;
  }
  ${mediaSizes.min1140} {
    margin-top: 32px;
    max-width: 480px;
  }
`;

const FormDropdownItemCity = styled.div`
  font-size: 10px;
  color: ${p => p.theme.colors.gray};
  line-height: 100%;
  font-family: ${p => p.theme.fontFamily.Ubuntu};

  margin-left: 8px;
`;

const FormDropdownItemPin = styled.div`
  font-size: 14px;
  color: ${p => p.theme.colors.black}
  line-height: 100%;
  font-family: ${p => p.theme.fontFamily.Ubuntu};
`;

const FormDropdownItem = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 15px;
  cursor: pointer;
  transition: ${p => p.theme.transitions.regular};
  border-bottom: 1px solid #d3d3d3;

  &:hover {
    background: #d3d3d3;
  }
`;

const FormDropdown = styled.div`
  position: absolute;
  top: calc(100% + 3px);
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #ffffff;

  z-index: 15;

  transition: ${p => p.theme.transitions.regular};
  visibility: hidden;
  opacity: 0;

  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15), 0 1px 6px rgba(0, 0, 0, 0.11),
    0 0.500862px 1.50259px rgba(0, 0, 0, 0.11),
    0 0.181152px 0.543456px rgba(0, 0, 0, 0.07);

  &.active {
    visibility: visible;
    opacity: 1;
  }
`;

const FormFieldSolo = styled.input`
  width: 100%;
  background: #ffffff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15), 0 1px 6px rgba(0, 0, 0, 0.11),
    0 0.500862px 1.50259px rgba(0, 0, 0, 0.11),
    0 0.181152px 0.543456px rgba(0, 0, 0, 0.07);

  border-radius: 4px;
  outline: none;
  border: 2px solid transparent;
  height: 48px;
  padding: 20px 16px 4px 16px;
  font-family: ${p => p.theme.fontFamily.Ubuntu};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  transition: ${p => p.theme.transitions.regular};

  &.error {
    border: 2px solid #ff554a;
    background: #fff2f0;
  }
  &:disabled {
    background: #e3e5e8;
  }
  ${mediaSizes.min1140} {
    height: 64px;
    padding: 24px 16px 8px 16px;
    font-size: 18px;
    line-height: 32px;
    border-radius: 8px;
  }
`;
export {
  Wrapper,
  Title,
  Subtitle,
  FormField,
  FormItem,
  FormWrap,
  FormWrapTitle,
  FormSides,
  FormSidesLeft,
  FormSidesRight,
  FormFieldWrap,
  FormFieldSolo,
  FormFieldRow,
  FormFieldRowCouple,
  FormFieldLabel,
  FormFieldSubtitle,
  FormCheckBoxRow,
  FormCheckBoxWrap,
  FormCheckbox,
  FormCheckBoxLabel,
  FormCheckBoxIcon,
  FormRadioRow,
  FormRadioItem,
  FormRadioItemTitle,
  FormRadioItemButtons,
  FormRadioItemButton,
  FormRadioItemButtonLabel,
  FormRadio,
  FormSubmitButton,
  Error,
  BelowLine,
  Projected,
  ProjectedText,
  ProjectedItems,
  ProjectedItem,
  ProjectedCard,
  ProjectedCardTitle,
  ProjectedCardTitleSpan,
  ProjectedCardTitleCount,
  ProjectedItemYear,
  SignUpButton,
  FormDropdownItemCity,
  FormDropdownItemPin,
  FormDropdownItem,
  FormDropdown,
};
