import React from 'react';
import { Svg } from './styles';

const InputUncheckIcon = ({ props }: any) => (
  <Svg viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.2168 3.75C4.52644 3.75 3.9668 4.30964 3.9668 5V19C3.9668 19.6904 4.52644 20.25 5.2168 20.25H19.2168C19.9072 20.25 20.4668 19.6904 20.4668 19V5C20.4668 4.30964 19.9072 3.75 19.2168 3.75H5.2168ZM2.4668 5C2.4668 3.48122 3.69801 2.25 5.2168 2.25H19.2168C20.7356 2.25 21.9668 3.48122 21.9668 5V19C21.9668 20.5188 20.7356 21.75 19.2168 21.75H5.2168C3.69801 21.75 2.4668 20.5188 2.4668 19V5Z"
      fill="currentColor"
    />
  </Svg>
);

export default InputUncheckIcon;
