import * as Yup from 'yup';

export const initialData = {
  first_name: '',
  last_name: '',
  number: '',
  expiry: '',
  cvv: '',
  zip_code: '',
  agree: false,
};
export const PaymentValidation = Yup.object().shape({
  first_name: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  last_name: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  expiry: Yup.string()
    .min(5, 'Too Short!')
    .max(5, 'Too Long!')
    .required('Required'),
  number: Yup.string()
    .min(14, 'Too Short!')
    .max(16, 'Too Long!')
    .required('Required'),
  cvv: Yup.string()
    .matches(/^[0-9]+$/, "Only digits")
    .min(3, 'Too Short!')
    .max(4, 'Too Long!')
    .required('Required'),
  zip_code: Yup.string()
    .min(5, 'Too Short!')
    .max(5, 'Too Long!')
    .required('Required'),
});
