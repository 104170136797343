// core
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

// utils
import { $apiClient } from '../../utils/request';

// types
import { IPaymentData } from './types';
import { EButtonTypes } from '../../components/Atoms/Button/types';

// Loader
import LoadingIndicator from '../../components/Features/Loaders/LoadingIndicator';
import LoadingWrapper from '../../components/Features/Loaders/LoadingWrapper';
import {
  ColorType,
  TypesType,
} from '../../components/Features/Loaders/LoadingIndicator/types';

// styles
import {
  Wrapper,
  Title,
  Subtitle,
  Addresses,
  AddressesItem,
  AddressesItemSpan,
  Button,
} from './styles';

// icons

const IndustrialRequest = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<IPaymentData | null>(null);

  const { id } = useParams<{ id: string }>();

  const getData = () => {
    setLoading(true);
    $apiClient
      .get(`appeal/${id}`)
      .then(res => setData(res.data.data))
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper>
      {loading && (
        <LoadingWrapper>
          <LoadingIndicator
            type={TypesType.Local}
            color={ColorType.Dark}
            size={7}
          />
        </LoadingWrapper>
      )}
      <Title>Thank you for submitting your analysis request</Title>
      <Subtitle>
        We will start analyzing your property right away. Once we are done, we
        will email our analysis to you using the contact information we have on
        file
      </Subtitle>
      <Addresses>
        <AddressesItem>
          <AddressesItemSpan>Property address:</AddressesItemSpan>
          {data?.address}
        </AddressesItem>
        <AddressesItem>
          <AddressesItemSpan>Pin:</AddressesItemSpan>
          {data?.pin}
        </AddressesItem>
      </Addresses>
      <Button theme={EButtonTypes.Outline} to={'/'}>
        Check another property
      </Button>
    </Wrapper>
  );
};

export default IndustrialRequest;
