// core
import React from 'react';

// data
import { collectData, whyData } from './data';

// styles
import {
  Content,
  Desc,
  LastUpdated,
  ListItem,
  ListTitle,
  OrderList,
  Separator,
  SubTitle,
  Title,
  Wrapper,
} from './styles';

const PrivacyComp = () => {
  return (
    <Wrapper>
      <Content>
        <Title>Privacy Policy</Title>
        <Separator />
        <LastUpdated>Last updated April 2022</LastUpdated>
        <Desc>
          The Privacy Notice of Illinois Property Tax Appeal INC, (“the Firm”)
          describes how the Firm treats personal information when you use
          services provided by the Firm , including information provided when
          you submit written or electronic material directly to the Firm,
          regardless of the means by which the information was submitted. This
          policy applies to our current as well as our former customers.
        </Desc>
        <SubTitle>1. Personal Information</SubTitle>
        <Desc>
          Personal information means information that we collect in the normal
          course of business including requests you made for information,
          information submitted regarding property tax appeals, information that
          we obtain from public records, and any other information you submit to
          us that might be regarded as personal information about you or your
          property. Personal information includes any information that includes
          your personal or business identity, your personal or business contact
          information, non-public information regarding your financial affairs
          including personal or business tax returns, and any other information
          delivered to us or obtained by us in the normal course of business,
          which is not publicly available.
        </Desc>

        <SubTitle>
          2. Your Information is Protected and its Use is Restricted
        </SubTitle>
        <Desc>
          We protect your personal information by restricting access to such
          information to the employees of the Firm; to the appraisers,
          attorneys, and other professionals who provide services to and for the
          Firm on your behalf in the course of the services that we provide to
          you; to the various governmental agencies to whom we repressed you; to
          those who must know such information in connection to servicing your
          account; and to others who need to know such information in the
          process of providing the services that you have requested we provide.
          We maintain numerous safeguards to make sure your personal information
          is safe.
        </Desc>

        <SubTitle>
          3. Sharing Your Personal Information with Third Parties
        </SubTitle>
        <Desc>
          With the exception of those parties noted in 2) above, we never share
          your personal information with third parties unless required to do so
          by judicial governmental regulatory authority or to those to whom you
          specifically authorized and directed us to share such information.
        </Desc>

        <SubTitle>4. Usage Information</SubTitle>
        <Desc>
          When you use the Firm website, WWW.ILLTAXAPPEALS.COM, we may record
          information about your usage of the site such as information you click
          on in the Firm website (including UI elements, settings) or
          information that you upload. In order to ensure the quality of our
          service to you, we may place a tag ( also called a “web beacon”) in
          HTML-based customer support emails to other communications with you in
          order to confirm delivery. Any usage information that we collect is
          only used to assure our services and out website are maintained at the
          highest level and such information is not disclosed to any third
          party.
        </Desc>

        <SubTitle>5. Uses of Personal Information By the Firm</SubTitle>
        <Desc>
          If you submit personal contact information to the Firm, whether by
          letter, phone message or through the website or other means, we
          reserve the right to use such information to inform you concerning the
          activities, programs, availability of information, and opportunities
          to support the Firm. We may use such personal information to operate,
          maintain, and improve the features and functionality on the Firm
          website and to process any flagging activity or other communication
          you send to us. We will not disclose your email address or other
          personal information to any other outside party without your consent.
          We may use your email address without further consent for
          non-marketing or administrative purposes such as communicating with
          you in the process of providing our services to you. We may enter
          information in our client database or use out cookies, web beacons,
          and log file information to: (a) store information so that you will
          not have to re-enter it during your visit or in your submission of
          documentation to the Firm or the next time you visit the Firm website;
          (b) provide custom, personalized content and information; (c) monitor
          the effectiveness of our communication efforts; (d) monitor aggregate
          metrics such as total number of visitors and pages vied; and (e) track
          your entries, submissions, and status in various website components.
        </Desc>

        <SubTitle>6. More Information</SubTitle>
        <Desc>
          For more information about our privacy practices, you may write us at:
          <br />
          <br />
          ILLINOIS Property Tax Appeals
          <br />
          15000 S Cicero Ave. Ste. 101
          <br />
          Oak Forest, IL 60452
        </Desc>
      </Content>
    </Wrapper>
  );
};

export default PrivacyComp;
