// core
import React from 'react';

// styles

import {
  Wrapper,
  LogoWrap,
  Logo,
  Navigation,
  NavigationLink,
  HomePageIcon,
} from './styles';

const Header = () => {
  return (
    <Wrapper>
      <LogoWrap to={'/'}>
        <Logo src={'/img/logo.svg'} alt="" />
      </LogoWrap>
      <Navigation>
        <NavigationLink
          to={'/'}
          className={navData => (navData.isActive ? 'active' : '')}
        >
          <HomePageIcon />
        </NavigationLink>
        <NavigationLink
          to={'/about'}
          className={navData => (navData.isActive ? 'active' : '')}
        >
          About Us
        </NavigationLink>
        <NavigationLink
          to={'/qa'}
          className={navData => (navData.isActive ? 'active' : '')}
        >
          Q & A
        </NavigationLink>
        <NavigationLink
          to={'/tax-explained'}
          className={navData => (navData.isActive ? 'active' : '')}
        >
          Your tax bill explained
        </NavigationLink>
        <NavigationLink
          to={'/services'}
          className={navData => (navData.isActive ? 'active' : '')}
        >
          Services Provided
        </NavigationLink>
        <NavigationLink
          to={'/taxpayers'}
          className={navData => (navData.isActive ? 'active' : '')}
        >
          Satisfied taxpayers
        </NavigationLink>
      </Navigation>
    </Wrapper>
  );
};

export default Header;
