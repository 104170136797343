export const listData = [
  {
    marker: 'A',
    text: 'Analysis of any exemptions the property is entitled too.',
  },
  {
    marker: 'B',
    text: "A check for factual errors of your actual property characteristics such as: square footage, number of bedrooms, etc) verses the assessor's records.",
  },
  {
    marker: 'C',
    text: 'Use of our proprietary "MAX"imum search engine computer program which identifies all similar properties assessed lower than your home. (7 out of 10 homes on a block, overpay more than similar homes.)',
  },
  {
    marker: 'D',
    text: 'Custom Commercial/Industrial property tax assessment and potential savings report.',
  },
  {
    marker: 'E',
    text: 'Winning appeal strategies which allow you to PAY LESS and lower your monthly mortgage (escrow) amounts.',
  },
  {
    marker: 'F',
    text: 'Full savings forensic look at your property for you to realize any and all your savings.',
  },
  {
    marker: 'G',
    text: 'Reductions of the property taxes on a multi-year basis for a one-time flat fee.',
  },
];
