import { ReactNode } from 'react';

export enum EButtonTypes {
  Fill = 'fill',
  Outline = 'outline',
}
export interface IProps {
  children: ReactNode | null;
  theme: EButtonTypes;
  type: 'button' | 'submit';
  disabled?: boolean;
}
