import * as Yup from 'yup';

export const initialData = {
  property_county: '',
  property_address: '',
  property_pin: '',
  property_city: '',
  property_state: '',
  property_zip_code: '',
  property_township: '',
  property_style: '',
  property_area: '',
  property_purchased_recently: false,
  property_sale_price: '',
  property_is_primary: '',
  owner_name: '',
  owner_phone: '',
  owner_email: '',
  owner_is_old: '',
};
export const NonCookCountyValidation = Yup.object().shape({
  property_county: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  property_address: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  property_pin: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  property_city: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  property_state: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  property_zip_code: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  property_township: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  property_style: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!'),
  property_area: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!'),
  owner_name: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  owner_phone: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  owner_email: Yup.string().email('Invalid email').required('Required'),
  owner_is_old: Yup.string().required('Required'),
  property_is_primary: Yup.string().required('Required'),
});
