// core
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

// utils
import { $apiClient } from '../../utils/request';

// types
import { IPaymentData } from './types';

// styles
import {
  Wrapper,
  Title,
  Info,
  InfoReceipt,
  InfoReceiptNumber,
  InfoReceiptNumberLink,
  InfoCard,
  InfoCardItem,
  InfoCardItemTitle,
  InfoCardItemText,
  InfoSummary,
  InfoBox,
  InfoBoxItem,
  InfoBoxItemSpan,
  InfoBoxAmount,
  InfoBoxAmountSpan,
  Button,
} from './styles';

// data

// Loader
import LoadingIndicator from '../../components/Features/Loaders/LoadingIndicator';
import LoadingWrapper from '../../components/Features/Loaders/LoadingWrapper';
import {
  ColorType,
  TypesType,
} from '../../components/Features/Loaders/LoadingIndicator/types';

// icons
import DownloadIcon from '../../components/Icons/Download';
import { EButtonTypes } from '../../components/Atoms/ButtonLink/types';

const PaymentSuccess = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<IPaymentData | null>(null);

  const { id } = useParams<{ id: string }>();

  const getData = () => {
    setLoading(true);
    $apiClient
      .get(`appeal/${id}`)
      .then(res => setData(res.data.data))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper>
      {loading && (
        <LoadingWrapper>
          <LoadingIndicator
            type={TypesType.Local}
            color={ColorType.Dark}
            size={7}
          />
        </LoadingWrapper>
      )}
      <Title>Payment Confirmation</Title>
      <Info>
        <InfoReceipt>
          <InfoReceiptNumber>Receipt #{id}</InfoReceiptNumber>
          <InfoReceiptNumberLink
            href={data?.payment.receipt_url}
            target={'_blank'}
          >
            Download Receipt <DownloadIcon />
          </InfoReceiptNumberLink>
        </InfoReceipt>
        <InfoCard>
          <InfoCardItem>
            <InfoCardItemTitle>AMOUNT PAID</InfoCardItemTitle>
            <InfoCardItemText>${data?.payment.amount_paid}</InfoCardItemText>
          </InfoCardItem>
          <InfoCardItem>
            <InfoCardItemTitle>DATE PAID</InfoCardItemTitle>
            <InfoCardItemText>{data?.payment.date}</InfoCardItemText>
          </InfoCardItem>
          <InfoCardItem>
            <InfoCardItemTitle>PAYMENT METHOD</InfoCardItemTitle>
            <InfoCardItemText>
              {data?.payment.payment_method} - {data?.payment.last_four}
            </InfoCardItemText>
          </InfoCardItem>
        </InfoCard>
        <InfoSummary>SUMMARY</InfoSummary>
        <InfoBox>
          <InfoBoxItem>
            <InfoBoxItemSpan>{data?.payment.summary_company}</InfoBoxItemSpan>
            <InfoBoxItemSpan>${data?.payment.amount_paid}</InfoBoxItemSpan>
          </InfoBoxItem>
          <InfoBoxItem>
            <InfoBoxItemSpan>{data?.address}</InfoBoxItemSpan>
          </InfoBoxItem>
          <InfoBoxItem>
            <InfoBoxItemSpan>{data?.pin}</InfoBoxItemSpan>
          </InfoBoxItem>
          <InfoBoxAmount>
            <InfoBoxAmountSpan>Amount Charged</InfoBoxAmountSpan>
            <InfoBoxAmountSpan>${data?.fee}</InfoBoxAmountSpan>
          </InfoBoxAmount>
        </InfoBox>
        <Button theme={EButtonTypes.Outline} to={'/#analysis'}>
          Check Another Property
        </Button>
      </Info>
    </Wrapper>
  );
};

export default PaymentSuccess;
