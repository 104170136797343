import React from 'react';
import { Svg } from './styles';

const ArrowPrevIcon = ({ props }: any) => (
  <Svg viewBox="0 0 40 40" fill="none" {...props}>
    <path
      d="M24.9991 33.2003L14.1324 22.3336C12.8491 21.0503 12.8491 18.9503 14.1324 17.667L24.9991 6.80029"
      stroke="#323537"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default ArrowPrevIcon;
