// core
import React, { useEffect, useState } from 'react';
import { ErrorMessage, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';

// utils
import { $apiClient } from '../../utils/request';

// types
import { FormValues, IProjected } from './types';
import { EButtonTypes } from '../../components/Atoms/Button/types';

// styles
import {
  Wrapper,
  Title,
  Subtitle,
  FormField,
  FormFieldSolo,
  FormItem,
  FormWrap,
  FormWrapTitle,
  FormSides,
  FormSidesLeft,
  FormSidesRight,
  FormFieldWrap,
  FormFieldRow,
  FormFieldRowCouple,
  FormFieldLabel,
  FormFieldSubtitle,
  FormCheckBoxRow,
  FormCheckBoxWrap,
  FormCheckbox,
  FormCheckBoxLabel,
  FormCheckBoxIcon,
  FormRadioRow,
  FormRadioItem,
  FormRadioItemTitle,
  FormRadioItemButtons,
  FormRadioItemButton,
  FormRadioItemButtonLabel,
  FormRadio,
  FormSubmitButton,
  Error,
  BelowLine,
} from './styles';

// Loader
import LoadingIndicator from '../../components/Features/Loaders/LoadingIndicator';
import LoadingWrapper from '../../components/Features/Loaders/LoadingWrapper';
import {
  ColorType,
  TypesType,
} from '../../components/Features/Loaders/LoadingIndicator/types';

// icons
import InputUncheckIcon from '../../components/Icons/InputUncheck';
import InputCheckIcon from '../../components/Icons/InputCheck';
import PhoneIcon from '../../components/Icons/Phone';
import EmailIcon from '../../components/Icons/Email';
import SearchIcon from '../../components/Icons/Search';

// data
import { IndustrialValidation, initialData } from './data';
import { toast } from 'react-toastify';

const IndustrialForm = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState({
    lng: null,
    lat: null,
    addr: null,
    place: null,
  });
  const [pinData, setPinData] = useState<FormValues | null>(null);

  const initialValues: FormValues = pinData || initialData;

  const Validation = IndustrialValidation;

  const getPinData = () => {
    if (searchQuery && searchQuery.lng !== null) {
      setLoading(true);
      setPinData(null);
      $apiClient
        .post('property/find', {
          searchString: searchQuery,
          type: 'commercial',
        })
        .then(res => setPinData(res.data.data))
        .catch(res => toast.error(JSON.parse(res.request.response).message))
        .finally(() => setLoading(false));
    } else {
      setPinData(initialData);
    }
  };

  const handleSubmit = (values: FormValues) => {
    const data = { ...values, type: 'commercial' };
    setLoading(true);

    $apiClient
      .post('appeal', data)
      .then(res => {
        navigate(`/industrial-request/${res.data.data?.id}`);
      })
      .catch(res => {
        setTimeout(() => {
          const element = document.getElementById('form');
          window.scrollTo({
            left: 0,
            top: element!.offsetTop + 90,
            behavior: 'smooth',
          });
        }, 300);

        toast.error(JSON.parse(res.request.response).message);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getPinData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  const handleErrorScroll = () => {
    const element = document.getElementById('form');
    window.scrollTo({
      left: 0,
      top: element!.offsetTop + 90,
      behavior: 'smooth',
    });
  };

  return (
    <Wrapper>
      {loading && (
        <LoadingWrapper className={'fixed'}>
          <LoadingIndicator
            type={TypesType.Local}
            color={ColorType.Dark}
            size={7}
          />
        </LoadingWrapper>
      )}
      <Title>
        Illinois Industrial/Commercial <br /> Properties Analysis
      </Title>
      <Subtitle>
        Please tell us about your property so that we can analyze your
        property's taxes. Click on 'Check My Taxes' button below when you are
        done. We will promptly analyze your property's taxes and contact you
        back with our results and recommendations.
      </Subtitle>

      <FormFieldWrap id="form" className={'solo'}>
        <FormFieldRow>
          <FormFieldSolo
            apiKey={process.env.REACT_APP_GOOGLE_MAPS}
            onPlaceSelected={place => {
              setSearchQuery({
                lng: place.geometry.location.lng(),
                lat: place.geometry.location.lat(),
                addr: place.formatted_address,
                place: place,
              });
            }}
            options={{
              types: ['address'],
              fields: ['all'],
              componentRestrictions: { country: 'usa' },
            }}
          />
          <FormFieldLabel>
            <SearchIcon />
            Find my property by address or PIN
          </FormFieldLabel>
        </FormFieldRow>
      </FormFieldWrap>

      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={Validation}
      >
        {({ errors, touched, values }) => (
          <FormItem>
            <FormWrap>
              <FormWrapTitle>Property Information</FormWrapTitle>
              <FormSides>
                <FormSidesLeft>
                  <FormFieldWrap>
                    <FormFieldRow>
                      <FormField
                        type="text"
                        autoComplete="chrome-off"
                        name="property_address"
                        className={
                          errors.property_address &&
                          touched.property_address &&
                          'error'
                        }
                      />
                      <FormFieldLabel>Property Address</FormFieldLabel>
                    </FormFieldRow>
                    <ErrorMessage name="property_address">
                      {msg => <Error>{msg}</Error>}
                    </ErrorMessage>
                  </FormFieldWrap>
                  <FormFieldRowCouple>
                    <FormFieldWrap>
                      <FormFieldRow>
                        <FormField
                          type="text"
                          autoComplete="chrome-off"
                          name="property_city"
                          className={
                            errors.property_city &&
                            touched.property_city &&
                            'error'
                          }
                        />
                        <FormFieldLabel>City</FormFieldLabel>
                      </FormFieldRow>
                      <ErrorMessage name="property_city">
                        {msg => <Error>{msg}</Error>}
                      </ErrorMessage>
                    </FormFieldWrap>
                    <FormFieldWrap>
                      <FormFieldRow>
                        <FormField
                          type="text"
                          autoComplete="chrome-off"
                          name="property_state"
                          className={
                            errors.property_state &&
                            touched.property_state &&
                            'error'
                          }
                        />
                        <FormFieldLabel>State</FormFieldLabel>
                      </FormFieldRow>
                      <ErrorMessage name="property_state">
                        {msg => <Error>{msg}</Error>}
                      </ErrorMessage>
                    </FormFieldWrap>
                  </FormFieldRowCouple>
                  <FormFieldRowCouple>
                    <FormFieldWrap className={'short'}>
                      <FormFieldRow>
                        <FormField
                          type="text"
                          autoComplete="chrome-off"
                          name="property_zip_code"
                          className={
                            errors.property_zip_code &&
                            touched.property_zip_code &&
                            'error'
                          }
                        />
                        <FormFieldLabel>Zip Code</FormFieldLabel>
                      </FormFieldRow>
                      <ErrorMessage name="property_zip_code">
                        {msg => <Error>{msg}</Error>}
                      </ErrorMessage>
                    </FormFieldWrap>
                    <FormFieldWrap>
                      <FormFieldRow>
                        <FormField
                          type="text"
                          autoComplete="chrome-off"
                          name="property_township"
                          className={
                            errors.property_township &&
                            touched.property_township &&
                            'error'
                          }
                        />
                        <FormFieldLabel>Township</FormFieldLabel>
                      </FormFieldRow>
                      <ErrorMessage name="property_township">
                        {msg => <Error>{msg}</Error>}
                      </ErrorMessage>
                    </FormFieldWrap>
                  </FormFieldRowCouple>
                  <FormCheckBoxRow>
                    <FormCheckBoxWrap>
                      <FormCheckbox
                        id={'property_purchased_recently'}
                        type="checkbox"
                        name="property_purchased_recently"
                      />
                      <FormCheckBoxIcon>
                        {values.property_purchased_recently === true ? (
                          <InputCheckIcon />
                        ) : (
                          <InputUncheckIcon />
                        )}
                      </FormCheckBoxIcon>
                      <FormCheckBoxLabel
                        htmlFor={'property_purchased_recently'}
                      >
                        Did you purchase your home in the last 3 years?
                      </FormCheckBoxLabel>
                    </FormCheckBoxWrap>
                  </FormCheckBoxRow>
                  <FormFieldWrap className={'long'}>
                    <FormCheckBoxLabel
                      htmlFor={'property_is_primary'}
                      className={'checkbox'}
                    >
                      How much was the sale price?
                    </FormCheckBoxLabel>
                    <FormFieldRow>
                      <FormField
                        type="number"
                        name="property_sale_price"
                        disabled={values.property_purchased_recently === false}
                        className={
                          errors.property_sale_price &&
                          touched.property_sale_price &&
                          'error'
                        }
                      />
                      <FormFieldLabel>Amount in $</FormFieldLabel>
                    </FormFieldRow>
                    <ErrorMessage name="property_sale_price">
                      {msg => <Error>{msg}</Error>}
                    </ErrorMessage>
                  </FormFieldWrap>
                </FormSidesLeft>
                <FormSidesRight>
                  <FormFieldWrap>
                    <FormFieldRow>
                      <FormField
                        type="text"
                        autoComplete="chrome-off"
                        name="property_pin"
                        className={
                          errors.property_pin && touched.property_pin && 'error'
                        }
                      />
                      <FormFieldLabel>PIN #1</FormFieldLabel>
                    </FormFieldRow>
                    <ErrorMessage name="property_pin">
                      {msg => <Error>{msg}</Error>}
                    </ErrorMessage>
                  </FormFieldWrap>
                  <FormRadioRow>
                    <FormRadioItem>
                      <FormRadioItemTitle>
                        Multiple PINS for Property
                      </FormRadioItemTitle>
                      <FormRadioItemButtons>
                        <FormRadioItemButton>
                          <FormRadio
                            type="radio"
                            name="property_multiple_pins"
                            id="isMultiplyYes"
                            value="1"
                          />
                          <FormRadioItemButtonLabel htmlFor={'isMultiplyYes'}>
                            yes
                          </FormRadioItemButtonLabel>
                        </FormRadioItemButton>
                        <FormRadioItemButton>
                          <FormRadio
                            type="radio"
                            name="property_multiple_pins"
                            id="isMultiplyNo"
                            value="0"
                          />
                          <FormRadioItemButtonLabel htmlFor={'isMultiplyNo'}>
                            No
                          </FormRadioItemButtonLabel>
                        </FormRadioItemButton>
                      </FormRadioItemButtons>
                      <ErrorMessage name="property_multiple_pins">
                        {msg => <Error>{msg}</Error>}
                      </ErrorMessage>
                    </FormRadioItem>
                  </FormRadioRow>
                  <FormFieldWrap>
                    <FormCheckBoxLabel
                      htmlFor={'property_is_primary'}
                      className={'margin'}
                    >
                      If YES, please provide PINS
                    </FormCheckBoxLabel>
                    <FormFieldWrap>
                      <FormFieldRow>
                        <FormField
                          type="text"
                          autoComplete="chrome-off"
                          name="property_additional_pin1"
                          disabled={values.property_multiple_pins !== '1'}
                          className={
                            errors.property_additional_pin1 &&
                            touched.property_additional_pin1 &&
                            'error'
                          }
                        />
                        <FormFieldLabel>PIN #2</FormFieldLabel>
                      </FormFieldRow>
                      <ErrorMessage name="property_additional_pin1">
                        {msg => <Error>{msg}</Error>}
                      </ErrorMessage>
                    </FormFieldWrap>
                    <FormFieldWrap>
                      <FormFieldRow>
                        <FormField
                          type="text"
                          autoComplete="chrome-off"
                          name="property_additional_pin2"
                          disabled={values.property_multiple_pins !== '1'}
                          className={
                            errors.property_additional_pin2 &&
                            touched.property_additional_pin2 &&
                            'error'
                          }
                        />
                        <FormFieldLabel>PIN #3</FormFieldLabel>
                      </FormFieldRow>
                      <ErrorMessage name="property_additional_pin2">
                        {msg => <Error>{msg}</Error>}
                      </ErrorMessage>
                    </FormFieldWrap>
                    <FormFieldWrap>
                      <FormFieldRow>
                        <FormField
                          type="text"
                          autoComplete="chrome-off"
                          name="property_additional_pin3"
                          disabled={values.property_multiple_pins !== '1'}
                          className={
                            errors.property_additional_pin3 &&
                            touched.property_additional_pin3 &&
                            'error'
                          }
                        />
                        <FormFieldLabel>PIN #4</FormFieldLabel>
                      </FormFieldRow>
                      <ErrorMessage name="property_additional_pin3">
                        {msg => <Error>{msg}</Error>}
                      </ErrorMessage>
                    </FormFieldWrap>
                  </FormFieldWrap>
                </FormSidesRight>
              </FormSides>
            </FormWrap>
            <FormWrap>
              <FormWrapTitle>Owner Information</FormWrapTitle>
              <FormSides>
                <FormSidesLeft>
                  <FormFieldWrap>
                    <FormFieldRow>
                      <FormField
                        type="text"
                        autoComplete="chrome-off"
                        name="owner_name"
                        className={
                          errors.owner_name && touched.owner_name && 'error'
                        }
                      />
                      <FormFieldLabel>Owner Name</FormFieldLabel>
                    </FormFieldRow>
                    <ErrorMessage name="owner_name">
                      {msg => <Error>{msg}</Error>}
                    </ErrorMessage>
                  </FormFieldWrap>
                  <FormFieldWrap>
                    <FormFieldRow>
                      <FormField
                        type="text"
                        autoComplete="chrome-off"
                        name="owner_email"
                        className={
                          errors.owner_email && touched.owner_email && 'error'
                        }
                      />
                      <FormFieldLabel>
                        <EmailIcon />
                        Email Address
                      </FormFieldLabel>
                    </FormFieldRow>
                    <ErrorMessage name="owner_email">
                      {msg => <Error>{msg}</Error>}
                    </ErrorMessage>
                    <FormFieldSubtitle>
                      Where you want your savings analysis sent
                    </FormFieldSubtitle>
                  </FormFieldWrap>
                </FormSidesLeft>
                <FormSidesRight>
                  <FormFieldWrap>
                    <FormFieldRow>
                      <FormField
                        type="text"
                        autoComplete="chrome-off"
                        name="owner_corporate_name"
                        className={
                          errors.owner_corporate_name &&
                          touched.owner_corporate_name &&
                          'error'
                        }
                      />
                      <FormFieldLabel>Corporate Name</FormFieldLabel>
                    </FormFieldRow>
                    <ErrorMessage name="owner_corporate_name">
                      {msg => <Error>{msg}</Error>}
                    </ErrorMessage>
                  </FormFieldWrap>
                  <FormFieldWrap>
                    <FormFieldRow>
                      <FormField
                        type="text"
                        autoComplete="chrome-off"
                        name="owner_phone"
                        className={
                          errors.owner_phone && touched.owner_phone && 'error'
                        }
                      />
                      <FormFieldLabel>
                        <PhoneIcon />
                        Phone #
                      </FormFieldLabel>
                    </FormFieldRow>
                    <ErrorMessage name="owner_phone">
                      {msg => <Error>{msg}</Error>}
                    </ErrorMessage>
                  </FormFieldWrap>
                </FormSidesRight>
              </FormSides>
            </FormWrap>
            <FormWrap>
              <FormWrapTitle>Type of Property</FormWrapTitle>
              <FormFieldRowCouple className="top">
                <FormFieldWrap>
                  <FormFieldRow>
                    <FormField
                      type="text"
                      autoComplete="chrome-off"
                      name="company_type_of_property"
                      className={
                        errors.company_type_of_property &&
                        touched.company_type_of_property &&
                        'error'
                      }
                    />
                    <FormFieldLabel>Type of Property</FormFieldLabel>
                  </FormFieldRow>
                  <ErrorMessage name="company_type_of_property">
                    {msg => <Error>{msg}</Error>}
                  </ErrorMessage>
                </FormFieldWrap>
                <FormFieldWrap>
                  <FormFieldRow>
                    <FormField
                      type="text"
                      autoComplete="chrome-off"
                      name="company_area"
                      className={
                        errors.company_area && touched.company_area && 'error'
                      }
                    />
                    <FormFieldLabel>Approx. Sq. Ft. of Building</FormFieldLabel>
                  </FormFieldRow>
                  <ErrorMessage name="company_area">
                    {msg => <Error>{msg}</Error>}
                  </ErrorMessage>
                </FormFieldWrap>
                <FormFieldWrap>
                  <FormFieldRow>
                    <FormField
                      type="text"
                      autoComplete="chrome-off"
                      name="company_stories"
                      className={
                        errors.company_stories &&
                        touched.company_stories &&
                        'error'
                      }
                    />
                    <FormFieldLabel># of Stories / Building</FormFieldLabel>
                  </FormFieldRow>
                  <ErrorMessage name="company_stories">
                    {msg => <Error>{msg}</Error>}
                  </ErrorMessage>
                </FormFieldWrap>
              </FormFieldRowCouple>
              <FormFieldRowCouple className={'bottom'}>
                <FormFieldWrap>
                  <FormCheckBoxLabel
                    htmlFor={'company_have_appealed'}
                    className={'solo'}
                  >
                    Do you currently or have you appealed with another legal
                    firm in the past?
                  </FormCheckBoxLabel>
                  <FormFieldRow>
                    <FormField
                      type="text"
                      autoComplete="chrome-off"
                      name="company_have_appealed"
                      className={
                        errors.company_have_appealed &&
                        touched.company_have_appealed &&
                        'error'
                      }
                    />
                  </FormFieldRow>
                  <ErrorMessage name="company_have_appealed">
                    {msg => <Error>{msg}</Error>}
                  </ErrorMessage>
                </FormFieldWrap>
                <FormFieldWrap>
                  <FormCheckBoxLabel
                    htmlFor={'company_significant_vacancy'}
                    className={'solo'}
                  >
                    Significant vacancy last year or currently?
                  </FormCheckBoxLabel>
                  <FormFieldRow>
                    <FormField
                      type="text"
                      autoComplete="chrome-off"
                      name="company_significant_vacancy"
                      className={
                        errors.company_significant_vacancy &&
                        touched.company_significant_vacancy &&
                        'error'
                      }
                    />
                  </FormFieldRow>
                  <ErrorMessage name="company_significant_vacancy">
                    {msg => <Error>{msg}</Error>}
                  </ErrorMessage>
                </FormFieldWrap>
              </FormFieldRowCouple>
            </FormWrap>
            <BelowLine>
              Our firm has former assessors, tax appeal judges, appraisers and
              attorney's of counsel who will analyze and <br /> contact you via
              email or by phone, with the anticipated successful appeal analysis
              for your review.
            </BelowLine>
            <div
              onClick={() =>
                Object.keys(errors).length > 0 && handleErrorScroll()
              }
            >
              <FormSubmitButton theme={EButtonTypes.Outline} type={'submit'}>
                Check My Taxes
              </FormSubmitButton>
            </div>
          </FormItem>
        )}
      </Formik>
    </Wrapper>
  );
};

export default IndustrialForm;
