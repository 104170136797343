// core
import React from 'react';

// utils

// types

// styles
import {
  Button,
  Desc,
  Image,
  ImageWrapper,
  ImageContent,
  SubTitle,
  Title,
  Wrapper,
} from './styles';

const AboutUs = () => {
  return (
    <Wrapper>

      <ImageWrapper>
        <Image src="/img/shake.png" />
        <ImageContent>
          <Title>Our Mission</Title>
          <Desc>
            ...is to help taxpayers SUCCESSFULLY navigate the confusing tax appeal
            cycle in Illinois. Our staff consists of Realtors, former tax appeal
            judges, former assessors, attorneys of counsel, mortgage providers,
            appraisers and other real estate professionals.
            <br /> We see the unfair Illinois property tax environment placed on
            property taxpayers everyday.
          </Desc>
        </ImageContent>
      </ImageWrapper>
      <SubTitle>
        Do nothing and continue to overpay your fair share of taxes... <br />{' '}
        You need to appeal every year!!!
      </SubTitle>
      <Button to="/#analysis" theme={'fill'}>
        I want to sign up now!
      </Button>
    </Wrapper>
  );
};

export default AboutUs;
