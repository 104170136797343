import styled from 'styled-components/macro';
import Icon from '../Icon';

const Svg = styled(Icon)`
  // width: 24px;
  // height: 24px;
  width: 100%;
  height: 100%;
`;

export { Svg };
