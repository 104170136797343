import styled from 'styled-components/macro';
import { mediaSizes } from '../../styles/media';
import ButtonLink from '../../components/Atoms/ButtonLink';
import { NavLink } from 'react-router-dom';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${mediaSizes.max1024} {
    margin-top: -72px;
  }
`;
const PromoWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  height: calc(100vh - 132px);

  ${mediaSizes.max1024} {
    min-height: 608px;
    height: initial;
    justify-content: flex-start;

    padding-top: 72px;
  }
  ${mediaSizes.max480} {
    height: 656px;
    align-items: flex-start;
    padding: calc(96px + 72px) 32px 56px 24px;
  }
`;
const Background = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
`;
const BackgroundImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const PromoTitle = styled.div`
  font-family: ${p => p.theme.fontFamily.Libre};
  font-style: normal;
  font-weight: 700;
  font-size: 72px;
  line-height: 96px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 64px;

  ${mediaSizes.max1024} {
    font-size: 48px;
    line-height: 72px;
    margin: 128px 0 56px 0;
  }
  ${mediaSizes.max480} {
    text-align: start;
    font-size: 40px;
    line-height: 56px;
    margin: 0 0 64px 0;
  }
`;
const PromoSubtitle = styled.div`
  font-family: ${p => p.theme.fontFamily.Libre};
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 72px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 40px;

  ${mediaSizes.max1024} {
    font-size: 32px;
    line-height: 48px;
    margin-bottom: 24px;
  }
  ${mediaSizes.max480} {
    text-align: start;
    font-size: 24px;
    line-height: 40px;
  }
`;
const PromoButton = styled(ButtonLink)`
  max-width: 248px;
  &:hover {
    a {
      color: white;
      border-color: white;
    }
  }
`;
const SectionsWrapper = styled.div`
  padding: 80px 96px 120px 96px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1440px;

  ${mediaSizes.max1024} {
    padding: 64px 96px 80px 96px;
  }
  ${mediaSizes.max480} {
    padding: 64px 24px 80px 24px;
  }
`;
const SectionsTitle = styled.div`
  font-family: ${p => p.theme.fontFamily.Libre};
  font-weight: 400;
  font-size: 24px;
  line-height: 40px;
  text-align: center;
  color: ${p => p.theme.colors.black};
  margin-bottom: 24px;

  ${mediaSizes.min768} {
    font-size: 32px;
    line-height: 48px;
  }
`;
const SectionsSubtitle = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  color: ${p => p.theme.colors.gray};
  text-align: center;
  margin-bottom: 80px;
  /* max-width: 980px; */

  ${mediaSizes.max1024} {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 64px;
  }
`;
const CardsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${mediaSizes.max1024} {
    flex-direction: column;
  }
`;
const CardItem = styled.div`
  padding: 36px 30px;
  border-radius: 8px;
  width: 100%;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  & + & {
    margin-left: 48px;
  }

  &.blue {
    background: ${p => p.theme.colors.blue};
  }
  &.red {
    background: ${p => p.theme.colors.red};
  }
  &.green {
    background: ${p => p.theme.colors.green};
  }

  ${mediaSizes.max1024} {
    & + & {
      margin-left: 0;
      margin-top: 24px;
    }
  }
`;

const CardItemSubTitle = styled.div`
  width: 100%;
  font-family: ${p => p.theme.fontFamily.Libre};
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 16px;

  /* ${mediaSizes.max1024} { */
  /*   margin-bottom: 24px; */
  /* } */
  /* ${mediaSizes.max480} { */
  /*   margin-bottom: 32px; */
  /* } */
`;

const CardItemTitle = styled.div`
  width: 100%;
  font-family: ${p => p.theme.fontFamily.Libre};
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 40px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 64px;

  span {
    /* font-weight: 700; */
    font-size: 32px;
  }

  ${mediaSizes.max1024} {
    margin-bottom: 24px;
  }
  ${mediaSizes.max480} {
    margin-bottom: 32px;
  }
`;
const CardItemButton = styled(NavLink)`
  width: fit-content;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  color: ${p => p.theme.colors.lightGray};
  padding: 10px 27px;
  border: 1px solid ${p => p.theme.colors.lightGray};
  border-radius: 48px;
  transition: ${p => p.theme.transitions.regular};

  &:hover {
    background: ${p => p.theme.colors.lightGray};
    &.blue {
      color: ${p => p.theme.colors.blue};
    }
    &.red {
      color: ${p => p.theme.colors.red};
    }
    &.green {
      color: ${p => p.theme.colors.green};
    }
  }
`;
export {
  Wrapper,
  PromoWrapper,
  Background,
  BackgroundImage,
  PromoTitle,
  PromoSubtitle,
  PromoButton,
  SectionsWrapper,
  SectionsTitle,
  SectionsSubtitle,
  CardsWrapper,
  CardItem,
  CardItemTitle,
  CardItemButton,
  CardItemSubTitle,
};
