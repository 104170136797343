export const clients = [
  {
    name: 'RIMAH ODEH',
    quote:
      'Illinois commercial and small apartment building owners are fighting constant high property taxes which reduces their investment portfolio. ILLINOIS Property Tax Appeals Inc.  are highly recommended to all of my current and potential clients, they provide property tax relief to make the payments more affordable. ILLINOIS PROPERTY TAX APPEALS INC. PROFESSIONALLY HELPS MY PROPERTY INVESTORS NAVIGATE THE COMPLEX TAX APPEAL PROCESS AT REASONABLE COSTS.',
    place: 'REALTOR BETTER HOMES AND GARDEN',
  },
  {
    name: 'NICOLE LAMBROS',
    quote:
      'ILLINOIS PROPERTY TAX APPEALS INC. Provides complete and professional property tax relief when representing homeowners in their tax appeal matters. I, personally have enjoyed significant property tax relief on my home. THEIR LOW ONE TIME FEE AND WINNING RESULTS IS WHY I CONTINUE TO RECOMMEND THEIR SERVICES TO ALL MY OVERTAXED MORTGAGE CLIENTS. THEY KEEP THEIR MONTHLY MORTGAGE PAYMENTS DOWN BY LOWERING THEIR PROPERTY TAXES. ',
    place: 'PRODUCTION BRANCH MANAGER, <br /> PREFERRED RATE MORTGAGE',
  },
  {
    name: 'GUS D.',
    quote:
      'I own many single family rental properties and use exclusively Illinois property tax appeals since they are cheaper than the downtown tax lawyers I used in the past. Illinois Property Tax Appeals one time low fee is more cost effective and lets me keep more of my tax savings. They win since they have former assessors and tax appeal judges on staff who know the winning strike zone... Their website offers 24/7 savings analysis for free and allows me to sign up simply and seamlessly.',
    place: 'ORLAND PARK. IL',
  },
  {
    name: 'Len K',
    quote:
      'The assessor just re-assessed my Chicago bungalow and jacked my taxes up 3 times the amount. ILLINOIS PROPERTY TAX APPEALS INC. took the sting out of that proposed increase…',
    place: 'Gage Park, Chicago',
  },
  {
    name: 'Pauline C',
    quote:
      'ILLINOIS PROPERTY TAZ APPEALS INC. attorney was to reduce my high taxes with my township assessor.',
    place: 'Burr Ridge, IL',
  },
];
