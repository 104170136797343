import React from 'react';
import { Svg } from './styles';

const ArrowHeadIcon = ({ props }: any) => (
  <Svg viewBox="0 0 47 87" fill="none" {...props}>
    <path
      d="M46.3604 3.08029V83.2073C46.3604 85.88 43.1289 87.2185 41.239 85.3287L1.17552 45.2651C0.00394678 44.0936 0.00394362 42.1941 1.17552 41.0225L41.239 0.958967C43.1289 -0.930928 46.3604 0.407574 46.3604 3.08029Z"
      fill="#EE5252"
    />
  </Svg>
);

export default ArrowHeadIcon;
