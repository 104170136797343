// core
import React from 'react';
import { useWindowSize } from 'react-use';
import AppleIcon from '../../components/Icons/Apple';
import { questionData } from './data';
import QuestionComp from './QuestionComp';
import {
  Wrapper,
  AppleCont,
  AppleItem,
  AppleWrap,
  Button,
  Content,
  Desc,
  QuestionCont,
  QuestionWrap,
  SubTitle,
  Title,
} from './styles';

// styles

const QaComp = () => {
  const { width } = useWindowSize();

  const half = Math.ceil(questionData.length / 2);

  const first = questionData.slice(0, half);
  const second = questionData.slice(-half);

  return (
    <Wrapper>
      <Content>
        <Title>
          Do nothing {width <= 660 && <br />} and Your Property Taxes <br />{' '}
          Will Continue to Creep Up!
        </Title>
        <Desc>
          We’ll take care of helping you enjoy a winning bite at the tax appeal
          apple
        </Desc>
        <AppleCont>
          <AppleItem>
            <AppleWrap>
              <AppleIcon />
            </AppleWrap>
            <SubTitle>Assessor</SubTitle>
          </AppleItem>
          <AppleItem>
            <AppleWrap>
              <AppleIcon />
            </AppleWrap>
            <SubTitle>
              County Board of Review <br /> (BOR)
            </SubTitle>
          </AppleItem>
          <AppleItem>
            <AppleWrap>
              <AppleIcon />
            </AppleWrap>
            <SubTitle>
              IL Property Tax <br />
              Appeal Board (PTAB)
            </SubTitle>
          </AppleItem>
        </AppleCont>
        <Desc>
          Less than 600,000 of your neighbors appealed last year. There are
          over three million single family homes in the six county Chicago metro
          market
        </Desc>
      </Content>
      <Title>Top ten questions about appeals</Title>
      <QuestionWrap>
        <QuestionCont>
          {first.map((el, i) => (
            <QuestionComp
              key={i}
              answer={el.answerText}
              number={el.number}
              question={el.question}
            />
          ))}
        </QuestionCont>
        <QuestionCont>
          {second.map((el, i) => (
            <QuestionComp
              key={i}
              answer={el.answerText}
              number={el.number}
              question={el.question}
            />
          ))}
        </QuestionCont>
      </QuestionWrap>
      <Button to="/#analysis" theme={'fill'}>
        I want to sign up now!
      </Button>
    </Wrapper>
  );
};

export default QaComp;
