import styled from 'styled-components/macro';
import Button from '../../../components/Atoms/Button';
import { mediaSizes } from '../../../styles/media';

const QuestionWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 160px;
  width: 100%;
  margin-bottom: 80px;
`;

const QuestionCont = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;

  &:first-child {
    /* max-width: 544px; */
  }

  & + & {
    margin-left: 24px;
  }
`;

const Question = styled.div`
  display: flex;
  position: relative;

  & + & {
    margin-top: 4px;
  }

  ${mediaSizes.max1024} {
    & + & {
      margin-top: 8px;
    }
  } ;
`;

const QuestionText = styled.div`
  position: relative;
  font-family: ${p => p.theme.fontFamily.Libre};
  color: ${p => p.theme.colors.primary};
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 40px;
  transition: ${p => p.theme.transitions.regular};
  cursor: pointer;

  & + & {
    margin-top: 4px;
  }

  &:hover {
    text-decoration: underline;
  }

  ${mediaSizes.max660} {
    font-family: ${p => p.theme.fontFamily.Ubuntu};
    color: ${p => p.theme.colors.primary};
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
  } ;
`;

const QuestionNumber = styled.div`
  font-family: ${p => p.theme.fontFamily.Libre};
  color: ${p => p.theme.colors.primary};
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 40px;
  margin-right: 8px;

  ${mediaSizes.max660} {
    font-family: ${p => p.theme.fontFamily.Ubuntu};
    color: ${p => p.theme.colors.primary};
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
  } ;
`;

const AnswerTitle = styled.div`
  font-family: ${p => p.theme.fontFamily.Ubuntu};
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;

  ${mediaSizes.max660} {
    font-size: 14px;
    line-height: 178%;
  } ;
`;

const AnswerDesc = styled.div`
  font-family: ${p => p.theme.fontFamily.Ubuntu};
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  margin: 16px 0 32px 0;

  ${mediaSizes.max660} {
    font-size: 14px;
    line-height: 178%;
  } ;
  >ol {
    margin: 0;
    padding-left: 24px;
    li {
      margin: 0;
    }
    li + li {
      margin-top: 16px;
    }
    p {
      margin: 0;
    }
  }
`;

const QuestionAnswer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(calc(-100% - 8px));
  display: flex;
  flex-direction: column;
  min-width: 522px;
  width: 100%;
  padding: 24px 40px;
  z-index: 5;
  background: white;
  transition: ${p => p.theme.transitions.regular};
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15), 0px 1px 6px rgba(0, 0, 0, 0.11),
    0px 0.500862px 1.50259px rgba(0, 0, 0, 0.11),
    0px 0.181152px 0.543456px rgba(0, 0, 0, 0.07);
  /*  */
  visibility: hidden;
  opacity: 0;

  ${mediaSizes.max660} {
    min-width: initial;
    padding: 24px 32px;
  } ;
`;

const ButtonClose = styled(Button)`
  max-width: 376px;
  margin: 0 auto;

  ${mediaSizes.max660} {
    padding: 0 48px;
  }
`;

export {
  Question,
  QuestionCont,
  QuestionText,
  QuestionNumber,
  QuestionWrap,
  ButtonClose,
  QuestionAnswer,
  AnswerTitle,
  AnswerDesc,
};
