// core
import React from 'react';

// utils

// types

// data
import { tableData } from './data';

// styles
import {
  Button,
  Content,
  Desc,
  Separator,
  TermsTitle,
  Table,
  TableAmount,
  TableCont,
  TableItem,
  TableName,
  TableOperator,
  TableOutline,
  TableWarning,
  TableWarningArrow,
  TableWarningText,
  TermsCont,
  TermsDesc,
  TermsTextCont,
  Title,
  Wrapper,
} from './styles';
import RedOutline from '../../components/Icons/RedOutline';
import ArrowHeadIcon from '../../components/Icons/ArrowHead';
import { useWindowSize } from 'react-use';

const TaxComp = () => {
  const { width } = useWindowSize();
  return (
    <Wrapper>
      <Content>
        <Title>Understanding Your Tax Bill</Title>
        <TableCont>
          <TableWarning>
            <TableWarningArrow>
              <ArrowHeadIcon />
            </TableWarningArrow>
            <TableWarningText>
              Pay attention to the column on your tax bill, which shows exactly
              how your tax bill is calculated.
              <br />
              It's im-portant to check to ensure you received all of the
              exemptions to which you are entitled.
            </TableWarningText>
          </TableWarning>
          <Table>
            <TableOutline>
              <RedOutline />
            </TableOutline>
            {tableData.map((el, i) => (
              <TableItem key={i}>
                <TableName indent={el.indent}>{el.name}</TableName>
                <TableOperator>{el.operator}</TableOperator>
                <TableAmount border={el.border}>{el.amount}</TableAmount>
              </TableItem>
            ))}
          </Table>
          <Desc>
            Your property tax bill is determined by two things - a property's
            equalized assessed value (its share of the total tax base) and the
            applicable tax rates, which depend on the level of spending of local
            taxing districts.
            <br />
            <br />
            The assessed value is determined by local township assessors, who
            determine the market value of your home. This value is based on many
            factors, including your property characteristics, current sales of
            like homes in your area, and a sales ratio study that takes the
            three previous years' sales into account. Generally, your assessment
            is determined by taking one-third of market value and subtracting
            any exemptions to which you may be entitled. Tax rates are set by
            local government bodies that levy dollars. The amount of dollars
            levied by a taxing district depends on its budget.
          </Desc>
        </TableCont>
        <TermsCont>
          {width <= 660 ? null : (
            <>
              <TermsTitle>Terms you should know...</TermsTitle>
              <Separator />
            </>
          )}
          <TermsTextCont>
            <TermsDesc>
              <span>Equalization Factor:</span> the factor that must be applied
              to local assessments to bring about the percentage of increase or
              decrease that will result in an equalized assessed value equal to
              one-third of the market value of taxable property in a
              jurisdiction.
              <br />
              <br />
              <span>Equalized Assessed Value:</span> the assessed value
              multiplied by the state-certified equalization factor. Tax bills
              are calculated by multiplying the equalized assessed value minus
              any exemptions by the tax rate.
              <br />
              <br />
              <span>Exemption:</span> a reduction in the assessed value of a
              property. Will County offers many exemptions, including a general
              homestead exemptions and exemptions for senior citizens, veterans,
              and disabled persons.
              <br />
              <br />
              <span>Levy:</span> the amount of money a taxing body certifies to
              be raised from the property tax.
            </TermsDesc>
            <TermsDesc>
              <span>Market Value:</span> the most probable sale price of a
              property in a competitive and open market, assuming that the buyer
              and seller are acting prudently and knowledgeably, allowing
              sufficien time for the sale, and assuming that the transaction is
              not affected by undue pressures.
              <br />
              <br />
              <span>Tax Code:</span> a number used by the county clerk that
              refers to a specific combination of taxing bodies.
              <br />
              <br />
              <span>Tax Rate:</span> the amount of tax due stated in terms of a
              percentage of the tax base within a certain tax code.
              <br />
              <br />
              <span>Taxing Body/Taxing District:</span> a local governmental
              unit that levies a property tax. Taxing districts include school
              districts, park districts, fire protection districts, and
              municipalities, among others.
              <br />
              <br />
              <span>Total Assessed Value (Tax Base):</span> the sum of all real
              property within a taxing district.
            </TermsDesc>
          </TermsTextCont>
        </TermsCont>
        <Button to="/#analysis" theme={'fill'}>
          I want to sign up now!
        </Button>
      </Content>
    </Wrapper>
  );
};

export default TaxComp;
