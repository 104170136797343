// core
import React, { useEffect, useState } from 'react';
import { ErrorMessage, Formik, Field } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';

// utils
import { $apiClient } from '../../utils/request';

// types
import { FormValues, IPaymentData } from './types';
import { EButtonTypes } from '../../components/Atoms/Button/types';

// styles
import {
  Wrapper,
  Title,
  Subtitle,
  Addresses,
  AddressesItem,
  AddressesItemSpan,
  SignButton,
  BlockText,
  BlockTextTitle,
  BlockTextDesc,
  FormTitle,
  FormField,
  FormItem,
  FormWrap,
  FormFieldWrap,
  FormFieldLine,
  FormFieldRow,
  FormFieldLabel,
  FormSubmitButton,
  Error,
  Cards,
  CardsItem,
  FormCheckbox,
  FormCheckBoxIcon,
  FormCheckBoxRow,
  FormCheckBoxWrap,
  FormCheckBoxLabel,
  FormCheckBoxLink,
  Failed,
  FailedBox,
  FailedBoxTitle,
  FailedBoxSubtitle,
  FailedTitle,
  FailedItems,
  FailedItem,
  FormFieldMasked,
  ButtonSignWrap,
} from './styles';

// data
import { initialData, PaymentValidation } from './data';

// Loader
import LoadingIndicator from '../../components/Features/Loaders/LoadingIndicator';
import LoadingWrapper from '../../components/Features/Loaders/LoadingWrapper';
import {
  ColorType,
  TypesType,
} from '../../components/Features/Loaders/LoadingIndicator/types';

// icons

import InputCheckIcon from '../../components/Icons/InputCheck';
import InputUncheckIcon from '../../components/Icons/InputUncheck';

const PaymentForm = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<IPaymentData | null>(null);
  const [isError, setError] = useState<boolean>(false);

  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const initialValues: FormValues = initialData;

  const expiryMask = [/[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/];

  const getData = () => {
    setLoading(true);
    $apiClient
      .get(`appeal/${id}`)
      .then(res => {
        setData(res.data.data);
        if (res.data.data.status === true) {
          setTimeout(() => {
            const element = document.getElementById('payment');
            window.scrollTo({
              left: 0,
              top: element!.offsetTop,
              behavior: 'smooth',
            });
          }, 300);
        }
      })
      .finally(() => setLoading(false));
  };

  const signDocuments = () => {
    setLoading(true);
    $apiClient
      .get(`documents/${id}`)
      .then(res => window.location.replace(res.data.data.url))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (values: FormValues) => {
    setError(false);
    setLoading(true);
    const data = { ...values, type: 'card' };
    $apiClient
      .post(`payment/${id}`, data)
      .then(res => navigate(`/payment-success/${id}`))
      .catch(() => setError(true))
      .finally(() => setLoading(false));
  };

  return (
    <Wrapper>
      {loading && (
        <LoadingWrapper>
          <LoadingIndicator
            type={TypesType.Local}
            color={ColorType.Dark}
            size={7}
          />
        </LoadingWrapper>
      )}
      <Title>Let’s Get Started!</Title>
      <Subtitle>
        Thank You for taking a step to reduce your property taxes. We will{' '}
        <br /> do our best to assist you in this matter. To get started, please
        sign <br /> Illinois Property Tax Appeals engagement documents <br />{' '}
        and submit your payment.
      </Subtitle>
      <Addresses>
        <AddressesItem>
          <AddressesItemSpan>Property address:</AddressesItemSpan>
          {data?.address}
        </AddressesItem>
        <AddressesItem>
          <AddressesItemSpan>Pin:</AddressesItemSpan>
          {data?.pin}
        </AddressesItem>
      </Addresses>
      <BlockText>
        <BlockTextTitle>1. Appeal Authorization</BlockTextTitle>
        <BlockTextDesc>
          Click on the button below and sign the Illinois Property Tax Appeals
          service agreement and the county’s authorization documents. By signing
          these documents you authorize Illinois Property Tax Appeals and our
          attorney to represent you as part of this appeal process.
        </BlockTextDesc>
      </BlockText>

      <ButtonSignWrap
        onClick={() => {
          data && data.status === false && signDocuments();
        }}
      >
        <SignButton
          disabled={data ? data.status === true : false}
          theme={EButtonTypes.Fill}
          type="button"
        >
          Sign Documents
        </SignButton>
      </ButtonSignWrap>
      {data?.status && (
        <>
          <BlockText id={'payment'}>
            <BlockTextTitle>2. Submit Payment</BlockTextTitle>
            <BlockTextDesc>
              Please submit your payment to start this appeal process. Once we
              receive your payment our team will began to work on preparing
              evidence for your appeal to be timely filed.
            </BlockTextDesc>
          </BlockText>

          <FormTitle>Appeal Fee: ${data?.fee}</FormTitle>

          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={PaymentValidation}
          >
            {({ errors, touched, values, handleChange }) => (
              <FormItem>
                <FormWrap>
                  <FormFieldLine>
                    <FormFieldWrap>
                      <FormFieldRow>
                        <FormField
                          type="text"
                          name="first_name"
                          className={
                            errors.first_name && touched.first_name && 'error'
                          }
                        />
                        <FormFieldLabel>First Name</FormFieldLabel>
                      </FormFieldRow>
                      <ErrorMessage name="first_name">
                        {msg => <Error>{msg}</Error>}
                      </ErrorMessage>
                    </FormFieldWrap>
                    <FormFieldWrap>
                      <FormFieldRow>
                        <FormField
                          type="text"
                          name="last_name"
                          className={
                            errors.last_name && touched.last_name && 'error'
                          }
                        />
                        <FormFieldLabel>Last Name</FormFieldLabel>
                      </FormFieldRow>
                      <ErrorMessage name="last_name">
                        {msg => <Error>{msg}</Error>}
                      </ErrorMessage>
                    </FormFieldWrap>
                  </FormFieldLine>
                  <Cards>
                    <CardsItem
                      src={'/img/cards/mastercard.svg'}
                      alt={'Mastercard'}
                    />
                    <CardsItem src={'/img/cards/visa.svg'} alt={'Visa'} />
                    <CardsItem
                      src={'/img/cards/american-express.svg'}
                      alt={'American Express'}
                    />
                    <CardsItem
                      src={'/img/cards/discover-network.svg'}
                      alt={'Discover Network'}
                    />
                  </Cards>
                  <FormFieldLine>
                    <FormFieldWrap>
                      <FormFieldRow>
                        <FormField
                          type="text"
                          name="number"
                          className={errors.number && touched.number && 'error'}
                        />
                        <FormFieldLabel>Credit Card No #</FormFieldLabel>
                      </FormFieldRow>
                      <ErrorMessage name="number">
                        {msg => <Error>{msg}</Error>}
                      </ErrorMessage>
                    </FormFieldWrap>
                  </FormFieldLine>
                  <FormFieldLine className={'three'}>
                    <FormFieldWrap>
                      <FormFieldRow>
                        <Field
                          type="text"
                          name="expiry"
                          render={({ field }) => (
                            <FormFieldMasked
                              {...field}
                              mask={expiryMask}
                              id="expiry"
                              type="text"
                              onChange={handleChange}
                              className={
                                errors.expiry && touched.expiry && 'error'
                              }
                            />
                          )}
                        />
                        <FormFieldLabel>Exp. Date</FormFieldLabel>
                      </FormFieldRow>
                      <ErrorMessage name="expiry">
                        {msg => <Error>{msg}</Error>}
                      </ErrorMessage>
                    </FormFieldWrap>
                    <FormFieldWrap>
                      <FormFieldRow>
                        <FormField
                          type="text"
                          name="cvv"
                          className={errors.cvv && touched.cvv && 'error'}
                        />
                        <FormFieldLabel>CVC</FormFieldLabel>
                      </FormFieldRow>
                      <ErrorMessage name="cvv">
                        {msg => <Error>{msg}</Error>}
                      </ErrorMessage>
                    </FormFieldWrap>
                    <FormFieldWrap>
                      <FormFieldRow>
                        <FormField
                          type="number"
                          name="zip_code"
                          className={
                            errors.zip_code && touched.zip_code && 'error'
                          }
                        />
                        <FormFieldLabel>Zip Code</FormFieldLabel>
                      </FormFieldRow>
                      <ErrorMessage name="zip_code">
                        {msg => <Error>{msg}</Error>}
                      </ErrorMessage>
                    </FormFieldWrap>
                  </FormFieldLine>
                  <FormCheckBoxRow>
                    <FormCheckBoxWrap>
                      <FormCheckbox id={'agree'} type="checkbox" name="agree" />
                      <FormCheckBoxIcon>
                        {values.agree === true ? (
                          <InputCheckIcon />
                        ) : (
                          <InputUncheckIcon />
                        )}
                      </FormCheckBoxIcon>
                      <FormCheckBoxLabel htmlFor={'agree'}>
                        I agree to the Illinois Property Tax Appeal Inc.{' '}
                        <FormCheckBoxLink
                          to={'/terms-of-service'}
                          target={'_blank'}
                        >
                          Terms and Conditions
                        </FormCheckBoxLink>{' '}
                        and{' '}
                        <FormCheckBoxLink
                          to={'/privacy-policy'}
                          target={'_blank'}
                        >
                          Privacy Policy
                        </FormCheckBoxLink>{' '}
                      </FormCheckBoxLabel>
                    </FormCheckBoxWrap>
                  </FormCheckBoxRow>
                  <FormSubmitButton
                    theme={EButtonTypes.Fill}
                    type={'submit'}
                    disabled={values.agree === false}
                  >
                    Submit Payment
                  </FormSubmitButton>
                  {isError && (
                    <Failed>
                      <FailedBox>
                        <FailedBoxTitle>An Error Occurred</FailedBoxTitle>
                        <FailedBoxSubtitle>
                          Remote transaction failure. Please Contact support.
                        </FailedBoxSubtitle>
                      </FailedBox>
                      <FailedTitle>
                        Some of the most common reasons for this are:
                      </FailedTitle>
                      <FailedItems>
                        <FailedItem>
                          You may have entered your credit card details
                          incorrectly
                        </FailedItem>
                        <FailedItem>
                          You may have insufficient funds on your credit card
                        </FailedItem>
                      </FailedItems>
                    </Failed>
                  )}
                </FormWrap>
              </FormItem>
            )}
          </Formik>
        </>
      )}
    </Wrapper>
  );
};

export default PaymentForm;
