import React from 'react';
import { Svg } from './styles';

const CrossIcon = ({ props }: any) => (
  <Svg viewBox="0 0 32 32" fill="none" {...props}>
    <path
      d="M8 24L24 8M8 8L24 24"
      stroke="#EE5252"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </Svg>
);

export default CrossIcon;
