import styled from 'styled-components/macro';
import ButtonLink from '../../components/Atoms/ButtonLink';
import { mediaSizes } from '../../styles/media';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0 80px 0;
  overflow: hidden;

  ${mediaSizes.max1024} {
    padding: 16px 0 80px 0;
  }
`;

const Content = styled.div`
  max-width: 1040px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TableCont = styled.div`
  display: flex;
  position: relative;
  margin: 80px 0;

  ${mediaSizes.max1024} {
    flex-direction: column-reverse;
    margin: 40px 0 64px 0;
    padding: 0 96px;
  }
  ${mediaSizes.max660} {
    padding: 0 24px;
    justify-content: center;
    align-items: center;
    margin: 24px 0 40px 0;
  }
`;

const Table = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  min-width: 411px;
  padding: 24px 40px 32px 32px;
  border: 1px solid #e3e5e8;
  margin-right: 80px;

  ${mediaSizes.max1024} {
    min-width: initial;
    max-width: 343px;
    padding: 24px 24px 24px 16px;
  }
  ${mediaSizes.max660} {
    margin-right: 0;
  }

  ${mediaSizes.max480} {
    width: 100%;
  }
`;

const TableText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  font-family: ${p => p.theme.fontFamily.Ubuntu};
  color: ${p => p.theme.colors.black};
`;

const TableOperator = styled(TableText)`
  margin-right: 40px;

  ${mediaSizes.max1024} {
    margin-right: 16px;
    margin-left: 28px;
  }
`;

const TableAmount = styled(TableText)<{ border?: boolean }>`
  display: flex;
  justify-content: flex-end;
  width: 77px;
  text-align: end;
  position: relative;

  ${p =>
    p.border
      ? `
        &::after{
            content: "";
            position: absolute;
            width: 100%;
            height: 1px;
            background-color: #D2D3D6;
            bottom: 0;
            left: 0;
        }
    `
      : ``}
`;

const TableName = styled(TableText)<{ indent?: boolean }>`
  width: 200px;
  padding-left: ${p => (p.indent ? '24px' : '0')};
`;

const TableOutline = styled.div`
  width: 103px;
  height: 43px;
  position: absolute;
  bottom: 24px;
  right: 11px;
  z-index: 4;

  ${mediaSizes.max1024} {
    width: 84px;
    height: 50px;
    bottom: 12px;
    right: 10px;
  }
`;

const TableWarning = styled.div`
  position: absolute;
  bottom: -40px;
  right: -45px;
  display: flex;
  align-items: center;
  z-index: 5;
  transform: rotate(6deg);

  ${mediaSizes.max1024} {
    transform: none;
    flex-direction: column-reverse;
    align-items: flex-start;
    bottom: 66px;
    right: initial;
    left: 360px;
  }

  ${mediaSizes.max660} {
    flex-direction: column;
    width: fit-content;
    position: static;
    align-items: flex-end;
  }

  ${mediaSizes.max480} {
    width: 100%;
  }
`;
const TableWarningText = styled.div`
  padding: 8px 24px 8px 16px;
  max-width: 650px;
  font-family: ${p => p.theme.fontFamily.Ubuntu};
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  background-color: ${p => p.theme.colors.pink};
  border-radius: 2px;
  color: #fff6f6;

  ${mediaSizes.max1024} {
    padding: 8px 16px;
    width: 352px;
    border-radius: 4px;
  }

  ${mediaSizes.max480} {
    width: 100%;
  }
`;
const TableWarningArrow = styled.div`
  width: 47px;
  height: 95px;
  margin-right: -5px;

  ${mediaSizes.max1024} {
    width: 12px;
    height: 24px;
    margin-right: 0;
    margin-top: -8px;
    margin-left: 16px;
    transform: rotate(-90deg);
  }

  ${mediaSizes.max660} {
    margin-right: 32px;
    margin-bottom: -8px;
    margin-top: 0;
    margin-left: 0;
    transform: rotate(90deg);
  }
`;

const TableItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:last-child {
    ${TableAmount} {
      color: #d03737;
    }
  }
`;

const TermsCont = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
`;

const Separator = styled.div`
  width: 100%;
  height: 1px;
  margin: 16px 0 40px 0;
  background-color: ${p => p.theme.colors.primary};

  ${mediaSizes.max1024} {
    width: calc(100% - 64px);
    align-self: center;
  }
`;

const TermsDesc = styled.div`
  font-family: ${p => p.theme.fontFamily.Ubuntu};
  color: ${p => p.theme.colors.primary};
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;

  & + & {
    margin-left: 80px;
  }

  span {
    font-weight: 700;
  }

  ${mediaSizes.max1024} {
    & + & {
      margin-left: 40px;
    }
  }
  ${mediaSizes.max660} {
    font-size: 14px;
    line-height: 24px;
    & + & {
      margin-left: 0;
      margin-top: 30px;
    }
  }
`;

const TermsTextCont = styled.div`
  display: flex;
  margin-bottom: 80px;

  ${mediaSizes.max1024} {
    padding: 0 32px;
    margin-bottom: 40px;
  }
  ${mediaSizes.max660} {
    flex-direction: column;
  }
`;

const Title = styled.div`
  font-family: ${p => p.theme.fontFamily.Libre};
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 48px;
  text-align: center;
  color: ${p => p.theme.colors.primary};

  ${mediaSizes.max480} {
    font-size: 24px;
    line-height: 40px;
  }
`;

const TermsTitle = styled(Title)`
  ${mediaSizes.max1024} {
    padding-left: 32px;
  }
`;

const Desc = styled.div`
  font-family: ${p => p.theme.fontFamily.Ubuntu};
  color: ${p => p.theme.colors.black};
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  max-width: 1045px;

  ${mediaSizes.max1024} {
    margin-bottom: 40px;
  }
  ${mediaSizes.max660} {
    font-size: 14px;
    line-height: 24px;
  }
`;

const SubTitle = styled.div`
  font-family: ${p => p.theme.fontFamily.Libre};
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 48px;
  text-align: center;
  color: ${p => p.theme.colors.black};
  margin-bottom: 40px;
`;

const Button = styled(ButtonLink)`
  max-width: 376px;

  ${mediaSizes.max660} {
    padding: 0 48px;
  }
`;

export {
  Wrapper,
  TermsDesc,
  TermsTextCont,
  Button,
  Title,
  SubTitle,
  Desc,
  Separator,
  Content,
  TableCont,
  TermsCont,
  Table,
  TableName,
  TableItem,
  TableText,
  TableAmount,
  TableOperator,
  TableOutline,
  TableWarning,
  TableWarningArrow,
  TableWarningText,
  TermsTitle,
};
