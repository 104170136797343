// core
import React, { useCallback, useEffect, useState } from 'react';
import QuoteMarkIcon from '../../components/Icons/QuoteMark';

// utils
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';

// data

// styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import {
  Button,
  Card,
  CardAdress,
  CardDesc,
  CardName,
  Image,
  CardCont,
  QuoteMarks,
  Title,
  Wrapper,
  CardNavCont,
  CardNavArrow,
} from './styles';
import ArrowPrevIcon from '../../components/Icons/ArrowPrev';
import ArrowNextIcon from '../../components/Icons/ArrowNext';
import { clients } from './data';
import { useWindowSize } from 'react-use';

const TaxpayersComp = () => {
  const { width: windowWidth } = useWindowSize();
  const [width, setWidth] = useState<number>(windowWidth);

  useEffect(() => {
    setWidth(windowWidth);
  }, [windowWidth, width]);

  return (
    <Wrapper>
      <Title>Satisfied Taxpayers</Title>
      <CardCont>
        <Image src="/img/taxpayers.png" />
        {width >= 660 ? (
          <Swiper
            loop={true}
            centeredSlides={true}
            slidesPerView={3}
            speed={700}
            allowTouchMove={true}
            navigation={{ prevEl: '.prevSlide', nextEl: '.nextSlide' }}
            modules={[Pagination, Navigation]}
            pagination={{
              clickable: true,
            }}
            className="mySwiper"
          >
            {clients.map((el, i) => (
              <SwiperSlide key={i}>
                <Card>
                  <QuoteMarks>
                    <QuoteMarkIcon />
                  </QuoteMarks>
                  <CardDesc>{el.quote}</CardDesc>
                  <CardName>{el.name}</CardName>
                  <CardAdress dangerouslySetInnerHTML={{ __html: el.place }} />
                  <CardNavCont>
                    <CardNavArrow className="prevSlide">
                      <ArrowPrevIcon />
                    </CardNavArrow>
                    <CardNavArrow className="nextSlide">
                      <ArrowNextIcon />
                    </CardNavArrow>
                  </CardNavCont>
                </Card>
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          <Swiper
            loop={true}
            slidesPerView={1}
            speed={700}
            allowTouchMove={true}
            modules={[Pagination]}
            pagination={{
              clickable: true,
            }}
            className="swiper-mob"
          >
            {clients.map((el, i) => (
              <SwiperSlide key={i}>
                <Card>
                  <QuoteMarks>
                    <QuoteMarkIcon />
                  </QuoteMarks>
                  <CardDesc>{el.quote}</CardDesc>
                  <CardName>{el.name}</CardName>
                  <CardAdress>{el.place}</CardAdress>
                  <CardNavCont>
                    <CardNavArrow className="prevSlide">
                      <ArrowPrevIcon />
                    </CardNavArrow>
                    <CardNavArrow className="nextSlide">
                      <ArrowNextIcon />
                    </CardNavArrow>
                  </CardNavCont>
                </Card>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </CardCont>
      <Button to="/#analysis" theme={'fill'}>
        I want to sign up now!
      </Button>
    </Wrapper>
  );
};

export default TaxpayersComp;
