import * as Yup from 'yup';

export const initialData = {
  property_address: '',
  property_city: '',
  property_state: '',
  property_zip_code: '',
  property_township: '',
  property_purchased_recently: false,
  property_sale_price: '',

  property_pin: '',
  property_multiple_pins: '',
  property_additional_pin1: '',
  property_additional_pin2: '',
  property_additional_pin3: '',

  owner_name: '',
  owner_email: '',
  owner_corporate_name: '',
  owner_phone: '',

  company_type_of_property: '',
  company_area: '',
  company_stories: '',
  company_have_appealed: '',
  company_significant_vacancy: '',
};
export const IndustrialValidation = Yup.lazy(values => {
  switch (values.property_multiple_pins) {
    case '1':
      return Yup.object().shape({
        property_address: Yup.string()
          .min(2, 'Too Short!')
          .max(50, 'Too Long!')
          .required('Required'),
        property_city: Yup.string()
          .min(2, 'Too Short!')
          .max(50, 'Too Long!')
          .required('Required'),
        property_state: Yup.string()
          .min(2, 'Too Short!')
          .max(50, 'Too Long!')
          .required('Required'),
        property_zip_code: Yup.string()
          .min(2, 'Too Short!')
          .max(50, 'Too Long!')
          .required('Required'),
        property_township: Yup.string()
          .min(2, 'Too Short!')
          .max(50, 'Too Long!')
          .required('Required'),
        property_purchased_recently: Yup.string().required('Required'),
        property_sale_price: Yup.string()
          .min(2, 'Too Short!')
          .max(50, 'Too Long!'),
        property_pin: Yup.string()
          .min(2, 'Too Short!')
          .max(50, 'Too Long!')
          .required('Required'),
        property_multiple_pins: Yup.string().required('Required'),
        owner_name: Yup.string()
          .min(2, 'Too Short!')
          .max(50, 'Too Long!')
          .required('Required'),
        owner_email: Yup.string().email('Invalid email').required('Required'),
        owner_corporate_name: Yup.string()
          .min(2, 'Too Short!')
          .max(50, 'Too Long!')
          .required('Required'),
        owner_phone: Yup.string()
          .min(2, 'Too Short!')
          .max(50, 'Too Long!')
          .required('Required'),
        company_type_of_property: Yup.string()
          .min(2, 'Too Short!')
          .max(50, 'Too Long!')
          .required('Required'),
        company_area: Yup.string()
         .matches(/^\d+$/,"Must be a valid number!")
          // .integer("Must be a number!")
          .min(2, 'Too Short!')
          .max(50, 'Too Long!')
          .required('Required'),
        company_stories: Yup.string()
          .matches(/^\d+$/,"Must be a valid number!")
          .min(1, 'Too Short!')
          .max(50, 'Too Long!')
          .required('Required'),
        company_have_appealed: Yup.string()
          .min(2, 'Too Short!')
          .max(50, 'Too Long!')
          .required('Required'),
        company_significant_vacancy: Yup.string()
          .min(2, 'Too Short!')
          .max(50, 'Too Long!')
          .required('Required'),
        property_additional_pin1: Yup.string()
          .min(2, 'Too Short!')
          .max(50, 'Too Long!')
          .required('Required'),
        property_additional_pin2: Yup.string()
          .min(2, 'Too Short!')
          .max(50, 'Too Long!'),
        property_additional_pin3: Yup.string()
          .min(2, 'Too Short!')
          .max(50, 'Too Long!'),
      });
    case '0':
      return Yup.object().shape({
        property_address: Yup.string()
          .min(2, 'Too Short!')
          .max(50, 'Too Long!')
          .required('Required'),
        property_city: Yup.string()
          .min(2, 'Too Short!')
          .max(50, 'Too Long!')
          .required('Required'),
        property_state: Yup.string()
          .min(2, 'Too Short!')
          .max(50, 'Too Long!')
          .required('Required'),
        property_zip_code: Yup.string()
          .min(2, 'Too Short!')
          .max(50, 'Too Long!')
          .required('Required'),
        property_township: Yup.string()
          .min(2, 'Too Short!')
          .max(50, 'Too Long!')
          .required('Required'),
        property_purchased_recently: Yup.string().required('Required'),
        property_sale_price: Yup.string()
          .min(2, 'Too Short!')
          .max(50, 'Too Long!'),
        property_pin: Yup.string()
          .min(2, 'Too Short!')
          .max(50, 'Too Long!')
          .required('Required'),
        property_multiple_pins: Yup.string().required('Required'),
        owner_name: Yup.string()
          .min(2, 'Too Short!')
          .max(50, 'Too Long!')
          .required('Required'),
        owner_email: Yup.string().email('Invalid email').required('Required'),
        owner_corporate_name: Yup.string()
          .min(2, 'Too Short!')
          .max(50, 'Too Long!')
          .required('Required'),
        owner_phone: Yup.string()
          .min(2, 'Too Short!')
          .max(50, 'Too Long!')
          .required('Required'),
        company_type_of_property: Yup.string()
          .min(2, 'Too Short!')
          .max(50, 'Too Long!')
          .required('Required'),
        company_area: Yup.string()
          .min(2, 'Too Short!')
          .max(50, 'Too Long!')
          .required('Required'),
        company_stories: Yup.string()
          .min(1, 'Too Short!')
          .max(50, 'Too Long!')
          .required('Required'),
        company_have_appealed: Yup.string()
          .min(2, 'Too Short!')
          .max(50, 'Too Long!')
          .required('Required'),
        company_significant_vacancy: Yup.string()
          .min(2, 'Too Short!')
          .max(50, 'Too Long!')
          .required('Required'),
      });
    default:
      return Yup.object().shape({
        property_address: Yup.string()
          .min(2, 'Too Short!')
          .max(50, 'Too Long!')
          .required('Required'),
        property_city: Yup.string()
          .min(2, 'Too Short!')
          .max(50, 'Too Long!')
          .required('Required'),
        property_state: Yup.string()
          .min(2, 'Too Short!')
          .max(50, 'Too Long!')
          .required('Required'),
        property_zip_code: Yup.string()
          .min(2, 'Too Short!')
          .max(50, 'Too Long!')
          .required('Required'),
        property_township: Yup.string()
          .min(2, 'Too Short!')
          .max(50, 'Too Long!')
          .required('Required'),
        property_purchased_recently: Yup.string().required('Required'),
        property_sale_price: Yup.string()
          .min(2, 'Too Short!')
          .max(50, 'Too Long!'),
        property_pin: Yup.string()
          .min(2, 'Too Short!')
          .max(50, 'Too Long!')
          .required('Required'),
        property_multiple_pins: Yup.string().required('Required'),
        owner_name: Yup.string()
          .min(2, 'Too Short!')
          .max(50, 'Too Long!')
          .required('Required'),
        owner_email: Yup.string().email('Invalid email').required('Required'),
        owner_corporate_name: Yup.string()
          .min(2, 'Too Short!')
          .max(50, 'Too Long!')
          .required('Required'),
        owner_phone: Yup.string()
          .min(2, 'Too Short!')
          .max(50, 'Too Long!')
          .required('Required'),
        company_type_of_property: Yup.string()
          .min(2, 'Too Short!')
          .max(50, 'Too Long!')
          .required('Required'),
        company_area: Yup.string()
          .min(2, 'Too Short!')
          .max(50, 'Too Long!')
          .required('Required'),
        company_stories: Yup.string()
          .min(1, 'Too Short!')
          .max(50, 'Too Long!')
          .required('Required'),
        company_have_appealed: Yup.string()
          .min(2, 'Too Short!')
          .max(50, 'Too Long!')
          .required('Required'),
        company_significant_vacancy: Yup.string()
          .min(2, 'Too Short!')
          .max(50, 'Too Long!')
          .required('Required'),
      });
  }
});
