import styled from 'styled-components/macro';
import ButtonLink from '../../components/Atoms/ButtonLink';
import { mediaSizes } from '../../styles/media';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0 80px 0;
  overflow: hidden;

  ${mediaSizes.max1024} {
  }

  ${mediaSizes.max660} {
    padding: 24px 24px 64px 24px;
  }
  ${mediaSizes.max480} {
  }
`;

const Content = styled.div`
  max-width: 807px;
  margin-bottom: 80px;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;

  ${mediaSizes.max660} {
    max-width: 100%;
    margin-bottom: 64px;
  }
`;

const Title = styled.div`
  font-family: ${p => p.theme.fontFamily.Libre};
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 48px;
  text-align: center;
  color: ${p => p.theme.colors.primary};
  margin-bottom: 40px;

  ${mediaSizes.max660} {
    font-size: 24px;
    line-height: 40px;
  }
`;

const Separator = styled.div`
  width: 100%;
  height: 2px;
  background-color: ${p => p.theme.colors.primary};
  margin: 40px 0 24px 0;
`;

const SubTitle = styled.div`
  font-family: ${p => p.theme.fontFamily.Ubuntu};
  color: ${p => p.theme.colors.black};
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  text-align: center;

  ${mediaSizes.max660} {
    font-size: 14px;
    line-height: 24px;
  } ;
`;

const AppleWrap = styled.div`
  width: 94px;
  height: 112px;

  margin-bottom: 16px;
  ${mediaSizes.max660} {
    width: 56px;
    height: 64px;
  } ;
`;

const AppleItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 210px;

  & + & {
    margin-left: 80px;
  }

  ${mediaSizes.max1024} {
    & + & {
      margin-left: 40px;
    }
  }
  ${mediaSizes.max660} {
    width: 99px;
    & + & {
      margin-left: 24px;
    }
  } ;
`;

const AppleCont = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 40px 0;

  ${mediaSizes.max660} {
  } ;
`;

const Desc = styled.div`
  font-family: ${p => p.theme.fontFamily.Ubuntu};
  color: ${p => p.theme.colors.black};
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;

  ${mediaSizes.max1024} {
    max-width: 616px;
  }
  ${mediaSizes.max660} {
    font-size: 14px;
    line-height: 24px;
  } ;
`;

const QuestionWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 0 160px; */
  max-width: 1120px;
  width: 100%;
  margin-bottom: 80px;

  ${mediaSizes.max1024} {
    flex-direction: column;
    padding: 0 112px;
    margin-bottom: 64px;
  }
  ${mediaSizes.max660} {
    padding: 0;
  }
`;

const QuestionCont = styled.div`
  display: flex;
  flex-direction: column;
  /* max-width: 552px; */
  width: 50%;

  /* &:first-child { */
  /*   max-width: 544px; */
  /* } */

  & + & {
    margin-left: 24px;
  }

  ${mediaSizes.max1024} {
    width: 100%;
    & + & {
      margin-left: 0;
      margin-top: 8px;
    }
  } ;
`;

const Button = styled(ButtonLink)`
  max-width: 376px;

  ${mediaSizes.max660} {
    padding: 0 24px;
  }
`;

const Question = styled.div`
  display: flex;
`;

const QuestionText = styled.div`
  position: relative;
  font-family: ${p => p.theme.fontFamily.Libre};
  color: ${p => p.theme.colors.primary};
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 40px;
  transition: ${p => p.theme.transitions.regular};
  cursor: pointer;

  & + & {
    margin-top: 4px;
  }

  &:hover {
    text-decoration: underline;
    /* &::after { */
    /*     content: ""; */
    /*     position: absolute; */
    /*     width: 100%; */
    /*     height: 1px; */
    /*     left: 0; */
    /*     bottom: 0; */
    /*     background-color: ${p => p.theme.colors.primary}; */
    /* } */
  }
`;

const QuestionNumber = styled.div`
  font-family: ${p => p.theme.fontFamily.Libre};
  color: ${p => p.theme.colors.primary};
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 40px;

  margin-right: 8px;
`;

export {
  Wrapper,
  Content,
  QuestionNumber,
  QuestionText,
  Question,
  Button,
  Title,
  SubTitle,
  Desc,
  Separator,
  AppleWrap,
  QuestionCont,
  QuestionWrap,
  AppleItem,
  AppleCont,
};
