import React from 'react';

const InstagramIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.1224 10.4584C13.6466 9.79224 12.8663 9.35449 11.9908 9.35449C11.1153 9.35449 10.3349 9.79224 9.8591 10.4584C9.55458 10.8961 9.36426 11.429 9.36426 12C9.36426 13.4465 10.5443 14.6265 11.9908 14.6265C13.4372 14.6265 14.6172 13.4465 14.6172 12C14.6172 11.429 14.4269 10.8961 14.1224 10.4584Z"
      fill="#F17575"
    />
    <path
      d="M15.7783 10.4775C15.9686 10.8582 16.0828 11.4672 16.0828 12.0001C16.0828 14.246 14.2557 16.0921 11.9908 16.0921C9.72593 16.0921 7.8988 14.265 7.8988 12.0001C7.8988 11.4482 8.013 10.8582 8.20332 10.4775H5.90039V16.587C5.90039 17.3864 6.60459 18.0906 7.40396 18.0906H16.5776C17.377 18.0906 18.0812 17.3864 18.0812 16.587V10.4775H15.7783Z"
      fill="#F17575"
    />
    <path
      d="M17.3962 6.25194H15.1885V8.78327H17.7008V6.5755V6.23291L17.3962 6.25194Z"
      fill="#F17575"
    />
    <path
      d="M17.9667 0.00927734H6.01427C2.70262 0.00927734 0 2.71189 0 6.02355V17.995C0 21.2876 2.70262 23.9902 6.01427 23.9902H17.9857C21.2974 23.9902 24 21.2876 24 17.976V6.02355C23.981 2.71189 21.2784 0.00927734 17.9667 0.00927734ZM19.4132 10.4581V16.5866C19.4132 18.1853 18.1761 19.4224 16.5773 19.4224H7.40365C5.80492 19.4224 4.5678 18.1853 4.5678 16.5866V10.4581V7.41293C4.5678 5.81419 5.80492 4.57708 7.40365 4.57708H16.5773C18.1761 4.57708 19.4132 5.81419 19.4132 7.41293V10.4581Z"
      fill="#F17575"
    />
  </svg>
);

export default InstagramIcon;
