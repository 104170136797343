import React from 'react';
import { Svg } from './styles';

const EmailIcon = ({ props }: any) => (
  <Svg viewBox="0 0 24 24">
    <path
      d="M16.2168 12C16.2168 9.79086 14.4259 8 12.2168 8C10.0077 8 8.2168 9.79086 8.2168 12C8.2168 14.2091 10.0077 16 12.2168 16C14.4259 16 16.2168 14.2091 16.2168 12ZM16.2168 12V13.5C16.2168 14.8807 17.3361 16 18.7168 16C20.0975 16 21.2168 14.8807 21.2168 13.5V12C21.2168 7.02944 17.1874 3 12.2168 3C7.24623 3 3.2168 7.02944 3.2168 12C3.2168 16.9706 7.24623 21 12.2168 21M16.7168 19.7942C15.2968 20.614 13.7464 21.0029 12.2168 21.0015"
      stroke="#8A8D94"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default EmailIcon;
