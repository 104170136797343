// core
import React from 'react';
import { useLocation } from 'react-router-dom';
import { useWindowSize } from 'react-use';

// utils

// types

// styles
import {
  Wrapper,
  PromoWrapper,
  PromoTitle,
  PromoSubtitle,
  PromoButton,
  Background,
  BackgroundImage,
  SectionsWrapper,
  SectionsTitle,
  SectionsSubtitle,
  CardsWrapper,
  CardItem,
  CardItemTitle,
  CardItemButton,
  CardItemSubTitle,
} from './styles';

const Home = () => {
  const { width } = useWindowSize();
  const location = useLocation();
  console.log(location);

  return (
    <Wrapper>
      <PromoWrapper>
        <Background>
          <BackgroundImage src={'/img/home.png'} />
        </Background>
        <PromoTitle>
          As an Illinois Homeowner,
          <br />
          do you feel like a number?
        </PromoTitle>
        <PromoSubtitle>
          We lower your tax burden <br />
          for a one-time low fee.
        </PromoSubtitle>
        <PromoButton to={'#analysis'} theme={'fill'}>
          Sign Up
        </PromoButton>
      </PromoWrapper>
      <SectionsWrapper id="analysis">
        <SectionsTitle>Get your complimentary savings analysis</SectionsTitle>
        <SectionsSubtitle>
          WE PROVIDE FINANCIAL RELIEF TO ILLINOIS PROPERTY TAXPAYERS FOR A ONE
          TIME SMALL FEE.
          {width <= 1024 ? null : <br />}
          WE SUCCESSFULLY APPEAL, TO ALLOW YOU TO KEEP MORE OF YOUR SAVINGS AND
          YOU PAY THE GOVERNMENT LESS.
        </SectionsSubtitle>
        <CardsWrapper>
          <CardItem className={'blue'}>
            <CardItemSubTitle>Provide me</CardItemSubTitle>
            <CardItemTitle>
              Cook County Computer Generated Residental Appeal Savings Analysis
            </CardItemTitle>
            <CardItemButton to={'/cook-county'} className={'blue'}>
              Click Here
            </CardItemButton>
          </CardItem>
          <CardItem className={'red'}>
            <CardItemSubTitle>Provide me</CardItemSubTitle>
            <CardItemTitle>
              Non-Cook County Residental Appeal Savings Analysis
            </CardItemTitle>
            <CardItemButton to={'/non-cook-county'} className={'red'}>
              Click Here
            </CardItemButton>
          </CardItem>
          <CardItem className={'green'}>
            <CardItemSubTitle>Provide a</CardItemSubTitle>
            <CardItemTitle>
              Illinois Commercial/ Industrial Projected Savings Analysis
            </CardItemTitle>
            <CardItemButton to={'/industrial'} className={'green'}>
              Click Here
            </CardItemButton>
          </CardItem>
        </CardsWrapper>
      </SectionsWrapper>
    </Wrapper>
  );
};

export default Home;
