import styled from 'styled-components/macro';
import { mediaSizes } from '../../../styles/media';
import { NavLink } from 'react-router-dom';

const Wrapper = styled.div`
  background: ${p => p.theme.colors.lightBlue};
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 15;

  ${mediaSizes.max1024} {
    padding: 32px 64px 0 64px;
  }
  ${mediaSizes.max660} {
    padding: 32px 32px 0 32px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${mediaSizes.max1024} {
    flex-direction: row;
  }
  ${mediaSizes.max480} {
    flex-direction: column;
  }
`;

const Container = styled.div`
  width: 100%;
  max-width: 1400px;
  padding: 0 96px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  &:first-child {
    &:before {
      content: '';
      width: calc(100% - 192px);
      height: 1px;
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      background: #e3e5e8;
    }
  }
  &:nth-child(2) {
    padding: 24px 96px;
  }

  ${mediaSizes.max1024} {
    padding: 0;
    align-items: flex-start;

    &:before {
      display: none;
    }

    &:nth-child(2) {
      padding: 0;
      flex-direction: column-reverse;
    }
  }
`;

const LogoWrap = styled(NavLink)`
  margin: 40px 0;

  ${mediaSizes.max1024} {
    margin: 0;
    /* margin-right: 90px; */
    margin-right: calc(100vw / 8.5);
  }
  ${mediaSizes.max660} {
    margin-right: 24px;
  }
  ${mediaSizes.max480} {
    margin-right: 0;
  }
`;
const Logo = styled.img`
  width: 130px;
  height: 120px;
  object-fit: contain;

  ${mediaSizes.max1024} {
    width: 104px;
    height: 96px;
  }
  ${mediaSizes.max480} {
    width: 96px;
    height: 88px;
  }
`;
const Contacts = styled.div`
  display: flex;
  align-items: center;

  ${mediaSizes.max1024} {
    flex-direction: column;
    align-items: flex-start;
  }
`;
const ContactsItem = styled.div`
  display: flex;
  align-items: center;

  & + & {
    margin-left: 64px;
  }

  ${mediaSizes.max1024} {
    & + & {
      margin-left: 0;
      margin-top: 24px;
    }
  }
`;
const ContactsItemIcon = styled.div`
  padding: 16px;
  border-radius: 50%;
  border: 1px solid #dfe7fd;
  height: 56px;
  width: 56px;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ContactsItemLink = styled.a`
  color: ${p => p.theme.colors.primary};
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  transition: ${p => p.theme.transitions.regular};

  &:hover {
    color: ${p => p.theme.colors.pink};
  }
`;

const Socials = styled.div`
  display: flex;
  align-items: center;
  max-width: 345px;
  width: 100%;

  ${mediaSizes.max1024} {
    justify-content: flex-end;
    margin-top: 32px;
    max-width: initial;
  }
  ${mediaSizes.max480} {
  }
`;
const SocialsText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: ${p => p.theme.colors.black};

  ${mediaSizes.max1024} {
    display: none;
  }
`;
const SocialsIcon = styled.a`
  display: block;
  margin-left: 24px;
  svg path {
    transition: ${p => p.theme.transitions.regular};
  }
  &:hover {
    svg path {
      fill: ${p => p.theme.colors.red};
    }
  }

  ${mediaSizes.max1024} {
  }

  ${mediaSizes.max480} {
    margin-left: 0;
    svg {
      width: 32px;
      height: 32px;
    }

    & + & {
      margin-left: 24px;
    }
  }
`;
const Rights = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin: 0 24px;
  color: ${p => p.theme.colors.gray};

  ${mediaSizes.max1024} {
    display: none;
  }
`;
const RightsSpan = styled.span`
  font-weight: 500;
`;
const Docs = styled.div`
  display: flex;
  align-items: center;

  ${mediaSizes.max1024} {
    width: 100%;
    flex-direction: column;
    align-items: flex-end;
  }
`;
const DocsLink = styled(NavLink)`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: ${p => p.theme.colors.pink};
  transition: ${p => p.theme.transitions.regular};
  & + & {
    margin-left: 64px;
  }
  &:hover {
    color: ${p => p.theme.colors.red};
  }

  ${mediaSizes.max1024} {
    & + & {
      margin-left: 0;
      margin-top: 32px;
    }
    ${mediaSizes.max480} {
      & + & {
        margin-top: 40px;
      }
    }
  }
`;

const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e3e5e8;

  margin-top: 32px;

  ${mediaSizes.max480} {
    margin: 32px 0 40px 0;
  }
`;

const RightsMob = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin: 8px 0;
  color: ${p => p.theme.colors.lighterGray};

  display: flex;
  align-items: center;
  justify-content: center;

  ${mediaSizes.max480} {
    margin: 40px 0 24px 0;
    color: #b1b3bb;
  }
`;

const MobLogoSocial = styled.div`
  display: flex;
  flex-direction: column;
`;

export {
  Wrapper,
  Content,
  Container,
  LogoWrap,
  Logo,
  Contacts,
  ContactsItem,
  ContactsItemIcon,
  ContactsItemLink,
  Socials,
  SocialsText,
  SocialsIcon,
  Rights,
  RightsMob,
  RightsSpan,
  Docs,
  DocsLink,
  Separator,
  MobLogoSocial,
};
