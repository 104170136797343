import React from 'react';
import { Svg } from './styles';

const InputCheckIcon = ({ props }: any) => (
  <Svg viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.33291 4.11612C4.56733 3.8817 4.88528 3.75 5.2168 3.75H16.2168C16.631 3.75 16.9668 3.41421 16.9668 3C16.9668 2.58579 16.631 2.25 16.2168 2.25H5.2168C4.48745 2.25 3.78798 2.53973 3.27225 3.05546C2.75653 3.57118 2.4668 4.27065 2.4668 5V19C2.4668 19.7293 2.75653 20.4288 3.27225 20.9445C3.78798 21.4603 4.48745 21.75 5.2168 21.75H19.2168C19.9461 21.75 20.6456 21.4603 21.1613 20.9445C21.6771 20.4288 21.9668 19.7293 21.9668 19V12C21.9668 11.5858 21.631 11.25 21.2168 11.25C20.8026 11.25 20.4668 11.5858 20.4668 12V19C20.4668 19.3315 20.3351 19.6495 20.1007 19.8839C19.8663 20.1183 19.5483 20.25 19.2168 20.25H5.2168C4.88528 20.25 4.56733 20.1183 4.33291 19.8839C4.09849 19.6495 3.9668 19.3315 3.9668 19V5C3.9668 4.66848 4.09849 4.35054 4.33291 4.11612ZM22.7471 4.53033C23.04 4.23744 23.04 3.76256 22.7471 3.46967C22.4542 3.17678 21.9794 3.17678 21.6865 3.46967L12.2168 12.9393L9.74713 10.4697C9.45423 10.1768 8.97936 10.1768 8.68647 10.4697C8.39357 10.7626 8.39357 11.2374 8.68647 11.5303L11.6865 14.5303C11.9794 14.8232 12.4542 14.8232 12.7471 14.5303L22.7471 4.53033Z"
      fill="currentColor"
    />
  </Svg>
);

export default InputCheckIcon;
