// core
import React from 'react';

// types
import { EButtonTypes, IProps } from './types';

// styles
import { Wrapper, Outline, Fill } from './styles';

const Button = ({ children, theme, type, disabled, ...restProps }: IProps) => {
  return (
    <Wrapper {...restProps}>
      {theme === EButtonTypes.Outline && (
        <Outline type={type} disabled={disabled}>
          {children}
        </Outline>
      )}
      {theme === EButtonTypes.Fill && (
        <Fill type={type} disabled={disabled}>
          {children}
        </Fill>
      )}
    </Wrapper>
  );
};

export default Button;
