import styled from 'styled-components/macro';
import { mediaSizes } from '../../styles/media';
import ButtonLink from '../../components/Atoms/ButtonLink';

const Wrapper = styled.div`
  max-width: 722px;
  padding: 24px 24px 64px 24px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  ${mediaSizes.min768} {
    padding: 24px 96px 80px 96px;
  }
`;
const Title = styled.div`
  font-family: ${p => p.theme.fontFamily.Libre};
  font-weight: 400;
  font-size: 24px;
  line-height: 40px;
  color: ${p => p.theme.colors.primary};
  text-align: center;
  margin-bottom: 40px;
  ${mediaSizes.min768} {
    font-size: 32px;
    line-height: 48px;
  }
`;

const Info = styled.div`
  width: 100%;
`;
const InfoReceipt = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  ${mediaSizes.min768} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
  }
`;
const InfoReceiptNumber = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  color: ${p => p.theme.colors.primary};
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  ${mediaSizes.min768} {
    margin-bottom: 0;
    margin-right: 12px;
  }
`;
const InfoReceiptNumberLink = styled.a`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: ${p => p.theme.colors.black};
  transition: ${p => p.theme.transitions.regular};
  cursor: pointer;

  svg {
    margin-left: 8px;
    path {
      transition: ${p => p.theme.transitions.regular};
    }
  }

  &:hover {
    color: ${p => p.theme.colors.pink};
    svg {
      color: ${p => p.theme.colors.pink};
    }
  }
`;
const InfoCard = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  ${mediaSizes.min768} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;
const InfoCardItem = styled.div`
  display: flex;
  align-items: center;
  & + & {
    margin-top: 24px;
  }

  ${mediaSizes.min768} {
    flex-direction: column;
    align-items: flex-start;
    & + & {
      margin-top: 0;
      margin-right: 32px;
    }
  }
`;
const InfoCardItemTitle = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #8a8d94;
  min-width: 130px;
  margin-right: 16px;
  ${mediaSizes.min768} {
    margin-bottom: 8px;
    margin-right: 0;
    min-width: auto;
  }
`;
const InfoCardItemText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: ${p => p.theme.colors.black};
  ${mediaSizes.min768} {
    font-size: 18px;
    line-height: 32px;
  }
`;
const InfoSummary = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #8a8d94;
  margin-bottom: 16px;
`;
const InfoBox = styled.div`
  width: calc(100% + 48px);
  margin-left: -24px;
  margin-right: -24px;
  padding: 16px 24px;
  background: #f6f8ff;
  margin-bottom: 40px;

  ${mediaSizes.min768} {
    width: 100%;
    margin: 0;
    margin-bottom: 40px;
    padding: 16px 40px;
  }
`;
const InfoBoxItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;
const InfoBoxItemSpan = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  color: #606467;

  & + & {
    margin-left: 12px;
  }
`;

const InfoBoxAmount = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-top: 16px;
  padding: 8px 0;
  &:before {
    content: '';
    width: 100%;
    height: 1px;
    background: #d2d3d6;
    position: absolute;
    left: 0;
    top: 0;
  }
`;
const InfoBoxAmountSpan = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  color: ${p => p.theme.colors.black};

  & + & {
    margin-left: 12px;
  }
`;
const Button = styled(ButtonLink)`
  max-width: 325px;
  margin: 0 auto;
  ${mediaSizes.min768} {
    max-width: 275px;
  }
`;
export {
  Wrapper,
  Title,
  Info,
  InfoReceipt,
  InfoReceiptNumber,
  InfoReceiptNumberLink,
  InfoCard,
  InfoCardItem,
  InfoCardItemTitle,
  InfoCardItemText,
  InfoSummary,
  InfoBox,
  InfoBoxItem,
  InfoBoxItemSpan,
  InfoBoxAmount,
  InfoBoxAmountSpan,
  Button,
};
