import styled from 'styled-components/macro';
import ButtonLink from '../../components/Atoms/ButtonLink';
import { mediaSizes } from '../../styles/media';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0 80px 0;
  overflow-x: hidden;
`;

const Title = styled.div`
  font-family: ${p => p.theme.fontFamily.Libre};
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 48px;
  text-align: center;
  color: ${p => p.theme.colors.primary};
`;

const CardNavCont = styled.div`
  display: flex;
  position: absolute;
  right: 56px;
  bottom: 44px;
`;

const CardNavArrow = styled.div`
  width: 40px;
  height: 40px;
  cursor: pointer;
  transition: ${p => p.theme.transitions.regular};

  svg path {
    transition: ${p => p.theme.transitions.regular};
  }

  & + & {
    margin-left: 23px;
  }

  &:hover {
    svg path {
      stroke: ${p => p.theme.colors.pink};
    }
  }

  ${mediaSizes.max1024} {
    display: none;
  }
`;

const CardCont = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 568px;
    margin: 49px 0 80px 0;
    /* overflow: hidden; */

    .mySwiper{
        min-width: 1797px;
        position: relative;
        overflow: visible;

        ${mediaSizes.max480} {
        }

        .swiper-wrapper {
            display: flex;
            align-items: center;
        }

         /* Bullets */
        .swiper-pagination {
            height: 12px;
            z-index: 19;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 300px;
            position: absolute;
            left: 50%;
            bottom: -10%;
            transform: translateX(-50%);
            ${mediaSizes.min1024} {
                display: none;
            }

          .swiper-pagination-bullet {
            background-color: #D2D3D6;
            margin: 0 8px;
            opacity: 1;
            width: 12px;
            height: 12px;
          }

          .swiper-pagination-bullet-active {
            background-color: ${p => p.theme.colors.pink};
            width: 12px;
            height: 12px;
          }
        }

        .swiper-slide{
            display:flex;
            align-items: center;
            justify-content:center;
            width: 599px !important;
            transition:all 500ms linear;
            opacity: 0.9;
            transform: scale(0.65);

            ${CardNavArrow} {
                visibility: hidden;
                opacity: 0;
            }


            &.swiper-slide-next{
                transform: scale(0.65) translateX(-50%);
            }
            &.swiper-slide-prev{
                transform: scale(0.65) translateX(50%);
            }

            &.swiper-slide-active {
                position: relative;
                opacity: 1;
                z-index: 5;
                transform: scale(1);

                ${CardNavArrow} {
                    visibility: visible;
                    opacity: 1;
               }
            }
        }
    }

    .swiper-mob{
        max-width: 100%;
        overflow: visible;

        .swiper-wrapper {
            display: flex;
            align-items: center;
        }

        .swiper-slide{
            display:flex;
            align-items: center;
            justify-content: center;

            ${CardNavArrow} {
                visibility: hidden;
                opacity: 0;
            }
        }

         /* Bullets */
        .swiper-pagination {
            height: 12px;
            z-index: 19;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 300px;
            position: absolute;
            left: 50%;
            bottom: -40px;
            transform: translateX(-50%);
            ${mediaSizes.min1024} {
                display: none;
            }

          .swiper-pagination-bullet {
            background-color: #D2D3D6;
            margin: 0 8px;
            opacity: 1;
            width: 12px;
            height: 12px;
          }

          .swiper-pagination-bullet-active {
            background-color: ${p => p.theme.colors.pink};
            width: 12px;
            height: 12px;
          }
        }

    }

    ${mediaSizes.max1024} {
        margin: 40px 0 100px 0;
    }

    ${mediaSizes.max660} {
        height: initial;
        min-height: 700px;
        margin: 64px 0 92px 0;


        &::after,
        &::before {
            content: "";
            width: 8px;
            height: 400px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15), 0px 1px 6px rgba(0, 0, 0, 0.11), 0px 0.500862px 1.50259px rgba(0, 0, 0, 0.11), 0px 0.181152px 0.543456px rgba(0, 0, 0, 0.07);
        }
        &::before {
            left: 0;
            border-radius: 0px 4px 4px 0px;
        }
        &::after {
            right: 0;
            border-radius: 4px 0px 0px 4px;
        }
    }
`;

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  object-fit: cover;

  ${mediaSizes.max660} {
    display: none;
  }
`;

const QuoteMarks = styled.div`
  width: 65px;
  height: 56px;
`;

const CardDesc = styled.div`
  font-family: ${p => p.theme.fontFamily.Ubuntu};
  color: ${p => p.theme.colors.black};
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;

  margin: 32px 0;
`;

const CardName = styled.div`
  font-family: ${p => p.theme.fontFamily.Ubuntu};
  color: ${p => p.theme.colors.black};
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
`;

const CardAdress = styled.div`
  font-family: ${p => p.theme.fontFamily.Ubuntu};
  color: ${p => p.theme.colors.gray};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 56px 40px 56px;
  width: 599px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 11px 27px rgba(0, 0, 0, 0.17), 0px 2px 8px rgba(0, 0, 0, 0.14),
    0px 1.12694px 2.75474px rgba(0, 0, 0, 0.08);
  position: relative;

  ${mediaSizes.max660} {
    width: calc(100% - 32px);
    padding: 48px 24px 40px 24px;
  }
`;

const SubTitle = styled.div`
  font-family: ${p => p.theme.fontFamily.Libre};
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 48px;
  text-align: center;
  color: ${p => p.theme.colors.black};
  margin-bottom: 40px;
`;

const Button = styled(ButtonLink)`
  max-width: 376px;

  ${mediaSizes.max660} {
    padding: 0 48px;
  }
`;

export {
  Wrapper,
  CardNavArrow,
  CardNavCont,
  Button,
  Title,
  SubTitle,
  Card,
  QuoteMarks,
  CardDesc,
  CardName,
  CardAdress,
  Image,
  CardCont,
};
