import styled from 'styled-components/macro';
import { mediaSizes } from '../../../styles/media';
import { NavLink } from 'react-router-dom';
import HomeIcon from '../../Icons/Home';

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 15;
  transition: ${p => p.theme.transitions.regular};

  &:after {
    transition: ${p => p.theme.transitions.regular};
    content: '';
    pointer-events: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #000000;
    opacity: 0;
    z-index: -1;
  }

  &.opened {
    width: 320px;

    &:after {
      content: '';
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background: #000000;
      opacity: 0.3;
      z-index: -1;
    }
  }
`;

const NavWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 24px;
  max-width: 1440px;
  width: 100%;
  position: relative;
  background-color: white;
  z-index: 11;

  ${mediaSizes.max1024} {
  }
`;

const MenuBurger = styled.div`
  width: 32px;
  height: 32px;

  margin-left: 16px;
`;

const MenuBurgerWrap = styled.div`
  font-family: ${p => p.theme.fontFamily.Ubuntu};
  color: ${p => p.theme.colors.black};
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;

  display: flex;
`;

const LogoWrap = styled(NavLink)``;
const Logo = styled.img`
  width: 61px;
  height: 56px;
  object-fit: contain;
`;

const Navigation = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const HomePageIcon = styled(HomeIcon)``;

const NavMenuWrapper = styled.div`
  position: fixed;
  width: 320px;
  height: 100vh;
  top: 0;
  right: 0;
  z-index: 10;
  background-color: white;
  padding-top: 120px;
  transition: ${p => p.theme.transitions.regular};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  transform: translateX(100%);

  &.opened {
    transform: translateX(0);
  }
`;

const NavMenu = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const NavigationLink = styled(NavLink)`
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  font-family: ${p => p.theme.fontFamily.Ubuntu};
  color: ${p => p.theme.colors.black};
  transition: ${p => p.theme.transitions.regular};
  border-bottom: 1px solid transparent;

  padding: 16px 32px;
  width: 100%;
  position: relative;
  text-transform: capitalize;

  /* :not(:first-child):hover { */
  /*   color: ${p => p.theme.colors.pink}; */
  /*   border-bottom: 1px solid ${p => p.theme.colors.pink}; */
  /* } */

  svg {
    margin-bottom: 3px;
  }

  &.active {
    color: ${p => p.theme.colors.red};
    background-color: #f0f1f2;

    svg {
      color: ${p => p.theme.colors.red};
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 4px;
      height: 100%;
      background-color: ${p => p.theme.colors.red};
    }
  }

  /* & + & { */
  /*   margin-left: 48px; */
  /* } */

  svg {
    transition: ${p => p.theme.transitions.regular};
  }

  &:hover {
    svg {
      color: ${p => p.theme.colors.red};
    }
  }
`;

const Socials = styled.div`
  display: flex;
  align-items: center;

  ${mediaSizes.max1024} {
    margin: 32px 0;
    max-width: initial;
  }
  ${mediaSizes.max480} {
  }
`;
const SocialsText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: ${p => p.theme.colors.black};

  ${mediaSizes.max1024} {
    display: none;
  }
`;
const SocialsIcon = styled.a`
  display: block;
  margin-left: 24px;
  svg path {
    transition: ${p => p.theme.transitions.regular};
  }
  &:hover {
    svg path {
      fill: ${p => p.theme.colors.red};
    }
  }

  ${mediaSizes.max1024} {
  }

  ${mediaSizes.max480} {
    margin-left: 0;
    svg {
      width: 32px;
      height: 32px;
    }

    & + & {
      margin-left: 24px;
    }
  }
`;

const Docs = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 32px;

  ${mediaSizes.max1024} {
    width: 100%;
    align-items: flex-end;
  }
`;
const DocsLink = styled(NavLink)`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: ${p => p.theme.colors.pink};
  transition: ${p => p.theme.transitions.regular};
  & + & {
    margin-left: 10px;
  }
  &:hover {
    color: ${p => p.theme.colors.red};
  }
`;

const NavFooterWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export {
  Wrapper,
  NavFooterWrap,
  NavWrapper,
  NavMenu,
  NavMenuWrapper,
  MenuBurger,
  LogoWrap,
  Logo,
  Docs,
  DocsLink,
  Navigation,
  NavigationLink,
  HomePageIcon,
  MenuBurgerWrap,
  Socials,
  SocialsText,
  SocialsIcon,
};
