import React from 'react';
import { Svg } from './styles';

const ArrowNextIcon = ({ props }: any) => (
  <Svg viewBox="0 0 40 40" fill="none" {...props}>
    <path
      d="M14.8496 33.2003L25.7163 22.3336C26.9996 21.0503 26.9996 18.9503 25.7163 17.667L14.8496 6.80029"
      stroke="#323537"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default ArrowNextIcon;
