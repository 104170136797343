import React from 'react';
import { Svg } from './styles';

const DollarIcon = ({ props }: any) => (
  <Svg viewBox="0 0 24 24" {...props}>
    <path
      d="M14.1455 9.39323C14.3626 9.64351 14.7416 9.67035 14.9919 9.45318C15.2422 9.23601 15.269 8.85706 15.0518 8.60677L14.1455 9.39323ZM9.85455 14.6068C9.63738 14.3565 9.25843 14.3296 9.00814 14.5468C8.75786 14.764 8.73101 15.1429 8.94819 15.3932L9.85455 14.6068ZM12.6 7C12.6 6.66863 12.3314 6.4 12 6.4C11.6686 6.4 11.4 6.66863 11.4 7H12.6ZM11.4 17C11.4 17.3314 11.6686 17.6 12 17.6C12.3314 17.6 12.6 17.3314 12.6 17L11.4 17ZM20.4 12C20.4 16.6392 16.6392 20.4 12 20.4V21.6C17.3019 21.6 21.6 17.3019 21.6 12H20.4ZM12 20.4C7.36081 20.4 3.6 16.6392 3.6 12H2.4C2.4 17.3019 6.69807 21.6 12 21.6V20.4ZM3.6 12C3.6 7.36081 7.36081 3.6 12 3.6V2.4C6.69807 2.4 2.4 6.69807 2.4 12H3.6ZM12 3.6C16.6392 3.6 20.4 7.36081 20.4 12H21.6C21.6 6.69807 17.3019 2.4 12 2.4V3.6ZM12 11.4C11.2735 11.4 10.6429 11.2026 10.2115 10.915C9.77588 10.6246 9.6 10.2893 9.6 10H8.4C8.4 10.8153 8.8957 11.48 9.54586 11.9134C10.2002 12.3497 11.0696 12.6 12 12.6V11.4ZM9.6 10C9.6 9.71074 9.77588 9.37543 10.2115 9.08502C10.6429 8.7974 11.2735 8.6 12 8.6V7.4C11.0696 7.4 10.2002 7.65032 9.54586 8.08656C8.8957 8.52 8.4 9.18469 8.4 10H9.6ZM12 8.6C12.9864 8.6 13.7698 8.96027 14.1455 9.39323L15.0518 8.60677C14.3901 7.84413 13.2344 7.4 12 7.4V8.6ZM12 12.6C12.7265 12.6 13.3571 12.7974 13.7885 13.085C14.2241 13.3754 14.4 13.7107 14.4 14H15.6C15.6 13.1847 15.1043 12.52 14.4541 12.0866C13.7998 11.6503 12.9304 11.4 12 11.4V12.6ZM11.4 7V8H12.6V7H11.4ZM11.4 16L11.4 17L12.6 17L12.6 16L11.4 16ZM12 15.4C11.0136 15.4 10.2302 15.0397 9.85455 14.6068L8.94819 15.3932C9.60993 16.1559 10.7656 16.6 12 16.6L12 15.4ZM14.4 14C14.4 14.2893 14.2241 14.6246 13.7885 14.915C13.3571 15.2026 12.7265 15.4 12 15.4V16.6C12.9304 16.6 13.7998 16.3497 14.4542 15.9134C15.1043 15.48 15.6 14.8153 15.6 14H14.4ZM11.4 8L11.4 16L12.6 16L12.6 8L11.4 8Z"
      fill="currentColor"
    />
  </Svg>
);

export default DollarIcon;
